import * as React from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { InputAdornment, StandardTextFieldProps } from '@mui/material';
import SearchIcon from '@/assets/search-icon.svg';
import DownIcon from '@/assets/caret_down.svg';
import { TextField } from '@/components/Inputs';
import { Dropdown } from '@/components/Navigation';
import useDropdownTrigger from '@/hooks/useDropdownTrigger';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { IdAndNameItem } from '@/types/utils';
import { normalizeText } from '@/utils/normalizeText';

export type SingleSelectItem = IdAndNameItem;

export interface SingleSelectFilterProps extends Omit<StandardTextFieldProps, 'onChange' | 'ref'> {
  value: SingleSelectItem;
  items?: SingleSelectItem[];
  label: string;
  placeholder: string;
  searchLabel?: string;
  emptyArrayLabel?: string;
  isLoading: boolean;
  isDisabled?: boolean;
  searchDisabled?: boolean;
  fullWidth?: boolean;
  icon?: string;
  border?: 'sm' | 'normal';
  dropdownClassName?: string;
  dropdownWidth?: 'sm' | 'md' | 'lg' | 'full';
  onChange: (newItem: SingleSelectItem) => void;
  className?: string;
}

export const SingleSelect = ({
  value,
  items,
  label,
  placeholder,
  searchLabel,
  emptyArrayLabel,
  isLoading,
  isDisabled,
  searchDisabled = false,
  fullWidth = false,
  icon,
  border = 'normal',
  dropdownClassName,
  dropdownWidth = 'md',
  onChange,
  className,
  ...props
}: SingleSelectFilterProps) => {
  const { t } = useTranslation('reports');
  const [searchText, setSearchText] = React.useState<string>('');
  const [selectOpen, selectAnchorEl, handleTriggerClick, handleSelectClose] = useDropdownTrigger();
  const boxRef = React.useRef<HTMLDivElement>(null);

  const filteredItemList = items
    ? items.filter((item) => {
        const normalizedItemName = normalizeText(item.name);
        const normalizedSearchText = normalizeText(searchText);

        return normalizedItemName.includes(normalizedSearchText);
      })
    : [];

  const emptyArrayText = emptyArrayLabel || t('no.items');

  const handleChange = (newValue: SingleSelectItem) => {
    setSearchText('');
    handleSelectClose();
    onChange(newValue);
  };

  const handleDropdownWidth = (width: string) => {
    switch (width) {
      case 'sm':
        return 'w-[150px]';
      case 'md':
        return 'w-[175px]';
      case 'lg':
        return 'w-[220px]';
      case 'full':
        return 'w-full';
      default:
        return 'w-[175px]';
    }
  };

  return (
    <Box ref={boxRef} className={`self-auto md:self-center ${fullWidth && 'w-full'}`}>
      <TextField
        className={className}
        focused={false}
        onClick={handleTriggerClick}
        id="input-with-icon-textfield"
        label={label}
        InputLabelProps={{
          className: 'font-primary text-normal text-secondary',
        }}
        inputProps={{
          className: `py-[9px] pl-2.5 pr-0 text-[12px] truncate cursor-pointer capitalize ${
            fullWidth ? '' : 'w-[133px]'
          }`,
        }}
        fullWidth={fullWidth}
        InputProps={{
          readOnly: true,
          className: `font-primary text-secondary truncate pr-0 cursor-pointer ${
            fullWidth ? 'flex justify-between' : ''
          } ${border === 'sm' ? 'rounded-sm' : ''}`,
          startAdornment: icon ? (
            <InputAdornment position="start">{icon && <img src={icon} alt="" />}</InputAdornment>
          ) : null,
          endAdornment: (
            <InputAdornment position="end" classes={{ root: 'w-5 mr-2 justify-center' }}>
              {!isLoading && <img src={DownIcon} alt="down" />}
              {isLoading && (
                <CircularProgress
                  color="primary"
                  classes={{
                    colorPrimary: 'text-secondaryLight',
                  }}
                  size="20px"
                />
              )}
            </InputAdornment>
          ),
        }}
        disabled={isLoading || isDisabled}
        value={value.name}
        {...props}
      />
      <Dropdown open={selectOpen && !(isLoading || isDisabled)} anchorEl={selectAnchorEl} onClose={handleSelectClose}>
        <div
          className={clsx(handleDropdownWidth(dropdownWidth), dropdownClassName)}
          style={dropdownWidth === 'full' ? { width: boxRef.current?.clientWidth } : {}}
        >
          {!searchDisabled && (
            <TextField
              id="input-with-icon-textfield"
              inputProps={{ className: 'py-2.5 pr-4 text-secondaryLight font-primary' }}
              InputProps={{
                className: 'text-[14px]',
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="" />
                  </InputAdornment>
                ),
              }}
              placeholder={searchLabel}
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
              classes={{ root: 'bg-white' }}
              className={clsx('min-w-fit px-4 py-2', dropdownWidth === 'full' && 'w-[calc(100%-32px)]')}
            />
          )}
          <div className="overflow-y-auto max-h-52">
            {filteredItemList.length === 0 && (
              <li className="m-auto h-auto w-fit bg-white px-4 py-[10.5px] text-sm text-secondary">
                <p className="m-auto font-primary">{emptyArrayText}</p>
              </li>
            )}
            {!isLoading &&
              filteredItemList.map((value) => (
                <MenuItem
                  key={value.id}
                  value={value.id}
                  defaultValue={placeholder}
                  onClick={() => handleChange({ id: value.id, name: value.name })}
                  classes={{
                    root: `py-2.5 text-sm font-primary text-secondary hover:bg-light_blue ${
                      dropdownWidth === 'full' && 'min-w-[180px]'
                    }`,
                  }}
                  disableRipple
                >
                  <p className="m-0 capitalize truncate">{value.name}</p>
                </MenuItem>
              ))}
          </div>
        </div>
      </Dropdown>
    </Box>
  );
};
