import { createTheme } from '@mui/material/styles';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (color: string) => augmentColor({ color: { main: color } });

export const theme = createTheme({
  palette: {
    primary: createColor('#F7931E'),
    secondary: createColor('#181F27'),
  },
});
