import { apiSlice } from '@/store';
import { UserProfile } from '../types';

export const profileApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query<UserProfile, void>({
      query: () => ({ url: 'profile' }),
      providesTags: ['Profile'],
    }),
  }),
});

export const { useGetProfileQuery, endpoints: profileEndpoints, usePrefetch: profilePrefetch } = profileApi;
