import { Nullable } from '@/types/utils';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IContractorFilters {
  id: Nullable<number>;
  startDate: Nullable<string>;
  endDate: Nullable<string>;
}

const initialState: IContractorFilters = {
  id: null,
  startDate: null,
  endDate: null,
};

export const contractorFiltersSlice = createSlice({
  name: 'contractorFilters',
  initialState,
  reducers: {
    setIdAndDate(state, action: PayloadAction<IContractorFilters>) {
      state.id = action.payload.id;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    changeStartDate(state, action: PayloadAction<string>) {
      state.startDate = action.payload;
    },
    changeEndDate(state, action: PayloadAction<string>) {
      state.endDate = action.payload;
    },
    clearId(state) {
      state.id = initialState.id;
    },
    clearFilters(state) {
      state.id = initialState.id;
      state.startDate = initialState.startDate;
      state.endDate = initialState.endDate;
    },
  },
});

export const { setIdAndDate, changeEndDate, changeStartDate, clearId, clearFilters } = contractorFiltersSlice.actions;
