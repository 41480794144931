import * as React from 'react';

import { Head } from '../../Head';

type ContentLayoutProps = {
  children: React.ReactNode;
  title?: string;
  description?: string;
};

export const ContentLayout = ({ children, title, description }: ContentLayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="mx-4 py-11 lg:mx-[60px] 2xl:w-[1440px] 2xl:mx-auto">
        <div>
          {title && <h1 className="m-0 text-3xl font-normal leading-[50px]">{title}</h1>}
          {description && <p className="text-sm font-normal leading-4">{description}</p>}
        </div>
        <div>{children}</div>
      </div>
    </>
  );
};
