import { Box, InputBase, SxProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/SearchRounded';

export const SearchBar = () => {
  const sxStyle: SxProps = {
    '& .MuiInputBase-input::-webkit-input-placeholder': {
      opacity: 1,
    },
  };
  return (
    <Box className="relative flex w-full rounded-md border border-solid border-secondaryLight bg-white sm:w-auto">
      <Box className="pointer-events-none flex h-full flex-grow items-center justify-center">
        <SearchIcon className="ml-2 fill-secondaryLight p-1" />
      </Box>
      <InputBase
        placeholder="Search"
        componentsProps={{
          root: { className: 'w-full' },
          input: {
            className: 'text-secondaryLight transition-[width] p-1 w-full sm:w-48 lg:focus:w-72 font-primary',
          },
        }}
        sx={sxStyle}
      />
    </Box>
  );
};
