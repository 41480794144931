import { apiSlice } from '@/store';
import {
  EmployeeReviewedAndPendingRequest,
  EmployeeRequestData,
  VacationDetails,
  VacationDetailsResponse,
  VacationDaysOffResponse,
  ReviewedRequests,
  PendingRequests,
  IntervalRequest,
  CreateVacationPostBody,
  CreateContractorVacationPostBody,
  IContractorVacation,
  ContractorRequests,
  EditContractorRequest,
} from '../types';
import { IntervalSchema } from '../types/schemas';
import { reviewedRequestStatus } from '@/utils/requestStatus';
import { MyTimeHolidayList, MyTimeHolidayListResponse, Pagination } from '@/types/lists';

const pendingRequestsTransformer = (resp: EmployeeRequestData[]): PendingRequests[] => {
  return resp.map((request: EmployeeRequestData) => {
    return {
      id: request.id,
      holidayLength: request.interval,
      status: 'pending',
      dateTo: request.endDate,
      dateFrom: request.startDate,
      projects: request.projects ? request.projects : [],
      description: request.description,
      files: request.files,
      type: {
        ...request.type,
      },
    };
  });
};

const reviewedRequestsTransformer = (resp: EmployeeRequestData[]): ReviewedRequests[] => {
  return resp.map((request: EmployeeRequestData) => {
    return {
      createdByHr: request.createdByHr,
      id: request.id,
      holidayLength: request.interval,
      status: reviewedRequestStatus(
        request.hrDecision ? request.hrDecision.status : 'pending',
        request.managerDecision ? request.managerDecision.status : 'pending',
      ),
      dateTo: request.endDate,
      dateFrom: request.startDate,
      approvedManager: request.managerDecision && request.managerDecision.employee.name,
      approvedHr: request.hrDecision && request.hrDecision.employee.name,
      hrDecision: request.hrDecision,
      managerDecision: request.managerDecision,
      files: request.files,
      type: {
        ...request.type,
      },
    };
  });
};

const contractorVacationsTransform = (resp: IContractorVacation[]): ContractorRequests[] => {
  return resp.map((request: IContractorVacation) => {
    return {
      id: request.id,
      name: request.name,
      holidayLength: request.interval,
      dateTo: request.endDate,
      dateFrom: request.startDate,
      description: request.description,
    };
  });
};

const vacationDetailsTransformer = (resp: VacationDetailsResponse): VacationDetails => {
  return {
    stats: {
      ...resp.stats,
    },
    daysOff: resp.daysOff.map((dayOff: VacationDaysOffResponse) => {
      return { name: dayOff.name, value: dayOff.count };
    }),
  };
};

const calendarTransformer = (resp: MyTimeHolidayListResponse): MyTimeHolidayList => {
  return {
    vacations: resp.vacations.map((vacation) => {
      return {
        startDate: vacation.startDate,
        endDate: vacation.endDate,
        interval: vacation.interval,
        type: vacation.type,
      };
    }),
    bankHolidays: [...resp.bankHolidays],
    vacationRequests: resp.vacationRequests.map((vacation) => {
      return {
        startDate: vacation.startDate,
        endDate: vacation.endDate,
        interval: vacation.interval,
        type: vacation.type,
        managerDecision: vacation.managerDecision,
      };
    }),
  };
};

export const myTimeApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPendingEmployeeRequests: build.query<PendingRequests[], EmployeeReviewedAndPendingRequest>({
      query: (params) => ({
        url: `/vacation-planner/my-time/requests`,
        params: { ...params, pending: 1 },
      }),
      transformResponse: (resp: EmployeeRequestData[]) => {
        return pendingRequestsTransformer(resp);
      },
      providesTags: ['My-Time-Employee-Requests', 'Pending'],
    }),
    getReviewedEmployeeRequests: build.query<ReviewedRequests[], EmployeeReviewedAndPendingRequest>({
      query: (params) => ({
        url: `/vacation-planner/my-time/requests`,
        params: { ...params, reviewed: 1 },
      }),
      transformResponse: (resp: EmployeeRequestData[]) => {
        return reviewedRequestsTransformer(resp);
      },
      providesTags: ['My-Time-Employee-Requests', 'Reviewed'],
    }),
    getVacationStats: build.query<VacationDetails, void>({
      query: () => ({
        url: `/vacation-planner/my-time/stats`,
      }),
      transformResponse: (resp: VacationDetailsResponse) => {
        return vacationDetailsTransformer(resp);
      },
      providesTags: ['Stats'],
    }),
    getInterval: build.query<{ interval: number }, IntervalRequest>({
      query: (data: IntervalRequest) => {
        if (data.typeId || data.typeId === 0) {
          return { url: '/vacation-planner/my-time/requests/interval', params: data };
        } else {
          return { url: '/vacation-planner/my-time/vacations/interval', params: data };
        }
      },
      providesTags: ['My-Time-Interval'],
      extraOptions: {
        dataSchema: IntervalSchema,
      },
    }),
    postCreateVacation: build.mutation<void, CreateVacationPostBody>({
      query: (body) => ({
        url: '/vacation-planner/my-time/requests',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Pending', 'My-Time-Calendar', 'Stats', 'Manage-Time-Off-Pending'],
    }),
    getContractorVacations: build.query<
      Pagination<ContractorRequests>,
      { page: number; perPage: number; sort?: string; order?: string }
    >({
      query: (params) => ({
        url: '/vacation-planner/my-time/vacations',
        params,
      }),
      transformResponse: (resp: Pagination<IContractorVacation>) => {
        return {
          ...resp,
          data: contractorVacationsTransform(resp.data),
        };
      },
      providesTags: ['My-Time-Contractor-Requests'],
    }),
    postCreateContractorVacation: build.mutation<void, CreateContractorVacationPostBody>({
      query: (body) => ({
        url: '/vacation-planner/my-time/vacations',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['My-Time-Contractor-Requests', 'My-Time-Calendar', 'Stats', 'Manage-Time-Off-Pending'],
    }),
    putEditContractorVacation: build.mutation<void, EditContractorRequest>({
      query: ({ requestId, ...rest }) => ({
        url: `/vacation-planner/my-time/vacations/${requestId}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: [
        'My-Time-Contractor-Requests',
        'My-Time-Calendar',
        'Stats',
        'Manage-Time-Off-Pending',
        'Manage-Time-Off-Reviewed',
        'Gantt-Calendar',
      ],
    }),
    putEditVacationRequest: build.mutation<void, CreateVacationPostBody>({
      query: ({ requestId, ...rest }) => ({
        url: `/vacation-planner/my-time/requests/${requestId}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Pending', 'My-Time-Calendar', 'Stats', 'Manage-Time-Off-Pending'],
    }),
    getMyTimeCalendar: build.query<MyTimeHolidayList, { startDate: string; endDate: string }>({
      query: (data) => ({
        url: `/vacation-planner/my-time/calendar`,
        params: data,
      }),
      transformResponse(resp: MyTimeHolidayListResponse) {
        return calendarTransformer(resp);
      },
      providesTags: ['My-Time-Calendar'],
    }),
    postVacationDocumentFile: build.mutation<void, { id: number; fileName: string; file: string }>({
      query: ({ id, file, fileName }) => ({
        url: `/vacation-planner/my-time/requests/${id}/files`,
        method: 'POST',
        body: {
          name: fileName,
          content: file,
        },
      }),
      invalidatesTags: ['My-Time-Employee-Requests', 'Manage-Time-Off-Pending', 'Manage-Time-Off-Reviewed'],
    }),
    deleteVacationDocumentFile: build.mutation<void, { requestId: number; fileId: number }>({
      query: (data) => {
        return {
          url: `/vacation-planner/my-time/requests/${data.requestId}/files/${data.fileId}`,
          method: 'DELETE',
          params: data,
        };
      },
      invalidatesTags: ['My-Time-Employee-Requests', 'Manage-Time-Off-Pending', 'Manage-Time-Off-Reviewed'],
    }),
    deleteContractorVacation: build.mutation<void, { vacationId: number }>({
      query: ({ vacationId }) => ({
        url: `/vacation-planner/my-time/vacations/${vacationId}`,
        method: 'DELETE',
        params: { id: vacationId },
      }),
      invalidatesTags: [
        'My-Time-Contractor-Requests',
        'My-Time-Calendar',
        'Stats',
        'Manage-Time-Off-Pending',
        'Manage-Time-Off-Reviewed',
        'Gantt-Calendar',
      ],
    }),
    deleteVacationRequest: build.mutation<void, { requestId: number }>({
      query: (data) => ({
        url: `/vacation-planner/my-time/requests/${data.requestId}`,
        method: 'DELETE',
        params: data,
      }),
      invalidatesTags: ['My-Time-Employee-Requests', 'My-Time-Calendar', 'Stats', 'Manage-Time-Off-Pending'],
    }),
  }),
});

export const {
  useGetPendingEmployeeRequestsQuery,
  useGetReviewedEmployeeRequestsQuery,
  useGetVacationStatsQuery,
  usePostCreateContractorVacationMutation,
  usePostCreateVacationMutation,
  usePutEditVacationRequestMutation,
  useGetIntervalQuery,
  useGetMyTimeCalendarQuery,
  usePostVacationDocumentFileMutation,
  useDeleteVacationDocumentFileMutation,
  useGetContractorVacationsQuery,
  usePutEditContractorVacationMutation,
  useDeleteContractorVacationMutation,
  useDeleteVacationRequestMutation,
} = myTimeApi;
