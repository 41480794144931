import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  description?: string;
}

export const PageLoader = ({ description }: Props) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex h-full w-full flex-col items-center justify-center backdrop-blur-sm">
      <p>{description ? description : t('loading.data')}</p>
      <CircularProgress color="primary" size={'4rem'} classes={{ colorPrimary: 'text-primaryLight' }} />
    </div>
  );
};
