import { toKebabCase } from '@/lib/utils';

type SectionProps = {
  id?: string;
  className?: string;
  children: React.ReactNode;
};

export const Section: React.FC<SectionProps> = ({ id, children, className }) => (
  <section id={id && toKebabCase(id)} className={className}>
    {children}
  </section>
);
