import { Trans, useTranslation } from 'react-i18next';

import lynxLogo from '@/assets/lynx-logo.svg';
import handbookCover from '@/assets/handbook/handbook-cover.png';

export const HandbookCover = () => {
  const { t } = useTranslation('handbook');

  return (
    <>
      <div className="flex w-full overflow-hidden relative">
        <img src={handbookCover} alt="employee-handbook-cover" className="w-full scale-125 translate-x-16 grayscale" />
        <div className="absolute md:w-1/2 h-full bg-veryLightBlue flex items-center justify-center">
          <div className="p-12 text-left">
            <img src={lynxLogo} alt="Lynx Solutions Logo" />
            <h1 className="text-6xl text-primary uppercase font-normal">
              <Trans components={{ strong: <strong /> }}>{t('employeeHandbook.title')}</Trans>
            </h1>
            <p className="mt-2 text-2xl text-secondary">{t('employeeHandbook.description')}</p>
          </div>
        </div>
      </div>
    </>
  );
};
