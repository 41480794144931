import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cn, toKebabCase } from '@/lib/utils';
import { Nullable } from '@/types/utils';

export const TableOfContents = () => {
  const { t } = useTranslation('handbook');
  const [activeElement, setActiveElement] = useState<Nullable<string>>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const intersectingEntry = entries.find((entry) => entry.isIntersecting);

        if (intersectingEntry) {
          const { id } = intersectingEntry.target;

          setActiveElement(id);
        }
      },
      { threshold: 0, rootMargin: '-30% 0% -65% 0%' },
    );

    document.querySelectorAll('article').forEach((element) => {
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <aside
      id="table-of-contents"
      className="sticky top-20 max-w-[21rem] bg-veryLightBlue hidden xl:block p-4 overflow-y-auto max-h-[calc(100vh-8rem)] [&_a]:text-secondary"
    >
      <ul className="list-none my-0 pl-0">
        {t('tableOfContents', { returnObjects: true }).map((item, index) => {
          const chapterId = toKebabCase(item.name);
          const isActive = activeElement === chapterId;

          return (
            <li key={`${item}-${index}`} className="text-sm font-medium pb-4">
              <a
                href={`#${chapterId}`}
                className={cn('hover:underline', {
                  '!text-primary': isActive,
                })}
              >
                {item.name}
              </a>
              {!!item.subchapters && (
                <ul className="list-disc pl-1 ml-4">
                  {item.subchapters.map((_item, _index) => (
                    <li key={`${index}-${_item}-${_index}`} className="text-xs font-normal">
                      <a href={`#${toKebabCase(_item)}`} className="hover:underline">
                        {_item}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </aside>
  );
};
