import { cn } from '@/lib/utils';
import { Trans } from 'react-i18next';

type InfoBoxProps = {
  text: string;
  url: string;
  /** If true, the text will have a top margin. */
  gutterTop?: boolean;
  className?: string;
};

export const InfoBox: React.FC<InfoBoxProps> = (props) => {
  const { text, url, className, gutterTop = false } = props;

  return (
    <div className={cn('flex justify-center', gutterTop && 'mt-12', className)}>
      <div className="border border-solid rounded border-gray py-2 px-4">
        <p className="text-sm font-medium my-0">
          <Trans
            components={{
              strong: <strong />,
              a: <a className="text-primary underline" href={url} target="_blank" rel="noreferrer" />,
            }}
          >
            {text}
          </Trans>
        </p>
      </div>
    </div>
  );
};
