import React, { useState } from 'react';

const useDropdownTrigger = (): [
  boolean,
  null | HTMLElement,
  (event: React.MouseEvent<HTMLElement>) => void,
  () => void,
] => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return [open, anchorEl, handleClick, handleClose];
};

export default useDropdownTrigger;
