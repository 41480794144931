import { MainLayout } from '@/components/Layout';
import { Dashboard } from '@/features/misc';
import { activityPlanningRoutes } from '@/features/activity-planning';
import { reportsRoutes } from '@/features/reports';
import { CircularProgress } from '@mui/material';
import { HomeRounded as DashboardIcon, Article } from '@mui/icons-material';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import navigationKeys from '@/locale/en/layout';
import { myWorkRoutes } from '@/features/my-work';
import { lazyImport } from '@/lib/lazyImport';
import { RoutesCollection, TRouteObject, TRoutesCollection } from '@/config/routing';
import { vacationPlannerRoutes } from '@/features/holiday';
import { contractorRoutes } from '@/features/contractor';
import { Authorization, EMPLOYEE_TYPES, EmployeeTypes, ROLES, RoleTypes } from '@/features/authorization';
import { Sandbox } from '@/features/sandbox/routes/Sandbox';
import { EmployeeHandbook } from '@/features/handbook';
import appConfig from '@/config/appConfig';

const { ReportsRoutes } = lazyImport(() => import('@/features/reports'), 'ReportsRoutes');
const { MyWorkRoutes } = lazyImport(() => import('@/features/my-work'), 'MyWorkRoutes');
const { Admin } = lazyImport(() => import('@/features/misc'), 'Admin');
const { LoginRedirect } = lazyImport(() => import('@/features/misc'), 'LoginRedirect');
const { NotFound } = lazyImport(() => import('@/features/misc'), 'NotFound');
const { VacationPlannerRoutes } = lazyImport(() => import('@/features/holiday'), 'VacationPlannerRoutes');
const { ContractorRoutes } = lazyImport(() => import('@/features/contractor'), 'ContractorRoutes');
const { ForbiddenPage } = lazyImport(() => import('@/features/misc'), 'ForbiddenPage');
const { ActivityPlanningRoutes } = lazyImport(() => import('@/features/activity-planning'), 'ActivityPlanningRoutes');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="flex items-center justify-center w-full h-full">
            <CircularProgress />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes: TRouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/employee-handbook',
        element: <EmployeeHandbook />,
      },
      {
        path: '/my-work',
        element: <MyWorkRoutes />,
        children: myWorkRoutes,
      },
      {
        path: '/reports',
        element: <ReportsRoutes />,
        children: reportsRoutes,
      },
      {
        path: '/admin',
        element: <Admin />,
      },
      {
        path: '/activity-planner',
        element: <ActivityPlanningRoutes />,
        children: activityPlanningRoutes,
      },
      {
        path: '/vacation-planner',
        element: <VacationPlannerRoutes />,
        children: vacationPlannerRoutes,
      },
      {
        path: '/contractor',
        element: (
          <Authorization
            allowedRoles={[]}
            allowedEmployeeTypes={[EMPLOYEE_TYPES.CONTRACTOR]}
            includeEmploymentHistory={true}
            forbiddenFallback={<Navigate to={RoutesCollection.ForbiddenPage} />}
          >
            <ContractorRoutes />
          </Authorization>
        ),
        children: contractorRoutes,
      },
      {
        path: '/login-redirect',
        element: <LoginRedirect />,
      },
      {
        path: '/sandbox',
        element: <Sandbox />,
      },
      {
        path: '/forbidden',
        element: <ForbiddenPage />,
      },
      {
        path: '/*',
        element: <NotFound />,
      },
    ],
  },
];

export type NavigationNameType = keyof typeof navigationKeys;

const icons = {
  DashboardIcon,
  Article,
};

export type NavigationItem = {
  name: NavigationNameType;
  to: TRoutesCollection;
  icon?: keyof typeof icons;
  // icon: (props: SvgIconProps) => JSX.Element;
  children?: NavigationItem[];
  roles?: RoleTypes[];
  employeeTypes?: EmployeeTypes[];
  hidden?: boolean;
};

const hideUnreleased = appConfig.hideUnreleasedFeatures;

export const protectedNavigationItems: NavigationItem[] = [
  { name: 'menu.dashboard', to: '/', icon: 'DashboardIcon' },
  { name: 'menu.mywork', to: '/my-work', icon: 'DashboardIcon', hidden: hideUnreleased },
  {
    name: 'menu.reports',
    to: '/reports',
    icon: 'Article',
    children: [
      {
        name: 'menu.reports.monthly',
        to: '/reports/monthly',
        icon: 'Article',
        hidden: hideUnreleased,
      },
      {
        name: 'menu.reports.overdue',
        to: '/reports/overdue-invoices',
        icon: 'Article',
        hidden: hideUnreleased,
      },
      {
        name: 'menu.reports.all',
        to: '/reports/all-invoices',
        icon: 'DashboardIcon',
        hidden: hideUnreleased,
      },
      {
        name: 'menu.reports.profitability',
        to: '/reports/monthly-hours-and-profitability',
        icon: 'DashboardIcon',
        roles: [ROLES.ADMIN],
      },
      {
        name: 'menu.reports.partiallyAllocated',
        to: '/reports/partially-allocated',
        icon: 'DashboardIcon',
        roles: [ROLES.ADMIN],
      },
    ],
    roles: [ROLES.ADMIN],
  },
  { name: 'menu.admin', to: '/admin', icon: 'DashboardIcon', hidden: hideUnreleased },
  {
    name: 'menu.activityPlanning',
    to: '/activity-planner',
    icon: 'DashboardIcon',
  },
  {
    name: 'menu.holiday',
    to: '/vacation-planner',
    icon: 'DashboardIcon',
  },
  {
    name: 'menu.contractor',
    to: '/contractor',
    icon: 'DashboardIcon',
    employeeTypes: [EMPLOYEE_TYPES.CONTRACTOR],
  },
  { name: 'menu.handbook', to: '/employee-handbook', icon: 'DashboardIcon' },
];
