import { z } from 'zod';

export const NoteSchema = z.object({
  id: z.number(),
  note: z.string(),
  createdAt: z.string(),
});

export const NoteListSchema = z.array(NoteSchema);

export const ProjectItemSchema = z.object({
  id: z.number(),
  capacityPlanningId: z.number().optional(),
  name: z.string(),
  planned: z.number().optional(),
  actual: z.number().optional(),
});

export const ProjectItemsListSchema = z.array(ProjectItemSchema);

export const CategorySchema = z.object({
  id: z.number(),
  name: z.string(),
  color: z.string().regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
  projects: ProjectItemsListSchema,
});

export const CategoriesListSchema = z.array(CategorySchema);

export const WeeklyDaysOffSchema = z.object({
  approvedDayOffs: z.number(),
  nationalHolidays: z.number(),
  pendingDayOffs: z.number(),
});

export const SeriesSchema = z.object({
  label: z.string(),
  type: z.enum(['bar']),
  data: z.array(z.number()),
  color: z.array(z.string()),
});

export const SeriesListSchema = z.array(SeriesSchema);

export const LegendItemSchema = z.object({
  id: z.number(),
  name: z.string(),
  color: z.string(),
  borderColor: z.string().optional(),
});

export const LegendSchema = z.array(LegendItemSchema);

export const ActivityDataResponseSchema = z.object({
  lastWeek: z.object({
    categories: CategoriesListSchema,
  }),
  actualWeek: z.object({
    categories: CategoriesListSchema,
  }),
  categories: CategoriesListSchema,
  employee: z.object({ weeklyHours: z.number(), fullyAllocated: z.boolean(), vacations: WeeklyDaysOffSchema }),
});

export const ActivityDataSchema = ActivityDataResponseSchema.extend({
  priorSeries: SeriesListSchema,
  currentSeries: SeriesListSchema,
  priorLegend: LegendSchema,
  currentLegend: LegendSchema,
});

export const EmployeeSchema = z.object({
  id: z.number(),
  name: z.string(),
  isFullyAllocated: z.boolean(),
});

export const EmployeeListSchema = z.array(EmployeeSchema);
