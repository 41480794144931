import { LOGIN_TYPES, LoginTypes } from '@/features/auth/types/index';
import { EMPLOYEE_TYPES, EmployeeTypes } from '@/features/authorization/types';

interface Config {
  apiUrl: string;
  siteName: string;
  tokenKey: string;
  profileKey: string;
  rootUrl: string;
  defaultEmployeeType: EmployeeTypes;
  loginType: LoginTypes;
  azureClientId: string;
  azureTenantId: string;
  hideUnreleasedFeatures: boolean;
  enableMocks: boolean;
  posthogToken: string;
}

const appConfig: Config = {
  apiUrl: import.meta.env.VITE_API_URL as string,
  siteName: (import.meta.env.VITE_SITE_NAME as string) || 'Lynx Management Portal',
  tokenKey: 'auth-token',
  profileKey: 'profile',
  rootUrl: import.meta.env.VITE_ROOT_URL as string,
  defaultEmployeeType: (import.meta.env.VITE_DEFAULT_ROLE as EmployeeTypes) || EMPLOYEE_TYPES.CONTRACTOR,
  loginType: (import.meta.env.VITE_LOGIN_TYPE as LoginTypes) || LOGIN_TYPES.REDIRECT,
  azureClientId: import.meta.env.VITE_AZURE_CLIENT_ID as string,
  azureTenantId: import.meta.env.VITE_AZURE_TENANT_ID as string,
  hideUnreleasedFeatures: import.meta.env.VITE_HIDE_UNRELEASED === 'true' || false,
  enableMocks: import.meta.env.VITE_ENABLE_MOCKS === 'true' || false,
  posthogToken: import.meta.env.VITE_POSTHOG_TOKEN as string,
};

export default appConfig;
