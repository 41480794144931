import { useTranslation } from 'react-i18next';
import { TRouteObjectWithName } from '@/config/routing';
import { Tabs as MuiTabs, Tab } from '@mui/material';
import { startTransition, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { useAuthorization } from '@/features/authorization';

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

function a11yProps(index: string) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

interface Props {
  routes: TRouteObjectWithName[];
  onRouteChange?: () => void;
}

export const Tabs = ({ routes, onRouteChange }: Props) => {
  const { t } = useTranslation('layout');
  const [, setValue] = useState<string>(routes[0].path);
  const { checkAccess } = useAuthorization();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    startTransition(() => {
      setValue(newValue);
      onRouteChange && onRouteChange();
    });
  };

  const navLinks = routes
    .filter(
      (route) =>
        checkAccess({
          allowedEmployeeTypes: route.allowedEmployeeTypes ?? [],
          allowedRoles: route.allowedRoles ?? [],
        }) && !route.hidden,
    )
    .map((route) => {
      return route.path;
    });

  const routeMatch = useRouteMatch(navLinks);
  const currentTab = routeMatch?.pattern?.path || navLinks[0];

  return (
    <MuiTabs
      value={currentTab ?? ''}
      onChange={handleChange}
      aria-label="tabs"
      className="border-0 border-b border-solid border-secondary border-opacity-30"
      variant="scrollable"
    >
      {routes
        .filter(
          (route) =>
            checkAccess({
              allowedEmployeeTypes: route.allowedEmployeeTypes ?? [],
              allowedRoles: route.allowedRoles ?? [],
            }) && !route.hidden,
        )
        .map((route) => {
          return (
            <Tab
              key={route.path}
              label={
                <div className="flex items-center relative">
                  {t(route.name)}
                  {route.showNotification && (
                    <div className="ml-[2px] absolute rounded-full bg-primary w-[5px] h-[5px] top-0 -right-2" />
                  )}
                </div>
              }
              value={route.path}
              to={route.path}
              disableRipple
              component={Link}
              className="capitalize font-primary"
              {...a11yProps(route.path)}
            />
          );
        })}
    </MuiTabs>
  );
};
