import { IPublicClientApplication } from '@azure/msal-browser';

export const signInWithMicrosoftPopup = async (instance: IPublicClientApplication) => {
  try {
    const response = await instance.loginPopup();
    if (response) {
      return response;
    }
    return null;
  } catch (error) {
    /**@todo: add error notification */
    throw new Error(JSON.stringify(error));
  }
};

export const signInWithMicrosoftRedirect = async (instance: IPublicClientApplication) => {
  try {
    await instance.loginRedirect();
  } catch (error) {
    /**@todo: add error notification */
    throw new Error(JSON.stringify(error));
  }
};
