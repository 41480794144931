import { Head } from '@/components/Head';
import { useTranslation } from 'react-i18next';
import { ContentLayout } from '@/components/Layout';
import { HandbookCover, Handbook, TableOfContents } from '../components';

export const EmployeeHandbook = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <Head title={t('title.employeeHandbook')} />
      <ContentLayout title={t('title.employeeHandbook')}>
        <main className="flex flex-1 gap-16 mt-11 justify-center scroll-smooth">
          <TableOfContents />
          <div className="max-w-[900px] flex flex-col">
            <HandbookCover />
            <Handbook />
          </div>
        </main>
      </ContentLayout>
    </>
  );
};
