import { useAuth } from '@/features/auth';
import { Modal } from '@/components/Overlay';
import IconAdd from '@/assets/icon-add.svg';
import { RequestForm, EventForm } from '../Forms';
import {
  CreateContractorVacationPostBody,
  CreateVacationPostBody,
  RequestContractorFormData,
  RequestFormData,
  EventFormData,
} from '../../types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { useModal } from '@/hooks/useModal';
import { useNotification } from '@/hooks/useNotification';
import format from 'date-fns/format';
import { Authorization, EMPLOYEE_TYPES, ROLES } from '@/features/authorization';
import { usePostCreateContractorVacationMutation, usePostCreateVacationMutation } from '../../api/myTimeApiSlice';
import { useGetVacationTypesQuery, useGetEmployeesQuery } from '../../api/holidaySharedApiSlice';
import { usePostCreateEventMutation } from '../../api/manageTimeOffApiSlice';
import { ContractorRequestForm } from '../Forms/ContractorForm/ContractorRequestForm';
import { GreetingsHeader } from '@/components/Common';
import { Button } from '@/components/Inputs';
import { getFullName } from '@/utils/fullName';

export const VacationHeader = () => {
  const { t } = useTranslation('common');
  const { showModal, hideModal, isModalVisible } = useModal();
  const { displayErrorNotification, displaySuccessNotification } = useNotification();

  const location = useLocation();
  const { profile } = useAuth();

  const fullName = getFullName(profile?.lastName, profile?.firstName);

  const title = fullName ? `${fullName}, ${t('greetings.lynx')}` : '';
  const createVacationTitle =
    profile?.type === EMPLOYEE_TYPES.CONTRACTOR ? t('title.addVacation') : t('title.addRequest');

  const [createVacation, { isLoading: isCreateVacationLoading }] = usePostCreateVacationMutation();
  const [createContractorVacation, { isLoading: isCreateContractorVacationLoading }] =
    usePostCreateContractorVacationMutation();
  const [createEvent, { isLoading: isCreateEventLoading }] = usePostCreateEventMutation();

  const { data: employeesData } = useGetEmployeesQuery(profile?.isHr ? { type: EMPLOYEE_TYPES.EMPLOYEE } : skipToken);
  const { data: vacationTypesData } = useGetVacationTypesQuery();

  const employees = employeesData ? employeesData : [];
  const vacationTypes = vacationTypesData ? vacationTypesData : [];

  const handleCreateVacation = (data: RequestFormData) => {
    const { description, vacationType, fromDate, toDate } = data;

    createVacation({
      typeId: vacationType?.id,
      startDate: format(fromDate, 'yyyy-MM-dd'),
      endDate: format(toDate, 'yyyy-MM-dd'),
      ...(description.length > 0 && { description }),
    } as CreateVacationPostBody)
      .unwrap()
      .then(() => {
        hideModal(`create-vacation`), displaySuccessNotification(t('event.holidayRequest.successful'));
      })
      .catch(() => displayErrorNotification(t('error.message')));
  };

  const handleCreateContractorVacation = (data: RequestContractorFormData) => {
    const { description, fromDate, toDate } = data;

    createContractorVacation({
      startDate: format(fromDate, 'yyyy-MM-dd'),
      endDate: format(toDate, 'yyyy-MM-dd'),
      ...(description.length > 0 && { description }),
    } as CreateContractorVacationPostBody)
      .unwrap()
      .then(() => {
        hideModal(`create-vacation`), displaySuccessNotification(t('event.vacation.successful'));
      })
      .catch(() => displayErrorNotification(t('error.message')));
  };

  const handleAddEvent = (data: EventFormData) => {
    const { employee, vacationType, description, fromDate, toDate } = data;

    createEvent({
      employeeId: employee.id,
      typeId: vacationType.id,
      startDate: format(fromDate, 'yyyy-MM-dd'),
      endDate: format(toDate, 'yyyy-MM-dd'),
      ...(description.length > 0 && { description }),
    })
      .unwrap()
      .then(() => {
        hideModal(`create-event`), displaySuccessNotification(t('event.holidayRequest.successful'));
      })
      .catch(() => displayErrorNotification(t('error.message')));
  };

  return (
    <GreetingsHeader title={title}>
      {location.pathname.startsWith('/vacation-planner/my-time') && (
        <Button
          color="secondary"
          variant="outlined"
          className="flex flex-row gap-2 normal-case h-fit"
          onClick={() => showModal(`create-vacation`)}
        >
          <img src={IconAdd} alt="add" />
          {createVacationTitle}
        </Button>
      )}
      {location.pathname.startsWith('/vacation-planner/manager') && (
        <Authorization allowedRoles={[ROLES.HR]} allowedEmployeeTypes={[]}>
          <Button
            color="secondary"
            variant="outlined"
            className="flex flex-row gap-2 normal-case h-fit"
            onClick={() => showModal('create-event')}
          >
            <img src={IconAdd} alt="add" />
            {t('title.addRequest')}
          </Button>
        </Authorization>
      )}
      <Modal
        open={isModalVisible(`create-vacation`)}
        handleClose={() => hideModal(`create-vacation`)}
        title={createVacationTitle}
      >
        <>
          <Authorization allowedRoles={[]} allowedEmployeeTypes={[EMPLOYEE_TYPES.EMPLOYEE]}>
            <RequestForm
              vacationTypes={vacationTypes}
              isLoading={isCreateVacationLoading}
              handleFormSubmit={handleCreateVacation}
            />
          </Authorization>
          <Authorization allowedRoles={[]} allowedEmployeeTypes={[EMPLOYEE_TYPES.CONTRACTOR]}>
            <ContractorRequestForm
              description={t('holiday.vacationDescription')}
              isLoading={isCreateContractorVacationLoading}
              handleFormSubmit={handleCreateContractorVacation}
            />
          </Authorization>
        </>
      </Modal>
      <Modal
        open={isModalVisible('create-event')}
        handleClose={() => hideModal('create-event')}
        title={t('title.addRequest')}
      >
        <EventForm
          employees={employees}
          vacationTypes={vacationTypes}
          isLoading={isCreateEventLoading}
          handleFormSubmit={handleAddEvent}
        />
      </Modal>
    </GreetingsHeader>
  );
};
