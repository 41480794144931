import { Trans, useTranslation } from 'react-i18next';
import { Article } from '../Article';
import { Section } from '../Section';
import { MediaCard } from '../MediaCard';
import { ArticleHeading } from '../ArticleHeading';
import { ISO_DOCUMENTS, LYNX_SHAREPOINT } from '@/config/constants';

import handbookIsoCertification from '@/assets/handbook/handbook-iso-certification.png';
import { TextCard } from '../TextCard';

export const HandbookCertification = () => {
  const { t } = useTranslation('handbook');

  return (
    <Article className="flex flex-col gap-12" id={t('companyCertification.title')}>
      <Section>
        <ArticleHeading title={t('companyCertification.title')} />

        <Section>
          <MediaCard imageSrc={handbookIsoCertification}>
            {t('companyCertification.paragraphs', { returnObjects: true }).map((item, index) => (
              <p key={index} className="[&_a]:text-secondary [&_a]:underline [&_a]:font-normal">
                <Trans
                  components={{
                    a1: <a href={LYNX_SHAREPOINT} target="_blank" rel="noreferrer" />,
                    a2: <a href={ISO_DOCUMENTS.iSO27001} target="_blank" rel="noreferrer" />,
                    a3: <a href={ISO_DOCUMENTS.iSO9001} target="_blank" rel="noreferrer" />,
                  }}
                >
                  {item}
                </Trans>
              </p>
            ))}
          </MediaCard>
        </Section>

        <Section className="mt-10">
          <p className="ml-12 text-sm">{t('companyCertification.documentList.intro')}</p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
            {t('companyCertification.documentList.items', { returnObjects: true }).map((item, index) => (
              <TextCard key={index} className="p-6 [&_a]:text-secondary [&_a]:underline [&_a]:font-normal">
                <h3 className="text-base font-bold uppercase my-0 mb-3">{item.title}</h3>
                <p className="text-xs font-normal">
                  <Trans
                    components={{
                      strong: <strong />,
                      a1: <a href={ISO_DOCUMENTS.overview} target="_blank" rel="noreferrer" />,
                      a2: <a href={ISO_DOCUMENTS.main} target="_blank" rel="noreferrer" />,
                      a3: <a href={ISO_DOCUMENTS.supporting} target="_blank" rel="noreferrer" />,
                    }}
                  >
                    {item.description}
                  </Trans>
                </p>
              </TextCard>
            ))}
          </div>

          <div className="ml-12 text-sm mt-6">
            <p className="italic">{t('companyCertification.outro')}</p>

            <div className="mt-6">
              <p className="mb-0">{t('companyCertification.certificationResponsables.intro')}</p>

              <p className="my-0">
                <Trans components={{ strong: <strong /> }}>
                  {t('companyCertification.certificationResponsables.informationSecurity')}
                </Trans>
              </p>
              <p className="my-0">
                <Trans components={{ strong: <strong /> }}>
                  {t('companyCertification.certificationResponsables.qualityManagement')}
                </Trans>
              </p>
            </div>
          </div>
        </Section>
      </Section>
    </Article>
  );
};
