import { apiSlice } from '@/store';
import { IInvoiceRequest, IInvoiceResponse, IInvoiceTableItem, IReportSummary, IReportSummaryRequest } from '../types';

const invoiceTransformer = (resp: IInvoiceResponse): IInvoiceTableItem => {
  return {
    data: resp.data.map((invoiceClient) => {
      return {
        id: invoiceClient.id,
        clientName: invoiceClient.name,
        invoiceValue: invoiceClient.total,
        invoices: invoiceClient.invoices.map((i) => {
          return {
            id: i.id,
            date: i.date,
            dueDate: i.dueDate,
            invoiceName: i.name,
            overdue: i.overdue,
            invoiceValue: i.value,
          };
        }),
      };
    }),
    meta: {
      ...resp.meta,
      currentPage: resp.meta.current_page,
      lastPage: resp.meta.last_page,
      perPage: resp.meta.per_page,
    },
  };
};

export const reportsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMonthlySummaryReport: build.query<IReportSummary, IReportSummaryRequest>({
      query: (data: IReportSummaryRequest) => ({ url: 'monthly-report/summary', params: data }),
    }),
    getOverdueInvoices: build.query<IInvoiceTableItem, IInvoiceRequest>({
      query: (data: IInvoiceRequest) => ({ url: 'invoice-report/invoices', params: data }),
      transformResponse: (resp: IInvoiceResponse) => {
        return invoiceTransformer(resp);
      },
    }),
    getAllInvoices: build.query<IInvoiceTableItem, IInvoiceRequest>({
      query: (data: IInvoiceRequest) => ({ url: 'invoice-report/invoices', params: data }),
      transformResponse: (resp: IInvoiceResponse) => {
        return invoiceTransformer(resp);
      },
    }),
  }),
});

export const { useGetMonthlySummaryReportQuery, useGetOverdueInvoicesQuery, useGetAllInvoicesQuery } = reportsApi;
