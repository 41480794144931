import { toast } from 'sonner';
import CloseIcon from '@mui/icons-material/Close';

export const useNotification = () => {
  const displaySuccessNotification = (message: string) => {
    toast.custom((t) => {
      return (
        <div className="flex flex-row h-16 items-center font-primary bg-secondary text-white rounded px-4 py-1.5">
          <p className="m-0 mr-14 p-0 font-normal text-sm">{message}</p>
          <CloseIcon className="m-0 cursor-pointer" onClick={() => toast.dismiss(t)} />
        </div>
      );
    });
  };

  const displayErrorNotification = (message: string) => {
    toast.custom((t) => {
      return (
        <div className="flex flex-row h-16 items-center font-primary bg-[#F54831] text-white rounded px-4 py-1.5">
          <p className="m-0 mr-14 p-0 font-normal text-sm">{message}</p>
          <CloseIcon className="m-0 cursor-pointer" onClick={() => toast.dismiss(t)} />
        </div>
      );
    });
  };

  const displayInfoNotification = (message: string) => {
    toast.custom((t) => {
      return (
        <div className="flex flex-row h-16 items-center font-primary bg-[#0288d1] text-white rounded px-4 py-1.5">
          <p className="m-0 mr-14 p-0 font-normal text-sm">{message}</p>
          <CloseIcon className="m-0 cursor-pointer" onClick={() => toast.dismiss(t)} />
        </div>
      );
    });
  };

  return {
    displaySuccessNotification,
    displayErrorNotification,
    displayInfoNotification,
  } as const;
};
