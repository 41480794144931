import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/ubuntu';
import '@fontsource/ubuntu/500.css';
import '@fontsource/ubuntu/400.css';
import '@fontsource/ubuntu/700.css';
import './config/i18n';
import App from './App';
import './index.css';
import appConfig from './config/appConfig';
import { worker } from './mocks/browser';
import posthog from 'posthog-js';

if (appConfig.enableMocks) {
  worker.start({ onUnhandledRequest: 'bypass' });
}

if (appConfig.posthogToken) {
  posthog.init(appConfig.posthogToken, {
    api_host: 'https://eu.posthog.com',
    loaded: function (posthog) {
      if (posthog.isFeatureEnabled('disable-autocapture')) {
        posthog.config.autocapture = false;
      }
    },
    enable_heatmaps: true,
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
