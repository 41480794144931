import { RouteObject, useRoutes } from 'react-router-dom';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { useAuth } from '@/features/auth';
import { PageLoader } from '@/components/Feedback';

export const AppRoutes = () => {
  const { isAuthenticated, loading, profile } = useAuth();

  const commonRoutes: RouteObject[] = [];

  const userRoutes = isAuthenticated ? protectedRoutes : publicRoutes;

  const element = useRoutes([...commonRoutes, ...userRoutes]);

  (loading || profile === null) && <PageLoader />;
  return <>{element}</>;
};
