import { Trans, useTranslation } from 'react-i18next';
import { ArticleHeading } from '../ArticleHeading';
import { SubchapterCard } from '../SubchapterCard';
import { MediaCard } from '../MediaCard';
import { TextCard } from '../TextCard';
import { SectionHeading } from '../SectionHeading';
import { InfoBox } from '../InfoBox';
import { Article } from '../Article';
import { Section } from '../Section';
import { HANDBOOK_RESOURCES } from '@/config/constants';

import chapter9Handbook1 from '@/assets/handbook/chapter9-handbook1.png';
import chapter9Handbook2 from '@/assets/handbook/chapter9-handbook2.png';
import chapter9Handbook3 from '@/assets/handbook/chapter9-handbook3.png';
import chapter9Handbook4 from '@/assets/handbook/chapter9-handbook4.png';
import chapter9Handbook5 from '@/assets/handbook/chapter9-handbook5.png';
import chapter9Handbook6 from '@/assets/handbook/chapter9-handbook6.png';
import chapter9Handbook7 from '@/assets/handbook/chapter9-handbook7.png';
import chapter9Handbook8 from '@/assets/handbook/chapter9-handbook8.png';
import chapter9Handbook9 from '@/assets/handbook/chapter9-handbook9.png';

export const HandbookPolicies = () => {
  const { t } = useTranslation('handbook');

  return (
    <Article className="flex flex-col gap-12" id={t('policies.title')}>
      <Section>
        <ArticleHeading title={t('policies.title')} />
        <div className="grid grid-cols-3 md:grid-cols-4 gap-3">
          {t('policies.subchapters.cards', { returnObjects: true }).map((item, index) => (
            <SubchapterCard key={item + '-' + index} id={item.name} item={item} />
          ))}
        </div>
      </Section>

      <Section>
        <Section id={t('policies.subchapters.smoking.title')}>
          <MediaCard reverseOrder title={t('policies.subchapters.smoking.title')} imageSrc={chapter9Handbook1}>
            <p>{t('policies.subchapters.smoking.paragraph')}</p>
          </MediaCard>
        </Section>

        <Section id={t('policies.subchapters.parking.title')}>
          <MediaCard title={t('policies.subchapters.parking.title')} imageSrc={chapter9Handbook2}>
            <p>{t('policies.subchapters.parking.paragraph')}</p>
          </MediaCard>
        </Section>
      </Section>

      <Section id={t('policies.subchapters.overtime.title')}>
        <TextCard className="text-center">
          <SectionHeading title={t('policies.subchapters.overtime.title')} />
          <p className="text-sm">
            <Trans components={{ strong: <strong /> }}>{t('policies.subchapters.overtime.paragraph')}</Trans>
          </p>
        </TextCard>
      </Section>

      <Section>
        <Section id={t('policies.subchapters.security.title')}>
          <MediaCard reverseOrder title={t('policies.subchapters.security.title')} imageSrc={chapter9Handbook3}>
            <p>{t('policies.subchapters.security.paragraph')}</p>
          </MediaCard>
        </Section>

        <Section id={t('policies.subchapters.discrimination.title')}>
          <MediaCard title={t('policies.subchapters.discrimination.title')} imageSrc={chapter9Handbook4}>
            <p>{t('policies.subchapters.discrimination.paragraph')}</p>
          </MediaCard>
          <InfoBox
            gutterTop
            text={t('policies.subchapters.discrimination.infobox')}
            url={HANDBOOK_RESOURCES.common.discrimination}
          />
        </Section>
      </Section>

      <Section id={t('policies.subchapters.confidentiality.title')}>
        <TextCard className="text-center">
          <SectionHeading title={t('policies.subchapters.confidentiality.title')} />
          <p className="text-sm">
            <Trans components={{ strong: <strong /> }}>{t('policies.subchapters.confidentiality.paragraph')}</Trans>
          </p>
        </TextCard>
        <InfoBox
          gutterTop
          text={t('policies.subchapters.confidentiality.infobox')}
          url={HANDBOOK_RESOURCES.common.confidentiality}
        />
      </Section>

      <Section>
        <Section id={t('policies.subchapters.nonCompete.title')}>
          <MediaCard reverseOrder title={t('policies.subchapters.nonCompete.title')} imageSrc={chapter9Handbook5}>
            <p>{t('policies.subchapters.nonCompete.paragraph')}</p>
          </MediaCard>
        </Section>

        <Section id={t('policies.subchapters.workFromHome.title')}>
          <MediaCard title={t('policies.subchapters.workFromHome.title')} imageSrc={chapter9Handbook8}>
            <p>
              <Trans components={{ strong: <strong /> }}>{t('policies.subchapters.workFromHome.paragraph')}</Trans>
            </p>
          </MediaCard>
        </Section>

        <div className="grid grid-cols-2 gap-6 mt-6">
          <InfoBox text={t('policies.subchapters.nonCompete.infobox')} url={HANDBOOK_RESOURCES.common.nonCompete} />
          <InfoBox text={t('policies.subchapters.workFromHome.infobox')} url={HANDBOOK_RESOURCES.common.workFromHome} />
        </div>
      </Section>

      <Section id={t('policies.subchapters.offer.title')}>
        <TextCard className="text-center">
          <SectionHeading title={t('policies.subchapters.offer.title')} />
          <p className="text-sm">
            <Trans components={{ strong: <strong /> }}>{t('policies.subchapters.offer.paragraph')}</Trans>
          </p>
        </TextCard>
        <InfoBox
          className="mt-6"
          text={t('policies.subchapters.offer.infobox')}
          url={HANDBOOK_RESOURCES.common.jobAndOffer}
        />
      </Section>

      <Section>
        <Section id={t('policies.subchapters.events.title')}>
          <MediaCard reverseOrder title={t('policies.subchapters.events.title')} imageSrc={chapter9Handbook6}>
            <p>
              <Trans components={{ strong: <strong /> }}>{t('policies.subchapters.events.paragraph')}</Trans>
            </p>
          </MediaCard>
        </Section>

        <Section id={t('policies.subchapters.referral.title')}>
          <MediaCard title={t('policies.subchapters.referral.title')} imageSrc={chapter9Handbook7}>
            <p>
              <Trans components={{ strong: <strong /> }}>{t('policies.subchapters.referral.paragraph')}</Trans>
            </p>
          </MediaCard>
        </Section>

        <div className="grid grid-cols-2 gap-6 mt-6">
          <InfoBox text={t('policies.subchapters.events.infobox')} url={HANDBOOK_RESOURCES.common.events} />
          <InfoBox text={t('policies.subchapters.referral.infobox')} url={HANDBOOK_RESOURCES.common.referral} />
        </div>
      </Section>

      <Section id={t('policies.subchapters.internship.title')}>
        <TextCard className="text-center">
          <SectionHeading title={t('policies.subchapters.internship.title')} />
          <p className="text-sm">
            <Trans components={{ strong: <strong /> }}>{t('policies.subchapters.internship.paragraph')}</Trans>
          </p>
        </TextCard>

        <div className="grid grid-cols-2 gap-6 mt-6">
          <InfoBox text={t('policies.subchapters.internship.infobox1')} url={HANDBOOK_RESOURCES.common.internship} />
          <InfoBox
            text={t('policies.subchapters.internship.infobox2')}
            url={HANDBOOK_RESOURCES.common.summerPractice}
          />
        </div>
      </Section>

      <Section id={t('policies.subchapters.devices.title')}>
        <MediaCard title={t('policies.subchapters.devices.title')} imageSrc={chapter9Handbook9}>
          <p>{t('policies.subchapters.devices.paragraph')}</p>
        </MediaCard>
        <InfoBox gutterTop text={t('policies.subchapters.devices.infobox')} url={HANDBOOK_RESOURCES.common.devices} />
      </Section>
    </Article>
  );
};
