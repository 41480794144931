export default {
  'menu.dashboard': 'Dashboard',
  'menu.mywork': 'My Work',
  'menu.reports': 'Reports',
  'menu.admin': 'Admin',
  'menu.activityPlanning': 'Activity Planner',
  'menu.holiday': 'Vacation Planner',
  'menu.contractor': 'Contractor',
  'menu.reports.monthly': 'Monthly Reports',
  'menu.reports.overdue': 'Overdue Invoices',
  'menu.reports.all': 'All Invoices',
  'menu.handbook': 'Employee Handbook',
  'menu.reports.profitability': 'Monthly Hours and Profitability',
  'menu.reports.partiallyAllocated': 'Partially Allocated',
  search: 'search',
  'greetings.welcome': 'Welcome',
  'holiday.tabs.myTime': 'My Time',
  'holiday.tabs.manager': 'Manage Time Off',
  'holiday.tabs.hr': 'Administrative',
  'holiday.tabs.outOfOffice': 'Out of office list',
  'holiday.tabs.userGuide': 'User guide',
  'contractor.tabs.reports': 'Generate docs',
  'contractor.tabs.history': 'History',
  'notification.none': 'No new notification',
  'planning.tab.myActivityPlanning': 'My activity planner',
  'planning.tab.manageActivityPlanning': 'Manage activity planner',
};
