import { Button } from '@/components/Inputs';
import { useAuth } from '../context/AuthContext';

export const Logout = () => {
  const { signOut } = useAuth();

  const handleLogout = async () => {
    /**@todo: Not sure we need to log out of our Microsoft account, to validate with PM and BE */
    // await instance.logoutPopup({
    //   postLogoutRedirectUri: '/login',
    // });
    signOut();
  };

  return (
    <Button
      variant="text"
      color="primary"
      onClick={handleLogout}
      className="m-auto w-full min-w-0 justify-start rounded-none p-2 pl-4 font-primary text-base capitalize transition-none hover:bg-black hover:bg-opacity-5"
    >
      Log out
    </Button>
  );
};
