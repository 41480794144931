import { IdAndNameItem, Prettify, YearAndMonth, ValueOf } from '@/types/utils';
import { monthNames } from '@/config/constants';
import { z } from 'zod';

type DayOffNested = {
  employee: IdAndNameItem;
} & YearAndMonth;

export type DayOff = Prettify<DayOffNested>;

export interface VacationDetailsRequest {
  employeeId: number;
  year: number;
}

export type MonthsStatus = {
  months: MonthStatus;
};

export type TMonthName = ValueOf<typeof monthNames>;

export type MonthStatus = {
  [month: TMonthName]: 'closed' | 'opened';
};

type MonthlyReportsNested = {
  closed: boolean;
} & YearAndMonth;

export type MonthlyReports = Prettify<MonthlyReportsNested>;

export type DayOffRequest = {
  id: number;
  name: string;
  link: string;
};

export type DayOffRequestsResponse = {
  downloadAllLink: string;
  employees: DayOffRequest[];
};

export const VacationStatsSchema = z.object({
  vacationDays: z.number(),
  vacationDaysPrevYear: z.number(),
  usedVacationDays: z.number(),
  remainingVacationDays: z.number(),
  otherDaysOff: z.number(),
});

export const VacationDaysOffSchema = z.object({
  name: z.string(),
  count: z.number(),
});

export const VacationDetailsApiResponse = z.object({
  stats: VacationStatsSchema,
  daysOff: z.array(VacationDaysOffSchema),
});

export const MonthReportSchema = z.object({
  month: z.number(),
  year: z.number(),
  closed: z.boolean(),
});

export const MonthReportsSchema = z.array(MonthReportSchema);

export const DayOffRequestSchema = z.object({
  id: z.number(),
  name: z.string(),
  link: z.string(),
});

export const DayOffRequestsResponseSchema = z.object({
  downloadAllLink: z.string(),
  employees: z.array(DayOffRequestSchema),
});

export const CatholicHolidaysSchema = z.array(z.number());

export const YearAndMonthSchema = z.object({
  year: z.number(),
  month: z.number(),
});

export const MissingDocumentSchema = z.object({
  generatingOngoing: z.boolean(),
  generatingRequired: z.boolean(),
});

export type MissingDocument = z.infer<typeof MissingDocumentSchema>;
