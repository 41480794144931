import { MonthCalendar as MuiMonthPicker, MonthCalendarProps as MuiMonthPickerProps } from '@mui/x-date-pickers';
import { SxProps } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import clsx from 'clsx';
import React, { useState } from 'react';
import { addYears, subYears } from 'date-fns';

import DateIcon from '@/assets/icon-calendar.svg';
import ArrowDown from '@/assets/caret_down.svg';
import ArrowLeft from '@/assets/chevron_left.svg';
import ArrowRight from '@/assets/chevron_right.svg';
import { nextYear, minDate as pastDate } from '@/config/constants';
import { Button } from '@/components/Inputs';
import { useTranslation } from 'react-i18next';
import { months } from '@/features/holiday/types';

export type MonthPickerProps = {
  disablePastYear?: boolean;
  disableFutureYear?: boolean;
  placement?: 'right' | 'left';
  showTodayButton?: boolean;
} & MuiMonthPickerProps<Date>;

export const MonthPicker = React.forwardRef<HTMLDivElement, MonthPickerProps>(
  ({ className = '', placement = 'right', showTodayButton, ...props }, ref) => {
    const { value, onChange, disablePastYear, disableFutureYear, minDate, maxDate } = props;
    const [isOpen, setOpen] = useState<boolean>(false);
    const { t } = useTranslation('common');

    const handleClickAway = () => {
      setOpen(false);
    };

    const canDecrementYear = value ? value.getFullYear() > pastDate.getFullYear() : false;

    const canIncrementYear = value ? value.getFullYear() < nextYear.getFullYear() : false;

    const handleYearIncrement = () => {
      if (!disableFutureYear && onChange && value && maxDate && canIncrementYear)
        value !== null && onChange(addYears(value, 1));
    };

    const handleYearDecrement = () => {
      if (!disablePastYear && onChange && value && minDate && canDecrementYear)
        value !== null && onChange(subYears(value, 1));
    };

    const handleOnChange = (newValue: Date) => {
      onChange && onChange(newValue);
      setOpen(!isOpen);
    };

    const sxStyle: SxProps = {
      '& .PrivatePickersMonth-root': {
        borderRadius: 'inherit',
        margin: 0,
        flexBasis: '25%',
        height: '40px',
        padding: '12px 10px 12px 10px',
        fontFamily: 'Ubuntu',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16.8px',
      },
      '& .Mui-selected, .Mui-selected:hover, .Mui-selected:active, .Mui-selected:focus': {
        backgroundColor: '#E9ECF5 !important',
        color: 'black !important',
      },
    };

    return (
      <div className="relative self-auto md:self-center" ref={ref}>
        <div className="relative flex w-fit cursor-pointer items-center" onClick={() => setOpen(!isOpen)}>
          <img src={DateIcon} alt="date" />
          <span className="px-2">{value && `${months[value.getMonth()]} / ${value.getFullYear()}`}</span>
          <img src={ArrowDown} alt="open date dropdown" />
        </div>
        {isOpen && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div
              className={clsx(
                'w-66 absolute z-10 mx-4 rounded-t-md bg-white shadow-md',
                placement === 'right' && '-left-1/4',
                placement === 'left' && '-right-1/4',
              )}
            >
              <div className="flex items-center justify-between bg-light_blue px-4 py-4">
                <img
                  className={disablePastYear || !canDecrementYear ? 'opacity-40' : 'cursor-pointer'}
                  src={ArrowLeft}
                  onClick={handleYearDecrement}
                  alt="date"
                />
                <span className="rounded-sm border-[1px] border-solid border-secondary px-4">
                  {value ? value.getFullYear() : new Date().getFullYear()}
                </span>
                <img
                  className={disableFutureYear || !canIncrementYear ? 'opacity-40' : 'cursor-pointer'}
                  src={ArrowRight}
                  onClick={handleYearIncrement}
                  alt="date"
                />
              </div>
              <MuiMonthPicker
                {...props}
                className={clsx('border-sm m-0 w-60 text-secondary', className)}
                onChange={handleOnChange}
                sx={sxStyle}
              />
              {showTodayButton && (
                <div className="flex w-full bg-light_blue hover:bg-secondaryLight hover:bg-opacity-20 duration-150">
                  <Button
                    variant="text"
                    color="secondary"
                    className="hover:bg-secondaryLight hover:bg-opacity-20 flex w-full justify-center items-center rounded-none duration-150"
                    onClick={() => handleOnChange(new Date())}
                  >
                    {t('today')}
                  </Button>
                </div>
              )}
            </div>
          </ClickAwayListener>
        )}
      </div>
    );
  },
);
