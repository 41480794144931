import { apiSlice } from '@/store';
import { IdAndNameItem, IdAndReasonItem } from '@/types/utils';
import { reviewedRequestStatus } from '@/utils/requestStatus';
import {
  ManageTimeOffRequestResponse,
  ManageTimeOffRequest,
  GanttCalendarList,
  IntervalRequest,
  EventRequest,
  EditEventRequest,
  IEmployeeItem,
  InitialRequestStatus,
  ManagePendingRequest,
  ManageReviewedRequest,
} from '../types';

const pendingRequestsTransformer = (resp: ManageTimeOffRequestResponse): ManagePendingRequest => {
  return {
    meta: {
      currentPage: resp.meta.current_page,
      lastPage: resp.meta.last_page,
      perPage: resp.meta.per_page,
      from: resp.meta.from,
      to: resp.meta.to,
      total: resp.meta.total,
    },
    data: resp.data.map((request) => {
      return {
        id: request.id,
        name: request.employee.name,
        employeeId: request.employee.id,
        holidayLength: request.interval,
        description: request.description,
        status: InitialRequestStatus.Pending,
        dateTo: request.endDate,
        dateFrom: request.startDate,
        projects: request.projects ? request.projects : [],
        manager: request.manager,
        managerDecision: request.managerDecision,
        type: {
          ...request.type,
        },
      };
    }),
  };
};

const reviewedRequestsTransformer = (resp: ManageTimeOffRequestResponse): ManageReviewedRequest => {
  return {
    meta: {
      currentPage: resp.meta.current_page,
      lastPage: resp.meta.last_page,
      perPage: resp.meta.per_page,
      from: resp.meta.from,
      to: resp.meta.to,
      total: resp.meta.total,
    },
    data: resp.data.map((request) => {
      return {
        createdByHr: request.createdByHr,
        id: request.id,
        name: request.employee.name,
        employeeId: request.employee.id,
        holidayLength: request.interval,
        description: request.description,
        status: reviewedRequestStatus(
          request.hrDecision ? request.hrDecision.status : InitialRequestStatus.Pending,
          request.managerDecision ? request.managerDecision.status : InitialRequestStatus.Pending,
        ),
        dateTo: request.endDate,
        dateFrom: request.startDate,
        approvedManager: request.managerDecision && request.managerDecision.employee.name,
        approvedHr: request.hrDecision && request.hrDecision.employee.name,
        managerDecision: request.managerDecision,
        hrDecision: request.hrDecision,
        files: request.files,
        type: {
          ...request.type,
        },
      };
    }),
  };
};

export const manageTimeOffApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getManagePendingRequests: build.query<ManagePendingRequest, ManageTimeOffRequest>({
      query: (data: ManageTimeOffRequest) => ({
        url: `/vacation-planner/manage-time-off/requests`,
        params: data,
      }),
      transformResponse: (resp: ManageTimeOffRequestResponse) => {
        return pendingRequestsTransformer(resp);
      },
      providesTags: ['Manage-Time-Off-Pending'],
    }),
    getManageReviewedRequests: build.query<ManageReviewedRequest, ManageTimeOffRequest>({
      query: (data: ManageTimeOffRequest) => ({
        url: `/vacation-planner/manage-time-off/requests`,
        params: data,
      }),
      transformResponse: (resp: ManageTimeOffRequestResponse) => {
        return reviewedRequestsTransformer(resp);
      },
      providesTags: ['Manage-Time-Off-Reviewed'],
    }),
    getManageInterval: build.query<{ interval: number }, IntervalRequest>({
      query: (data: IntervalRequest) => ({
        url: `/vacation-planner/manage-time-off/interval`,
        params: data,
      }),
      providesTags: ['Manage-Time-Off-Interval'],
    }),
    putRequestApprove: build.mutation<void, { requestId: number }>({
      query: ({ requestId }) => ({
        url: `/vacation-planner/manage-time-off/requests/${requestId}/approve`,
        method: 'PUT',
      }),
      invalidatesTags: [
        'Manage-Time-Off-Pending',
        'Manage-Time-Off-Reviewed',
        'My-Time-Employee-Requests',
        'My-Time-Calendar',
        'Stats',
        'Gantt-Calendar',
      ],
    }),
    putRequestReject: build.mutation<void, IdAndReasonItem>({
      query: (data) => ({
        url: `/vacation-planner/manage-time-off/requests/${data.id}/reject`,
        method: 'PUT',
        body: JSON.stringify(data),
      }),
      invalidatesTags: [
        'Manage-Time-Off-Pending',
        'Manage-Time-Off-Reviewed',
        'My-Time-Employee-Requests',
        'My-Time-Calendar',
        'Gantt-Calendar',
      ],
    }),
    getManageTimeOffCalendar: build.query<GanttCalendarList[], { startDate: string; endDate: string }>({
      query: (data) => ({
        url: `/vacation-planner/manage-time-off/calendar`,
        params: data,
      }),
      providesTags: ['Gantt-Calendar'],
    }),
    postCreateEvent: build.mutation<void, EventRequest>({
      query: (data) => ({
        url: `/vacation-planner/manage-time-off/add-event`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Manage-Time-Off-Reviewed', 'Gantt-Calendar'],
    }),
    putEditEvent: build.mutation<void, EditEventRequest>({
      query: ({ requestId, ...rest }) => ({
        url: `/vacation-planner/manage-time-off/event/${requestId}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: [
        'Manage-Time-Off-Pending',
        'Manage-Time-Off-Reviewed',
        'Gantt-Calendar',
        'My-Time-Employee-Requests',
      ],
    }),
    deleteEvent: build.mutation<void, { requestId: number }>({
      query: (data) => ({
        url: `/vacation-planner/manage-time-off/requests/${data.requestId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Manage-Time-Off-Reviewed', 'Gantt-Calendar', 'My-Time-Calendar', 'Stats', 'Reviewed'],
    }),
    getProjects: build.query<IdAndNameItem[], void>({
      query: () => ({ url: '/vacation-planner/manage-time-off/projects' }),
      providesTags: ['Employees'],
      transformResponse: (response: IEmployeeItem[]) => {
        return response.map((item) => ({ id: item.id, name: item.name }));
      },
    }),
  }),
});

export const {
  useGetManageTimeOffCalendarQuery,
  useGetManagePendingRequestsQuery,
  useGetManageReviewedRequestsQuery,
  useGetManageIntervalQuery,
  usePutRequestApproveMutation,
  usePutRequestRejectMutation,
  usePostCreateEventMutation,
  usePutEditEventMutation,
  useDeleteEventMutation,
  useGetProjectsQuery,
} = manageTimeOffApi;
