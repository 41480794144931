import { Button, TextField } from '@/components/Inputs';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { IdAndReasonItem } from '@/types/utils';
import { FormErrorMessage } from '../components';

const formSchema = z.object({
  id: z.number(),
  reason: z.string().min(1, { message: t('holiday.form.requiredMessage') }),
});

type ValidationSchema = z.infer<typeof formSchema>;

interface RejectRequestFormProps {
  requestId: number;
  handleRejectRequest: (data: IdAndReasonItem) => void;
  name: string;
  vacationType: string;
  dateFrom: Date;
  dateTo: Date;
  requestLength: number;
  onClose: () => void;
}

interface InfoRowProps {
  title: string;
  info: string;
}

const InfoRow = ({ title, info }: InfoRowProps) => {
  return (
    <div className="flex flex-row">
      <p className="p-0 m-0 text-sm capitalize">{title}:</p>
      <p className="p-0 m-0 ml-1 text-sm font-bold">{info}</p>
    </div>
  );
};

export const RejectRequestForm = ({
  requestId,
  handleRejectRequest,
  name,
  vacationType,
  dateFrom,
  dateTo,
  requestLength,
  onClose,
}: RejectRequestFormProps) => {
  const { t } = useTranslation('common');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ValidationSchema>({ resolver: zodResolver(formSchema), defaultValues: { id: requestId } });

  const onSubmit = (data: IdAndReasonItem) => {
    handleRejectRequest(data);
    onClose();
  };

  const dayDisplay =
    requestLength > 1
      ? ` (${requestLength} ${t('day', {
          count: requestLength,
        })})`
      : '';

  return (
    <div className="flex flex-col gap-6 mx-8 mt-6">
      <div>
        <InfoRow title={t('name')} info={name} />
        <InfoRow title={t('type')} info={vacationType} />
        <InfoRow
          title={t('date')}
          info={`${dateFrom.getDate() !== dateTo.getDate() ? format(dateFrom, 'dd LLL - ') : ''}${format(
            dateTo,
            'dd LLL yyyy',
          )}${dayDisplay}`}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <div>
          <p className="p-0 m-0 mb-1 text-xs font-medium">{t('reject.modal.textareaLabel')}</p>
          <TextField {...register('reason')} fullWidth={true} multiline={true} minRows={4} />
          {errors.reason && <FormErrorMessage errorMessage={t('error.required')} />}
        </div>
        <div className="flex flex-row-reverse w-full mb-6">
          <Button type="submit" className="p-3 text-xs font-medium normal-case" color="error" variant="contained">
            {t('reject.modal.buttonLabel')}
          </Button>
        </div>
      </form>
    </div>
  );
};
