import { useTranslation } from 'react-i18next';
import { MediaCard } from '../MediaCard';
import { Article } from '../Article';

import handbookChapter1 from '@/assets/handbook/handbook-chapter1.png';
import handbookChapter2 from '@/assets/handbook/handbook-chapter2.png';

export const HandbookIntro = () => {
  const { t } = useTranslation('handbook');

  return (
    <div className="mt-12">
      <Article id={t('intro1.title')}>
        <MediaCard reverseOrder title={t('intro1.title')} imageSrc={handbookChapter1}>
          <p>{t('intro1.paragraph')}</p>
        </MediaCard>
      </Article>

      <Article id={t('intro2.title')}>
        <MediaCard title={t('intro2.title')} imageSrc={handbookChapter2}>
          <p>{t('intro2.paragraph')}</p>
        </MediaCard>
      </Article>
    </div>
  );
};
