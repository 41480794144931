import { TimeReportTableItem, ActivityReportTableItem } from '@/features/reports/types';

export const summaryMock = {
  totalInvoiceHours: 92,
  totalInvoiceValue: 420,
  projects: [
    {
      projectId: 1,
      projectName: 'Starlink',
      invoiceHours: 30,
      invoiceValue: 100,
    },
    {
      projectId: 2,
      projectName: 'SaturnLink',
      invoiceHours: 50,
      invoiceValue: 120,
    },
    { projectId: 3, projectName: 'MarsLink', invoiceHours: 12, invoiceValue: 200 },
  ],
};

export const fakeTimeData: TimeReportTableItem[] = [
  {
    projectName: 'Project 1',
    workedHours: 120,
    managerHours: 180,
    invoiceHours: 180,
    percentage: '80,999',
    subRows: [
      {
        employeeName: 'Employee 1',
        role: 'Designer',
        rate: 10,
        workedHours: 50,
        managerHours: 20,
        invoiceHours: 20,
        value: 200,
        percentage: '100',
      },
      {
        employeeName: 'Employee 2',
        role: 'Designer',
        rate: 10,
        workedHours: 50,
        managerHours: 20,
        invoiceHours: 20,
        value: 200,
        percentage: '100',
      },
    ],
  },
  {
    projectName: 'Project 1',
    workedHours: 120,
    managerHours: 180,
    invoiceHours: 180,
    percentage: '80,999',
    subRows: [
      {
        employeeName: 'Employee 1',
        role: 'Designer',
        rate: 10,
        workedHours: 50,
        managerHours: 20,
        invoiceHours: 20,
        value: 200,
        percentage: '100',
      },
      {
        employeeName: 'Employee 2',
        role: 'Designer',
        rate: 10,
        workedHours: 50,
        managerHours: 20,
        invoiceHours: 20,
        value: 200,
        percentage: '100',
      },
    ],
  },
  {
    projectName: 'Project 1',
    workedHours: 120,
    managerHours: 180,
    invoiceHours: 180,
    percentage: '80,999',
    subRows: [
      {
        employeeName: 'Employee 1',
        role: 'Designer',
        rate: 10,
        workedHours: 50,
        managerHours: 20,
        invoiceHours: 20,
        value: 200,
        percentage: '100',
      },
      {
        employeeName: 'Employee 2',
        role: 'Designer',
        rate: 10,
        workedHours: 50,
        managerHours: 20,
        invoiceHours: 20,
        value: 200,
        percentage: '100',
      },
      {
        employeeName: 'Employee 3',
        role: 'Designer',
        rate: 10,
        workedHours: 50,
        managerHours: 20,
        invoiceHours: 20,
        value: 200,
        percentage: '100',
      },
      {
        employeeName: 'Employee 4',
        role: 'Designer',
        rate: 10,
        workedHours: 50,
        managerHours: 20,
        invoiceHours: 20,
        value: 200,
        percentage: '100',
      },
    ],
  },
  {
    projectName: 'Project 1',
    workedHours: 120,
    managerHours: 180,
    invoiceHours: 180,
    percentage: '80,999',
    subRows: [
      {
        employeeName: 'Employee 1',
        role: 'Designer',
        rate: 10,
        workedHours: 50,
        managerHours: 20,
        invoiceHours: 20,
        value: 200,
        percentage: '100',
      },
      {
        employeeName: 'Employee 2',
        role: 'Designer',
        rate: 10,
        workedHours: 50,
        managerHours: 20,
        invoiceHours: 20,
        value: 200,
        percentage: '100',
      },
    ],
  },
];

export const fakeActivityData: ActivityReportTableItem[] = [
  {
    projectName: 'project1',
    date: '12/12/2021',
    employeeName: 'Miklos Nagy',
    workedHours: 10,
    managerHours: 10,
    redmineId: 21312,
    task: 'This is a very hard task',
    comment: 'This is a comment',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/11/2021',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/11/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/02/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/05/2022',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'C25K',
    date: '12/11/2019',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'project1',
    date: '12/12/2021',
    employeeName: 'Miklos Nagy',
    workedHours: 10,
    managerHours: 10,
    redmineId: 21312,
    task: 'This is a very hard task',
    comment: 'This is a comment',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/11/2021',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/11/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/02/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/05/2022',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'project1',
    date: '12/12/2021',
    employeeName: 'Miklos Nagy',
    workedHours: 10,
    managerHours: 10,
    redmineId: 21312,
    task: 'This is a very hard task',
    comment: 'This is a comment',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/11/2021',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/11/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/02/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/05/2022',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'project1',
    date: '12/12/2021',
    employeeName: 'Miklos Nagy',
    workedHours: 10,
    managerHours: 10,
    redmineId: 21312,
    task: 'This is a very hard task',
    comment: 'This is a comment',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/11/2021',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/11/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/02/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/05/2022',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'project1',
    date: '12/12/2021',
    employeeName: 'Miklos Nagy',
    workedHours: 10,
    managerHours: 10,
    redmineId: 21312,
    task: 'This is a very hard task',
    comment: 'This is a comment',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/11/2021',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/11/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/02/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/05/2022',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'project1',
    date: '12/12/2021',
    employeeName: 'Miklos Nagy',
    workedHours: 10,
    managerHours: 10,
    redmineId: 21312,
    task: 'This is a very hard task',
    comment: 'This is a comment',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/11/2021',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/11/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/02/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/05/2022',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'project1',
    date: '12/12/2021',
    employeeName: 'Miklos Nagy',
    workedHours: 10,
    managerHours: 10,
    redmineId: 21312,
    task: 'This is a very hard task',
    comment: 'This is a comment',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/11/2021',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/11/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/02/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/05/2022',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'project1',
    date: '12/12/2021',
    employeeName: 'Miklos Nagy',
    workedHours: 10,
    managerHours: 10,
    redmineId: 21312,
    task: 'This is a very hard task',
    comment: 'This is a comment',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/11/2021',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/11/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/02/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/05/2022',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'project1',
    date: '12/12/2021',
    employeeName: 'Miklos Nagy',
    workedHours: 10,
    managerHours: 10,
    redmineId: 21312,
    task: 'This is a very hard task',
    comment: 'This is a comment',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/11/2021',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/11/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/02/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/05/2022',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'project1',
    date: '12/12/2021',
    employeeName: 'Miklos Nagy',
    workedHours: 10,
    managerHours: 10,
    redmineId: 21312,
    task: 'This is a very hard task',
    comment: 'This is a comment',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/11/2021',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/11/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/02/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/05/2022',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'project1',
    date: '12/12/2021',
    employeeName: 'Miklos Nagy',
    workedHours: 10,
    managerHours: 10,
    redmineId: 21312,
    task: 'This is a very hard task',
    comment: 'This is a comment',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/11/2021',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/11/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/02/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/05/2022',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'project1',
    date: '12/12/2021',
    employeeName: 'Miklos Nagy',
    workedHours: 10,
    managerHours: 10,
    redmineId: 21312,
    task: 'This is a very hard task',
    comment: 'This is a comment',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/11/2021',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/11/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K',
    date: '12/11/2021',
    employeeName: 'Ronald Richard',
    workedHours: 3,
    managerHours: 3,
    redmineId: 76347,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'C25K WearOS',
    date: '10/11/2021',
    employeeName: 'Ronald Richards',
    workedHours: 2,
    managerHours: 2,
    redmineId: 34563,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'WorkZen Labs app',
    date: '05/12/2020',
    employeeName: 'Bessie Cooper',
    workedHours: 5,
    managerHours: 5,
    redmineId: 21345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: 'Zen Android app',
    date: '09/02/2021',
    employeeName: 'Marvin',
    workedHours: 3,
    managerHours: 3,
    redmineId: 12345,
    task: 'Lorem Ipsum',
    comment: 'Worked on Lorem Ipsum',
  },
  {
    projectName: '7 Minute Workout iOS app',
    date: '16/05/2022',
    employeeName: 'Jane Cooper',
    workedHours: 8,
    managerHours: 8,
    redmineId: 22124,
    task: 'Title of task subject goes here',
    comment: 'Worked on something lorem',
  },
];
