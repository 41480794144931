import {
  HandbookIntro,
  HandbookCompanyIntro,
  HandbookOnboarding,
  HandbookAdministrative,
  HandbookCommunication,
  HandbookBenefits,
  HandbookEvaluation,
  HandbookPolicies,
  HandbookOffboarding,
  HandbookResources,
  HandbookCertification,
} from './sections';

export const Handbook = () => {
  return (
    <div className="w-full flex flex-col gap-14 text-secondary [&_article]:scroll-mt-20 [&_section]:scroll-mt-20">
      <HandbookIntro />
      <HandbookCompanyIntro />
      <HandbookOnboarding />
      <HandbookAdministrative />
      <HandbookCertification />
      <HandbookCommunication />
      <HandbookBenefits />
      <HandbookEvaluation />
      <HandbookPolicies />
      <HandbookOffboarding />
      <HandbookResources />
    </div>
  );
};
