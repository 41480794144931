import { IconButton, SxProps } from '@mui/material';
import { MobileDatePicker as MuiDatePicker, MobileDatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers';
import React from 'react';
import CaretDown from '@/assets/caret_down.svg';
import { useTranslation } from 'react-i18next';

export type DatePickerProps = {
  disablePastYear?: boolean;
  disableFutureYear?: boolean;
} & MuiDatePickerProps<Date>;

export const DatePicker = React.forwardRef<HTMLDivElement, DatePickerProps>(({ ...props }, ref) => {
  const { t } = useTranslation('common');

  const sx: SxProps = {
    '.Mui-focused': {
      color: '#3C425E !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(60, 66, 94, 0.4) !important',
      borderRadius: '2px',
    },
    '& .MuiInputBase-input': {
      padding: '9px 0px 9px 10px',
      fontSize: '14px',
      color: '#3C425E',
      fontFamily: 'ubuntu',
      cursor: 'pointer',
    },
    '& .MuiButtonBase-root': {
      backgroundColor: 'white',
    },
    '& .MuiInputBase-root': {
      paddingRight: '4px',
    },
  };

  const CaretDownIcon = () => {
    return (
      <IconButton disableRipple>
        <img src={CaretDown} alt="caret-down" />
      </IconButton>
    );
  };

  return (
    <MuiDatePicker
      {...props}
      ref={ref}
      format="dd/MM/yyyy"
      slotProps={{
        textField: {
          sx: sx,
          placeholder: props.value === null ? t('error.date.required') : undefined,
          InputProps: { endAdornment: <CaretDownIcon /> },
        },
        actionBar: { actions: ['today', 'accept', 'cancel'] },
      }}
    />
  );
});
