import story from '@/assets/handbook/icons/story.svg';
import vision from '@/assets/handbook/icons/vision.svg';
import expect from '@/assets/handbook/icons/expect.svg';
import manager from '@/assets/handbook/icons/manager.svg';
import structure from '@/assets/handbook/icons/structure.svg';
import firstWeek from '@/assets/handbook/icons/first-week.svg';
import firstThree from '@/assets/handbook/icons/first-three.svg';
import question from '@/assets/handbook/icons/question.svg';
import salary from '@/assets/handbook/icons/salary.svg';
import attendance from '@/assets/handbook/icons/attendance.svg';
import working from '@/assets/handbook/icons/working.svg';
import keys from '@/assets/handbook/icons/keys.svg';
import holiday from '@/assets/handbook/icons/holiday.svg';
import pillars from '@/assets/handbook/icons/pillars.svg';
import insurance from '@/assets/handbook/icons/insurance.svg';
import documents from '@/assets/handbook/icons/documents.svg';
import trip from '@/assets/handbook/icons/trip.svg';
import health from '@/assets/handbook/icons/health.svg';
import smoking from '@/assets/handbook/icons/smoking.svg';
import parking from '@/assets/handbook/icons/parking.svg';
import overtime from '@/assets/handbook/icons/overtime.svg';
import discrimination from '@/assets/handbook/icons/discrimination.svg';
import confidentiality from '@/assets/handbook/icons/confidentiality.svg';
import workFromHome from '@/assets/handbook/icons/work-from-home.svg';
import offer from '@/assets/handbook/icons/offer.svg';
import events from '@/assets/handbook/icons/events.svg';
import referral from '@/assets/handbook/icons/referral.svg';
import internship from '@/assets/handbook/icons/internship.svg';
import devices from '@/assets/handbook/icons/devices.svg';
import ssm from '@/assets/handbook/icons/ssm.svg';

type SubchapterIconProps = {
  name: string;
  icon: string;
};

export const SubchapterIcon: React.FC<SubchapterIconProps> = ({ icon, name }) => {
  const renderIcon = () => {
    switch (icon) {
      case 'story':
        return story;
      case 'vision':
        return vision;
      case 'expect':
        return expect;
      case 'manager':
        return manager;
      case 'structure':
        return structure;
      case 'first-week':
        return firstWeek;
      case 'first-three':
        return firstThree;
      case 'question':
        return question;
      case 'salary':
        return salary;
      case 'attendance':
        return attendance;
      case 'working':
        return working;
      case 'keys':
        return keys;
      case 'holiday':
        return holiday;
      case 'pillars':
        return pillars;
      case 'insurance':
        return insurance;
      case 'documents':
        return documents;
      case 'trip':
        return trip;
      case 'health':
        return health;
      case 'ssm':
        return ssm;
      case 'smoking':
        return smoking;
      case 'parking':
        return parking;
      case 'overtime':
        return overtime;
      case 'discrimination':
        return discrimination;
      case 'confidentiality':
        return confidentiality;
      case 'work-from-home':
        return workFromHome;
      case 'offer':
        return offer;
      case 'events':
        return events;
      case 'referral':
        return referral;
      case 'internship':
        return internship;
      case 'devices':
        return devices;
    }
  };
  return <img src={renderIcon()} alt={name} className="w-[50px]" />;
};
