import { RoutesCollection, TRouteObjectWithName } from '@/config/routing';
import { lazyImport } from '@/lib/lazyImport';
import { Navigate, Outlet, useMatch, useNavigate } from 'react-router-dom';
import { VacationHeader } from '../components/Layout';
import { Head } from '@/components/Head';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@/components/Navigation';
import useEffectOnce from '@/hooks/useEffectOnce';
import { Authorization, ROLES } from '@/features/authorization';
import { ContentLayout } from '@/components/Layout';
import { useMemo } from 'react';
import { useGetManagePendingRequestsQuery } from '../api/manageTimeOffApiSlice';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useAuth } from '@/features/auth';
import { UserProfile } from '@/features/profile';
import { ManagePendingRequest } from '../types';

const { MyTime } = lazyImport(() => import('./MyTime'), 'MyTime');
const { Manager } = lazyImport(() => import('./Manager'), 'Manager');
const { Administrative } = lazyImport(() => import('./Administrative'), 'Administrative');
const { OutOfOffice } = lazyImport(() => import('./OutOfOffice'), 'OutOfOffice');
const { UserGuide } = lazyImport(() => import('./UserGuide'), 'UserGuide');

export const vacationPlannerRoutes: TRouteObjectWithName[] = [
  {
    name: 'holiday.tabs.myTime',
    path: RoutesCollection.VacationPlannerMyTime,
    element: <MyTime />,
  },
  {
    name: 'holiday.tabs.manager',
    path: RoutesCollection.VacationPlannerManager,
    element: (
      <Authorization
        allowedEmployeeTypes={[]}
        allowedRoles={[ROLES.HR, ROLES.MANAGER, ROLES.ADMIN]}
        forbiddenFallback={<Navigate to={RoutesCollection.ForbiddenPage} />}
      >
        <Manager />
      </Authorization>
    ),
    allowedRoles: [ROLES.HR, ROLES.MANAGER, ROLES.ADMIN],
  },
  {
    name: 'holiday.tabs.hr',
    path: RoutesCollection.VacationPlannerAdministrative,
    element: (
      <Authorization
        allowedEmployeeTypes={[]}
        allowedRoles={[ROLES.HR, ROLES.ADMIN]}
        forbiddenFallback={<Navigate to={RoutesCollection.ForbiddenPage} />}
      >
        <Administrative />
      </Authorization>
    ),
    allowedRoles: [ROLES.HR, ROLES.ADMIN],
  },
  {
    name: 'holiday.tabs.outOfOffice',
    path: RoutesCollection.VacationPlannerOutOfOffice,
    element: <OutOfOffice />,
  },
  {
    name: 'holiday.tabs.userGuide',
    path: RoutesCollection.VacationPlannerUserGuide,
    element: <UserGuide />,
  },
];

const TabsSection = ({ routes }: { routes: TRouteObjectWithName[] }) => {
  return (
    <Tabs
      routes={routes}
      onRouteChange={() => {
        window.scrollTo(0, 0);
      }}
    />
  );
};

const useVacationPlannerRoutes = (
  profile: UserProfile | null,
  pendingRequestsData: ManagePendingRequest | undefined,
  vacationPlannerRoutes: TRouteObjectWithName[],
) => {
  return useMemo(() => {
    if ((profile?.isManager || profile?.isHr) && pendingRequestsData) {
      return vacationPlannerRoutes.map((route) =>
        route.name === 'holiday.tabs.manager'
          ? { ...route, showNotification: pendingRequestsData.data.length > 0 }
          : route,
      );
    }
    return vacationPlannerRoutes;
  }, [profile, pendingRequestsData, vacationPlannerRoutes]);
};

export const VacationPlannerRoutes = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const match = useMatch(RoutesCollection.VacationPlanner);
  const matchAdministrative = useMatch(RoutesCollection.VacationPlannerAdministrative);
  const matchManager = useMatch(RoutesCollection.VacationPlannerManager);
  const { profile } = useAuth();
  const { data: pendingRequestsData } = useGetManagePendingRequestsQuery(
    profile?.isManager || profile?.isHr
      ? {
          pending: '1',
          page: 1,
          perPage: 1,
        }
      : skipToken,
  );
  const updatedVacationPlannerRoutes = useVacationPlannerRoutes(profile, pendingRequestsData, vacationPlannerRoutes);

  useEffectOnce(() => {
    if (!matchAdministrative && !matchManager) {
      navigate(RoutesCollection.VacationPlannerMyTime);
    }
  });

  /** @todo: investigate better redirect solution */
  if (match) {
    navigate(RoutesCollection.VacationPlannerMyTime);
  }

  return (
    <>
      <Head title={t('title.holiday')} />
      <ContentLayout>
        <VacationHeader />
        <TabsSection routes={updatedVacationPlannerRoutes} />
        <Outlet />
      </ContentLayout>
    </>
  );
};
