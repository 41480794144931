import { Trans, useTranslation } from 'react-i18next';
import { ArticleHeading } from '../ArticleHeading';
import { InfoBox } from '../InfoBox';
import { Article } from '../Article';
import { Section } from '../Section';
import { HANDBOOK_RESOURCES } from '@/config/constants';

export const HandbookEvaluation = () => {
  const { t } = useTranslation('handbook');

  return (
    <Article className="flex flex-col" id={t('evaluation.title')}>
      <ArticleHeading className="justify-start" title={t('evaluation.title')} />

      <div className="grid grid-cols-2 gap-12">
        {t('evaluation.subchapters.list', { returnObjects: true }).map((item, index) => (
          <Section key={item.title} id={item.id}>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <div className="text-4xl font-bold mb-2 text-primary">{String(index + 1).padStart(2, '0')}</div>
                <h1 className="font-medium text-sm my-0 uppercase">
                  <Trans components={{ span: <span className="block" /> }}>{item.title}</Trans>
                </h1>
              </div>
              {item.paragraphs.map((item, index) => (
                <p key={index} className="text-sm my-0">
                  {item}
                </p>
              ))}
            </div>
          </Section>
        ))}
      </div>

      <InfoBox text={t('evaluation.infobox')} url={HANDBOOK_RESOURCES.common.employeeEvaluation} gutterTop />
    </Article>
  );
};
