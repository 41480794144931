import { createSlice } from '@reduxjs/toolkit';
import { activityPlanningApi } from '../api/activityPlanningApiSlice';

type ActivityPlanningState = {
  isCreating: boolean;
  isDeleting: boolean;
};

const initialState: ActivityPlanningState = {
  isCreating: false,
  isDeleting: false,
};

export const activityPlanningSlice = createSlice({
  name: 'activityPlanning',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(activityPlanningApi.endpoints.postCapacityEmployee.matchPending, (state) => {
      state.isCreating = true;
    });
    builder.addMatcher(activityPlanningApi.endpoints.deleteEmployeePlanning.matchPending, (state) => {
      state.isCreating = true;
    });
    builder.addMatcher(activityPlanningApi.endpoints.getCapacityData.matchFulfilled, (state) => {
      state.isDeleting = false;
      state.isCreating = false;
    });
  },
});

export default activityPlanningSlice.reducer;
