import { cn } from '@/lib/utils';

type ArticleHeadingProps = {
  title: string;
  className?: string;
};

export const ArticleHeading: React.FC<ArticleHeadingProps> = (props) => {
  const { title, className } = props;

  return (
    <div className={cn('flex justify-center mb-6', className)}>
      <h1 className="text-4xl font-normal my-0 text-secondary">{title}</h1>
    </div>
  );
};
