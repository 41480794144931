import { TCategoriesList, TLegend, TSeries, TSeriesList } from '../types';

export const priorSeriesTransformer = (data: TCategoriesList, categories: TCategoriesList): TSeriesList => {
  const plannedCategories: TSeries = {
    type: 'bar',
    label: 'Planned',
    data: [],
    color: [],
  };

  const actualCategories: TSeries = {
    type: 'bar',
    label: 'Actual',
    data: [],
    color: [],
  };

  categories.forEach((category) => {
    plannedCategories.color.push(category.color || '#F7931E');
    actualCategories.color.push(category.color || '#F7931E');

    const matchingCategory = data.find((_category) => _category.id === category.id);

    if (matchingCategory) {
      const plannedHours = matchingCategory.projects.reduce((acc, project) => acc + (project.planned as number), 0);
      const actualHours = matchingCategory.projects.reduce((acc, project) => acc + (project.actual as number), 0);

      plannedCategories.data.push(plannedHours);
      actualCategories.data.push(actualHours);
    } else {
      plannedCategories.data.push(0);
      actualCategories.data.push(0);
    }
  });

  return [plannedCategories, actualCategories];
};

export const currentSeriesTransformer = (data: TCategoriesList, categories: TCategoriesList): TSeriesList => {
  const currentCategories: TSeries = {
    type: 'bar',
    label: 'Current',
    data: [],
    color: [],
  };

  categories.forEach((category) => {
    currentCategories.color.push(category.color || '#F7931E');

    const plannedHours = data
      .filter((_category) => _category.id === category.id)
      .reduce((acc, _category) => {
        return acc + _category.projects.reduce((projAcc, project) => projAcc + (project.planned as number), 0);
      }, 0);

    currentCategories.data.push(plannedHours);
  });

  return [currentCategories];
};

export const priorLegendTransformer = (): TLegend => {
  return [
    {
      id: 0,
      name: 'Planed',
      color: '#F5F5F5',
      borderColor: '#999',
    },
    {
      id: 1,
      name: 'Actual',
      color: '#999',
    },
  ];
};

export const currentLegendTransformer = (data: TCategoriesList): TLegend => {
  return data.map((category) => ({
    id: category.id,
    name: category.name,
    color: category.color,
  }));
};
