import { CircularProgress, StyledEngineProvider } from '@mui/material';
import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from '@/store';
import { theme } from '@/style';
import ThemeProvider from '@mui/system/ThemeProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '@/config/authConfig';
import { MsalProvider } from '@azure/msal-react';
import { AuthProvider } from '@/features/auth';
import { setDefaultOptions } from 'date-fns';
import { SentryProvider } from './SentryProvider';
import { Toaster } from 'sonner';
import { DEFAULT_NOTIFICATION_TIMEOUT } from '@/config/timers';

setDefaultOptions({ weekStartsOn: 1 });

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <MsalProvider instance={msalInstance}>
      <Toaster position="bottom-left" duration={DEFAULT_NOTIFICATION_TIMEOUT} />
      <Provider store={store}>
        <AuthProvider>
          <HelmetProvider>
            <ThemeProvider theme={theme}>
              <StyledEngineProvider injectFirst>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Suspense
                    fallback={
                      <div className="flex h-full w-full items-center justify-center">
                        <CircularProgress />
                      </div>
                    }
                  >
                    <BrowserRouter>{children}</BrowserRouter>
                    <SentryProvider />
                  </Suspense>
                </LocalizationProvider>
              </StyledEngineProvider>
            </ThemeProvider>
          </HelmetProvider>
        </AuthProvider>
      </Provider>
    </MsalProvider>
  );
};
