import { Modal as MuiModal, ModalProps, IconButton, Fade } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { cn } from '@/lib/utils';

interface IModal extends ModalProps {
  open: boolean;
  handleClose: () => void;
  children: JSX.Element;
  title?: string;
  modalClasses?: string;
}

export const Modal = (props: IModal) => {
  const { children, handleClose, open, modalClasses } = props;
  const { title, ...rest } = props;

  return (
    <MuiModal {...rest} open={open} onClose={handleClose}>
      <Fade in={open}>
        <div
          className={cn(
            'absolute left-1/2 top-1/2 min-w-[300px] max-w-[500px] -translate-x-1/2 -translate-y-1/2 rounded border-none bg-white shadow-dropdown focus:outline-none',
            modalClasses,
          )}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="absolute top-0 right-0 border-none"
            disableRipple
          >
            <CloseIcon />
          </IconButton>
          <h2 className="mx-8 mt-6 text-2xl font-normal">{title}</h2>

          {children}
        </div>
      </Fade>
    </MuiModal>
  );
};
