import ErrorIcon from '@/assets/error_outline.svg';

interface FormErrorMessageProps {
  errorMessage: string;
}

export const FormErrorMessage = ({ errorMessage }: FormErrorMessageProps) => {
  return (
    <div className="mt-2 flex items-center">
      <img src={ErrorIcon} alt="error" />
      <p className="m-0 ml-1 text-sm font-medium text-error">{errorMessage}</p>
    </div>
  );
};
