import { Trans, useTranslation } from 'react-i18next';
import { ArticleHeading } from '../ArticleHeading';
import { InfoBox } from '../InfoBox';
import { TextCard } from '../TextCard';
import { Article } from '../Article';
import { Section } from '../Section';
import { HANDBOOK_RESOURCES } from '@/config/constants';

import { LEARNING_PLATFORMS, LYNX_BOOK_MASSAGE_LINK } from '@/config/constants';

export const HandbookBenefits = () => {
  const { t } = useTranslation(['handbook', 'common']);

  return (
    <Article className="flex flex-col" id={t('benefits.title')}>
      <ArticleHeading title={t('benefits.title')} />

      <div className="flex flex-col gap-6">
        <p className="text-sm text-center px-12 my-0">{t('benefits.intro1')}</p>
        <InfoBox text={t('benefits.intro2')} url={HANDBOOK_RESOURCES.common.employeeBenefits} />
      </div>

      <div className="grid grid-cols-3 gap-4 mt-12">
        <TextCard className="p-6">
          <Section id={t('benefits.subchapters.glasses.title')}>
            <h3 className="text-base font-bold uppercase my-0 mb-3">{t('benefits.subchapters.glasses.title')}</h3>
            <p className="text-xs">{t('benefits.subchapters.glasses.paragraph')}</p>
          </Section>
        </TextCard>

        <TextCard className="p-6">
          <Section id={t('benefits.subchapters.insurance.title')}>
            <h3 className="text-base font-bold uppercase my-0 mb-3">{t('benefits.subchapters.insurance.title')}</h3>
            <p className="text-xs">{t('benefits.subchapters.insurance.paragraph')}</p>
          </Section>
        </TextCard>

        <TextCard className="p-6">
          <Section id={t('benefits.subchapters.mealTickets.title')}>
            <h3 className="text-base font-bold uppercase my-0 mb-3">{t('benefits.subchapters.mealTickets.title')}</h3>
            <p className="text-xs">{t('benefits.subchapters.mealTickets.paragraph')}</p>
          </Section>
        </TextCard>

        <TextCard className="p-6">
          <Section id={t('benefits.subchapters.relaxation.title')}>
            <h3 className="text-base font-bold uppercase my-0 mb-3">{t('benefits.subchapters.relaxation.title')}</h3>
            <p className="text-xs mb-0">
              <Trans
                components={{
                  a: (
                    <a
                      className="text-xs underline text-secondary leading-[0px]"
                      href={LYNX_BOOK_MASSAGE_LINK}
                      rel="noreferrer"
                      target="_blank"
                    />
                  ),
                }}
              >
                {t('benefits.subchapters.relaxation.paragraph')}
              </Trans>
            </p>
          </Section>
        </TextCard>

        <TextCard className="p-6">
          <Section id={t('benefits.subchapters.welcomeKit.title')}>
            <h3 className="text-base font-bold uppercase my-0 mb-3">{t('benefits.subchapters.welcomeKit.title')}</h3>
            <p className="text-xs">{t('benefits.subchapters.welcomeKit.paragraph')}</p>
          </Section>
        </TextCard>

        <TextCard className="p-6">
          <Section id={t('benefits.subchapters.presents.title')}>
            <h3 className="text-base font-bold uppercase my-0 mb-3">{t('benefits.subchapters.presents.title')}</h3>
            <p className="text-xs">{t('benefits.subchapters.presents.paragraph')}</p>
          </Section>
        </TextCard>
      </div>

      <Section id={t('benefits.subchapters.professional.title')}>
        <TextCard className="mt-4 p-6 grid grid-cols-2 gap-6">
          <div>
            <h3 className="text-base font-bold uppercase my-0 mb-3">{t('benefits.subchapters.professional.title')}</h3>
            {t('benefits.subchapters.professional.section1', { returnObjects: true }).map((item, index) => (
              <p key={index} className="text-xs">
                {item}
              </p>
            ))}
          </div>

          <div>
            <p className="text-xs">{t('benefits.subchapters.professional.section2')}</p>
            <div>
              {LEARNING_PLATFORMS.map((item) => (
                <div key={item.name} className="flex flex-col justify-start [&_p]:my-0 mb-3">
                  <p className="text-primary uppercase text-sm font-medium">{item.name}</p>
                  <p className="text-xs">
                    <strong>{t('common:user')}</strong>: {item.user}
                  </p>
                  <p className="text-xs">
                    <strong>{t('common:password')}</strong>: {item.password}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </TextCard>
      </Section>
    </Article>
  );
};
