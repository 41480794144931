import { IClientItem } from '@/features/clients';
import { Nullable } from '@/types/utils';
import { createSlice } from '@reduxjs/toolkit';

interface IReportsFilter {
  selectedClient: Nullable<IClientItem>;
}

interface IReportsState {
  filters: IReportsFilter;
  counter: number;
}

const initialState: IReportsState = {
  filters: {
    selectedClient: null,
  },
  counter: 1,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    filtersChanged(state, action) {
      state.filters = { ...action.payload };
    },
  },
});

export const { filtersChanged } = reportsSlice.actions;

export default reportsSlice.reducer;
