import i18n from '@/config/i18n';
import { ErrorResponse } from '@/features/holiday/types';

export const queryError = (error: ErrorResponse) => {
  if (error && error.data && error.data.message) {
    return error.data.message;
  } else {
    return i18n.t('error.message');
  }
};
