import { styled, Switch as MuiSwitch, SwitchProps, CircularProgress } from '@mui/material';
import React from 'react';

const CustomSwitch = styled(MuiSwitch)(() => ({
  '& .MuiSwitch-colorPrimary': {
    color: '#676D8B',
    '$. .MuiSwitch-active': {
      color: '#676D8B',
    },
  },
  '& .css-1881pdu-MuiButtonBase-root-MuiSwitch-switchBase"': {
    marginTop: '10px',
    background: 'rgba(255, 255, 255, 0)',
  },
  '& .css-1ody0kd-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked': {
    color: '#676D8B',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0)',
    },
  },
  '& .css-1jopynr-MuiSwitch-root .MuiSwitch-colorPrimary': {
    color: '#676D8B',
  },
  '& .css-1ody0kd-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#676D8B',
    opacity: '40%',
  },
  '& .css-1yjjitx-MuiSwitch-track': {
    backgroundColor: '#676D8B',
    opacity: '40%',
    height: '6px',
    alignSelf: 'center',
  },
  '& .css-jsexje-MuiSwitch-thumb': {
    height: '16px',
    width: '16px',
    position: 'absolute',
    transform: 'translateX(10px)',
  },
  '& .css-1ody0kd-MuiButtonBase-root-MuiSwitch-switchBase .MuiSwitch-input': {
    width: '400%',
  },
  '& .css-1ody0kd-MuiButtonBase-root-MuiSwitch-switchBase:hover': {
    background: 'rgba(255, 255, 255, 0)',
  },
}));

export interface CustomSwitchProps extends SwitchProps {
  leftLabel?: string;
  rightLabel?: string;
  isLoading?: boolean;
}

export const Switch = React.forwardRef<HTMLDivElement, CustomSwitchProps>(
  ({ leftLabel, rightLabel, isLoading, ...props }: CustomSwitchProps, ref) => (
    <div className="flex items-center gap-3" ref={ref}>
      {leftLabel && <p className={`${!props.checked ? 'text-primary' : 'text-secondary'}`}>{leftLabel}</p>}
      {isLoading ? (
        <CircularProgress
          color="primary"
          classes={{
            colorPrimary: 'text-secondaryLight',
          }}
          size="15px"
          className="px-[22px]"
        />
      ) : (
        <CustomSwitch {...props} />
      )}
      {rightLabel && <p className={`${!props.checked ? 'text-secondary' : 'text-primary'}`}>{rightLabel}</p>}
    </div>
  ),
);
