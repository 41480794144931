import {
  MenuItem,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  List,
  ListItem,
  SwipeableDrawer,
  ClickAwayListener,
  Collapse,
  Avatar,
  Button,
} from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { NavigationItem, protectedNavigationItems } from '@/routes/protected';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Dropdown } from '@/components/Navigation';
import useDropdownTrigger from '@/hooks/useDropdownTrigger';
import React, { useState } from 'react';
import Hamburger from '@/assets/hamburger.svg';
import { Logout, useAuth } from '@/features/auth';
import { SearchBar, SectionDivider } from '@/components/Layout';
import { useAuthorization } from '@/features/authorization';
import { LightTooltip } from '@/components/Overlay';
import { getFullName, getInitials } from '@/utils/fullName';

interface INavigationButton {
  item: NavigationItem;
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isActive?: boolean;
  isSideBar?: boolean;
}

const NavigationButton = ({ item, handleClick, isActive, isSideBar = false }: INavigationButton) => {
  const { t } = useTranslation('layout');
  const location = useLocation();

  const isActivePath =
    isActive !== undefined
      ? isActive
      : location.pathname.substring(1).split('/', 1)[0] === item.to.substring(1).split('/', 1)[0];

  return (
    <Button
      disableRipple
      onClick={handleClick}
      className={
        isSideBar === false
          ? clsx(
              'h-full min-w-0 rounded-none border-y-[3px] border-solid border-white pl-0 pr-0 font-primary text-[15px] capitalize hover:bg-white',
              isActivePath ? 'border-b-primary font-medium text-primary' : 'text-secondary font-normal',
            )
          : clsx(
              'm-auto h-full w-full min-w-0 justify-start rounded-none p-2 pl-4 font-primary text-base capitalize transition-none',
              isActivePath
                ? 'bg-primary font-medium text-white hover:bg-primaryHover hover:text-white'
                : 'text-secondary hover:bg-black/5',
            )
      }
    >
      {t(item.name)}
      {item.children && <ArrowDropDownIcon />}
    </Button>
  );
};

export const TopBar = () => {
  const { t } = useTranslation('layout');
  const [menuOpen, menuAnchorEl, menuHandleClick, menuHandleClose] = useDropdownTrigger();
  const [profileOpen, profileAnchorEl, profileHandleClick, profileHandleClose] = useDropdownTrigger();

  const { checkAccess } = useAuthorization();

  const [openCollapse, setOpenCollapse] = useState(false);
  const [drawerState, setDrawerState] = useState(false);

  const { profile } = useAuth();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerState(open);
  };

  const handleClose = () => {
    setDrawerState(false);
    setOpenCollapse(false);
  };

  const handleOpenCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  const handleDrawerOpen = () => {
    setDrawerState(true);
  };

  const Drawer = () => {
    return (
      <ClickAwayListener onClickAway={() => handleClose()}>
        <List className="p-0 m-0 w-fit">
          <div className="flex flex-col justify-start min-w-0 gap-4 p-2 pl-8 mt-4 text-lg rounded-none cursor-default font-primary hover:bg-inherit">
            {profile?.firstName && profile.lastName && profile.email && (
              <>
                <Avatar>{getInitials(profile.lastName, profile.firstName)}</Avatar>
                <div className="flex flex-col">
                  {`${t('greetings.welcome')}, ${getFullName(profile.lastName, profile.firstName)}`}
                  <p className="m-0 text-sm text-secondaryLight w-fit">{profile.email}</p>
                </div>
              </>
            )}
          </div>

          <ListItem>
            <SearchBar />
          </ListItem>
          <SectionDivider size="small" />
          {protectedNavigationItems.map((item) => {
            const canAccess = checkAccess({
              allowedRoles: item.roles ? item.roles : [],
              allowedEmployeeTypes: item.employeeTypes ? item.employeeTypes : [],
              includeEmploymentHistory: true,
            });

            if (canAccess && !item.hidden)
              return (
                <MenuItem
                  key={item.name}
                  className="flex items-center p-0 m-0"
                  disableRipple
                  onClick={() => item.children ?? handleClose()}
                >
                  {item.children ? (
                    <div className="justify-end w-full">
                      <NavigationButton handleClick={handleOpenCollapse} item={item} isSideBar={true} />
                      <Collapse in={openCollapse} timeout="auto" className="bg-white">
                        <List>
                          {item.children.map((child) => {
                            const checkChildrenCanAccess = checkAccess({
                              allowedRoles: child.roles ? child.roles : [],
                              allowedEmployeeTypes: child.employeeTypes ? child.employeeTypes : [],
                              includeEmploymentHistory: true,
                            });

                            return (
                              checkChildrenCanAccess &&
                              !child.hidden && (
                                <NavLink key={child.name} to={child.to} className="m-0 text-black" end>
                                  {({ isActive }) => (
                                    <MenuItem
                                      disableRipple
                                      className={clsx(
                                        'pl-14 font-primary text-[15px]',
                                        isActive ? 'font-medium text-primary' : 'text-secondary hover:bg-black/5',
                                      )}
                                      onClick={() => handleClose()}
                                    >
                                      {t(child.name)}
                                    </MenuItem>
                                  )}
                                </NavLink>
                              )
                            );
                          })}
                        </List>
                      </Collapse>
                    </div>
                  ) : (
                    <NavLink key={item.name} to={item.to} className="w-full h-full" end>
                      {() => <NavigationButton item={item} isSideBar={true} />}
                    </NavLink>
                  )}
                </MenuItem>
              );
          })}
          <Logout />
        </List>
      </ClickAwayListener>
    );
  };

  return (
    <AppBar component="nav" position="fixed" className="z-[1001] bg-white shadow-none">
      <Toolbar className="p-0 shadow-header">
        <NavLink
          className="ml-4 text-3xl font-bold cursor-pointer select-none font-primary text-primary lg:mx-8"
          to={'/'}
        >
          LMP
        </NavLink>

        <Box className="hidden h-16 mr-4 md:gap-8 lg:flex">
          {protectedNavigationItems.map((item) => {
            const canAccess = checkAccess({
              allowedRoles: item.roles ? item.roles : [],
              allowedEmployeeTypes: item.employeeTypes ? item.employeeTypes : [],
              includeEmploymentHistory: true,
            });

            if (canAccess && !item.hidden)
              return (
                <div className="flex items-center">
                  {item.children ? (
                    <>
                      <NavigationButton handleClick={menuHandleClick} item={item} isSideBar={false} />
                      <Dropdown
                        open={menuOpen}
                        anchorEl={menuAnchorEl}
                        onClick={menuHandleClose}
                        className="-left-2 top-1"
                      >
                        {item.children.map((child) => {
                          const checkChildrenCanAccess = checkAccess({
                            allowedRoles: child.roles ? child.roles : [],
                            allowedEmployeeTypes: child.employeeTypes ? child.employeeTypes : [],
                            includeEmploymentHistory: true,
                          });

                          return (
                            checkChildrenCanAccess &&
                            !child.hidden && (
                              <NavLink key={child.name} to={child.to} className="text-black" end>
                                {({ isActive }) => (
                                  <MenuItem
                                    disableRipple
                                    className={clsx(
                                      'py-[11px] font-primary',
                                      isActive ? 'border-b-primary font-medium text-primary' : 'text-secondary',
                                    )}
                                  >
                                    {t(child.name)}
                                  </MenuItem>
                                )}
                              </NavLink>
                            )
                          );
                        })}
                      </Dropdown>
                    </>
                  ) : (
                    <NavLink key={item.name} to={item.to} className="h-full">
                      {({ isActive }) => <NavigationButton item={item} isActive={isActive} isSideBar={false} />}
                    </NavLink>
                  )}
                </div>
              );
          })}
        </Box>

        <Box className="flex-1" />

        <Box className="hidden lg:block">
          <SearchBar />
        </Box>

        <Box className="flex items-center justify-center h-16 mx-4 bg-brown-300">
          <LightTooltip placement="bottom" title={t('notification.none')}>
            <NotificationsNoneIcon className="hidden fill-secondary xl:ml-8 xl:mr-4" />
          </LightTooltip>
          {profile?.firstName && profile.lastName && (
            <Button
              disableRipple
              onClick={profileHandleClick}
              className="hidden h-full rounded-none text-[15px] font-normal capitalize text-secondary hover:bg-white lg:flex lg:pl-2 whitespace-nowrap"
            >
              {`${getFullName(profile.lastName, profile.firstName)}`} <ArrowDropDownIcon />
            </Button>
          )}
          <Dropdown open={profileOpen} anchorEl={profileAnchorEl} onClick={profileHandleClose} className="w-48">
            <Logout />
          </Dropdown>
        </Box>

        <Box className="block mr-4 lg:hidden">
          <IconButton
            aria-label="open-drawer"
            className="p-0"
            onClick={handleDrawerOpen}
            disableRipple
            disableFocusRipple
          >
            <img src={Hamburger} alt="hamburger" />
          </IconButton>
          <SwipeableDrawer open={drawerState} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)} anchor="right">
            <Drawer />
          </SwipeableDrawer>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
