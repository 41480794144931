import { GreetingsHeader } from '@/components/Common';
import { useAuth } from '@/features/auth';
import { getFullName } from '@/utils/fullName';
import { useTranslation } from 'react-i18next';

export const ContractorHeader = () => {
  const { t } = useTranslation('common');
  const { profile } = useAuth();

  const fullName = getFullName(profile?.firstName, profile?.lastName);

  const title = fullName ? `${fullName}, ${t('greetings.lynx')}` : '';

  return <GreetingsHeader title={title} />;
};
