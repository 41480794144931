type ResourceListProps = {
  list: {
    name: string;
    link: string;
  }[];
};

export const ResourceList: React.FC<ResourceListProps> = (props) => {
  const { list } = props;

  return (
    <ul className="my-0 pl-1 ml-4">
      {list.map((item, index) => (
        <li key={`${item.name}-${index}`} className="text-sm">
          <a href={item.link} target="_blank" rel="noreferrer" className="text-secondary font-normal underline">
            {item.name}
          </a>
        </li>
      ))}
    </ul>
  );
};
