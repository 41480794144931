import * as React from 'react';
import { ProfitabilityChart, SimplePieChart, CapacityCurrentChart, CapacityPriorChart } from '../components';

export const Sandbox = () => {
  return (
    <div>
      <h1>Sandbox</h1>

      <section className="w-10/12 m-auto">
        <ProfitabilityChart />
      </section>
      <section>
        <SimplePieChart />
      </section>
      <section className="flex justify-around mx-10 gap-14">
        <CapacityCurrentChart key={1} />
        <CapacityPriorChart key={2} />
      </section>
    </div>
  );
};
