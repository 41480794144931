import { cn } from '@/lib/utils';

type SectionHeadingProps = {
  title: string;
  align?: 'start' | 'center' | 'end';
};

export const SectionHeading: React.FC<SectionHeadingProps> = (props) => {
  const { title, align = 'center' } = props;

  return (
    <h2
      className={cn(
        'font-normal text-[28px] my-0 mb-6 text-secondary',
        align === 'center' && 'text-center',
        align === 'start' && 'text-start',
        align === 'end' && 'text-end',
      )}
    >
      {title}
    </h2>
  );
};
