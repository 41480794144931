import React from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';

export const Dropdown = React.forwardRef<HTMLDivElement, MenuProps>(({ ...props }: MenuProps, ref) => {
  return (
    <Menu
      classes={{
        paper: `rounded-none ${props.className ? props.className : 'shadow-dropdown'}`,
        list: 'p-0',
      }}
      ref={ref}
      {...props}
    >
      {props.children}
    </Menu>
  );
});
