import { cn } from '@/lib/utils';

type TextCardProps = {
  className?: string;
  children: React.ReactNode;
};

export const TextCard: React.FC<TextCardProps> = ({ children, className }) => (
  <div className={cn('p-12 bg-veryLightBlue', className)}>{children}</div>
);
