import { addYears, format, subYears } from 'date-fns';
import { ClickAwayListener, IconButton, SxProps } from '@mui/material';
import ChevronLeft from '@/assets/chevron_left.svg';
import ChevronRight from '@/assets/chevron_right.svg';
import React, { useState } from 'react';

import { MonthCalendar as MuiMonthPicker, MonthCalendarProps as MuiMonthPickerProps } from '@mui/x-date-pickers';
import clsx from 'clsx';
import { nextYear, minDate } from '@/config/constants';

type MonthPickerProps = {
  date: Date;
  handleChange: (newDate: Date | null) => void;
} & MuiMonthPickerProps<Date>;

export const DateNavigator = React.forwardRef<HTMLDivElement, MonthPickerProps>(({ ...props }, ref) => {
  const { date, handleChange } = props;

  const sxStyle: SxProps = {
    '& .PrivatePickersMonth-root': {
      borderRadius: 'inherit',
      margin: 0,
      flexBasis: '25%',
      height: '40px',
      padding: '12px 10px 12px 10px',
      fontFamily: 'Ubuntu',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16.8px',
    },
    '& .Mui-selected, .Mui-selected:hover, .Mui-selected:active, .Mui-selected:focus': {
      backgroundColor: '#E9ECF5 !important',
      color: 'black !important',
    },
  };

  const canDecrementYear = date.getFullYear() > minDate.getFullYear();

  const canIncrementYear = date.getFullYear() < nextYear.getFullYear();

  const isMinDate = () => {
    return date > minDate;
  };

  const isMaxDate = () => {
    return date < nextYear;
  };

  const handleYearIncrement = () => {
    if (handleChange && date && isMaxDate()) date !== null && handleChange(addYears(date, 1));
  };

  const handleYearDecrement = () => {
    if (handleChange && date && isMinDate()) date !== null && handleChange(subYears(date, 1));
  };

  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="relative flex w-fit flex-row items-center" ref={ref}>
      <IconButton
        className={clsx(!isMinDate() ? 'opacity-50 cursor-default' : 'cursor-pointer')}
        disableRipple
        onClick={() => isMinDate() && handleChange(new Date(date.getFullYear(), date.getMonth() - 1))}
      >
        <img src={ChevronLeft} alt="left" className="mr-2" />
      </IconButton>
      <p className="cursor-pointer text-primary m-0 w-32 text-center" onClick={() => setOpen(true)}>{`${format(
        date,
        'LLLL',
      )} ${date.getFullYear()}`}</p>
      <IconButton
        className={clsx(!isMaxDate() ? 'opacity-50 cursor-default' : 'cursor-pointer')}
        disableRipple
        onClick={() => isMaxDate() && handleChange(new Date(date.getFullYear(), date.getMonth() + 1))}
      >
        <img src={ChevronRight} alt="right" className="ml-2" />
      </IconButton>
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className="absolute top-10 z-[1000] mx-4 w-60 rounded-md bg-white shadow-md">
            <div className="flex items-center justify-between bg-light_blue px-4 py-4">
              <img
                className={clsx(!canDecrementYear ? 'opacity-50 cursor-default' : 'cursor-pointer')}
                src={ChevronLeft}
                onClick={canDecrementYear ? handleYearDecrement : () => {}}
                alt="date"
              />
              <span className="rounded-sm border-[1px] border-solid border-secondary px-4">
                {date ? date.getFullYear() : new Date().getFullYear()}
              </span>
              <img
                className={clsx(!canIncrementYear ? 'opacity-50 cursor-default' : 'cursor-pointer')}
                src={ChevronRight}
                onClick={canIncrementYear ? handleYearIncrement : () => {}}
                alt="date"
              />
            </div>
            <MuiMonthPicker
              {...props}
              value={date}
              className={clsx('border-sm m-0 p-0 w-60 text-secondary')}
              onChange={(value: Date) => {
                handleChange(new Date(date.getFullYear(), value.getMonth()));
              }}
              maxDate={nextYear}
              sx={sxStyle}
            />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
});
