import * as React from 'react';
import {
  BarPlot,
  ChartsXAxis,
  ResponsiveChartContainer,
  LinePlot,
  MarkPlot,
  ChartsYAxis,
  useDrawingArea,
  useYScale,
  ChartsTooltip,
  AllSeriesType,
  ChartsXAxisProps,
} from '@mui/x-charts';
import { styled } from '@mui/material/styles';
import { ScaleLinear } from 'd3-scale';

const xLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const series: AllSeriesType[] = [
  {
    label: 'customerData',
    type: 'bar',
    data: [20, 101, 74, 56, 135, 143, 111, 135, 89, 7, 62, 12],
    stack: 'stack1',
    color: '#3f464f',
  },
  {
    label: 'lynxData',
    type: 'bar',
    data: [139, 57, 73, 88, 28, 6, 0, 9, 71, 160, 106, 75],
    stack: 'stack1',
    color: '#F7931E',
  },
  { label: 'nonProd', type: 'bar', data: [0, 0, 0, 0, 6, 20, 0, 0, 0, 10, 0, 2], stack: 'stack1', color: '#C5C7C9' },
  {
    label: 'Vector',
    type: 'line',
    curve: 'linear',
    data: [139, 57, 73, 88, 28, 6, 24, 9, 71, 160, 106, 75],
    color: '#BE6500',
  },
];

const StyledPath = styled('path')<{ color: 'primary' }>(({ theme, color }) => ({
  fill: 'none',
  stroke: theme.palette.text[color],
  shapeRendering: 'crispEdges',
  strokeWidth: 1,
  pointerEvents: 'none',
}));

export const CartesianAxis = () => {
  const { left, width } = useDrawingArea();

  const yAxisScale = useYScale() as ScaleLinear<any, any>;

  const yTicks = [0, 20, 40, 60, 80, 100, 120, 140, 160, 180];

  return (
    <React.Fragment>
      {yTicks.map((value) => (
        <StyledPath key={value} d={`M ${left} ${yAxisScale(value)} l ${width} 0`} color="primary" />
      ))}
    </React.Fragment>
  );
};

export const ProfitabilityChart = () => {
  // In case that the value of every bar needs to be displayed
  // const BarCustomElement = (props) => {
  //   return (
  //     <>
  //       <BarElement {...props} color={`${props.ownerState.color}`}>
  //         <text x={0} y={0}>
  //           {props.ownerState.dataIndex}
  //         </text>
  //       </BarElement>
  //       <text>{props.ownerState.dataIndex}</text>
  //     </>
  //   );
  // };

  return (
    <ResponsiveChartContainer
      height={600}
      series={series}
      xAxis={[
        {
          id: 'x-axis-id',
          data: xLabels,
          scaleType: 'band',
          categoryGapRatio: 0.5,
          barGapRatio: 0,
        } as ChartsXAxisProps,
      ]}
      margin={{ top: 16 }}
      sx={{
        '& .MuiLineElement-root': {
          strokeDasharray: '10 5',
          strokeWidth: 1,
        },
        '& .MuiMarkElement-root': {
          fill: '#BE6500',
        },
        '& .MuiChartsAxis-tick': {
          visibility: 'hidden',
        },
        '& .MuiChartsAxis-line': {
          visibility: 'hidden',
        },
        '& .MuiChartsAxis-tickLabel tspan': {
          fontSize: '20px',
          fontWeight: '500',
        },
        '& .MuiChartsTooltip-valueCell': {
          backgroundColor: '#BE6500',
        },
      }}
    >
      <CartesianAxis />
      {/* <BarPlot slots={{ bar: BarCustomElement }} /> */}
      <BarPlot />
      <ChartsTooltip />
      <LinePlot />
      <MarkPlot />
      <ChartsXAxis position="bottom" axisId="x-axis-id" />
      <ChartsYAxis />
    </ResponsiveChartContainer>
  );
};
