//github.com/facebook/react/issues/14603#issuecomment-726551598

import React from 'react';

export function lazyImport<T extends React.ComponentType<unknown>, I extends { [K2 in K]: T }, K extends keyof I>(
  factory: () => Promise<I>,
  name: K,
): I {
  return Object.create({
    [name]: React.lazy(() => factory().then((module) => ({ default: module[name] }))),
  });
}
