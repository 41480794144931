import { Dialog as MuiDialog, IconButton, DialogProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

export const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(({ ...props }: DialogProps, ref) => (
  <MuiDialog {...props} maxWidth={false} classes={{ paperWidthFalse: 'max-w-[480px]', root: '-top-[50%]' }} ref={ref}>
    <IconButton
      aria-label="close"
      className="absolute right-0 top-0 border-none hover:bg-white"
      disableRipple
      onClick={() => props.onClose}
    >
      <CloseIcon />
    </IconButton>
    <h2 className="mx-8 mb-0 mt-6 text-2xl font-normal text-secondary">{props.title}</h2>
    {props.children}
  </MuiDialog>
));
