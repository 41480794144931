import { InitialRequestStatus, RequestStatus, TInitialRequestStatus } from '../features/holiday/types';

export const reviewedRequestStatus = (hrStatus: TInitialRequestStatus, managerStatus: TInitialRequestStatus) => {
  switch (true) {
    case hrStatus === InitialRequestStatus.Pending && managerStatus === InitialRequestStatus.Approved:
      return RequestStatus.ApprovedManager;
    case hrStatus === InitialRequestStatus.Approved:
      return RequestStatus.ApprovedHr;
    case hrStatus === InitialRequestStatus.Declined || managerStatus === InitialRequestStatus.Declined:
      return RequestStatus.Declined;
    default:
      return RequestStatus.Pending;
  }
};
