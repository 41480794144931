import { cn } from '@/lib/utils';

type MediaCardProps = {
  title?: string;
  imageSrc: string;
  children: React.ReactNode;
  /** Reverses the order of image and text */
  reverseOrder?: boolean;
  /** Classes applied to the component */
  className?: string;
  /** Classes applied to the image */
  imageClasses?: string;
};

export const MediaCard: React.FC<MediaCardProps> = (props) => {
  const { title, children, imageSrc, className, imageClasses, reverseOrder } = props;

  return (
    <div className={cn('grid grid-cols-1 sm:grid-cols-2', className)}>
      <img
        src={imageSrc}
        alt={title || 'employee-handbook'}
        className={cn('aspect-video w-full justify-self-center', imageClasses, {
          'sm:order-last': !!reverseOrder,
        })}
      />
      <div className="px-12 flex items-center">
        <div>
          {title && <h1 className="text-3xl font-normal leading-8 my-0 mt-6 text-secondary">{title}</h1>}
          <div className="text-sm font-normal">{children}</div>
        </div>
      </div>
    </div>
  );
};
