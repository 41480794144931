import { toKebabCase } from '@/lib/utils';

type ArticleProps = {
  id: string;
  children: React.ReactNode;
  className?: string;
};

export const Article: React.FC<ArticleProps> = ({ id, children, className }) => (
  <article id={toKebabCase(id)} className={className}>
    {children}
  </article>
);
