import { apiSlice } from './api';
import { administrativeSlice, holidaySharedSlice, myTimeSlice, manageTimeOffSlice } from '@/features/holiday/store';
import { reportsSlice } from '@/features/reports/store';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { contractorFiltersSlice } from '@/features/contractor/store/contractorFiltersSlice';
import { activityPlanningSlice } from '@/features/activity-planning/store';
import { profitabilitySlice } from '@/features/reports/store/profitabilitySlice';

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    reports: reportsSlice.reducer,
    administrative: administrativeSlice.reducer,
    myTime: myTimeSlice.reducer,
    manageTimeOff: manageTimeOffSlice.reducer,
    holidayShared: holidaySharedSlice.reducer,
    contractorFilters: contractorFiltersSlice.reducer,
    activityPlanning: activityPlanningSlice.reducer,
    profitability: profitabilitySlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  // devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
