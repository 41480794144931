import { rest } from 'msw';
import appConfig from '@/config/appConfig';
import { summaryMock } from './reportsMocks';
// import {
//   partiallyAllocatedData,
//   profitabilityData,
//   profitabilityEmployees,
//   profitabilityPeriod,
// } from './profitabilityMocks';

export const restHandlers = [
  //Catholic Holidays
  // rest.get(`${appConfig.apiUrl}/vacation-planner/hr-administrative/catholic-holidays/years`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(catholicHolidayYearsMock), ctx.delay());
  // }),
  //Employees
  // rest.get(`${appConfig.apiUrl}/employees`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(employeesMock), ctx.delay());
  // }),
  //Monthly Status
  // rest.get(`${appConfig.apiUrl}/vacation-planner/hr-administrative/months-status/:year`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(monthsStatusMock), ctx.delay());
  // }),
  //Day Off Requests
  // rest.get(`${appConfig.apiUrl}/vacation-planner/hr-administrative/day-off-requests/:year/:month`, (req, res, ctx) => {
  //   return res(
  //     ctx.status(200),
  //     ctx.json(
  //       dayOffRequestMock.filter(
  //         (item) => item.year === Number(req.params.year) && item.month === Number(req.params.month),
  //       ),
  //     ),
  //     ctx.delay(),
  //   );
  // }),
  //Open Month
  // rest.put(`${appConfig.apiUrl}/vacation-planner/hr-administrative/:year/:month/open`, (req, res, ctx) => {
  //   return res(ctx.status(200));
  // }),
  //Close Month
  // rest.put(`${appConfig.apiUrl}/vacation-planner/hr-administrative/:year/:month/close`, (req, res, ctx) => {
  //   return res(ctx.status(200));
  // }),
  //Summary Report
  rest.get(`${appConfig.apiUrl}/monthly-report/summary`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(summaryMock));
  }),
  //Employee Requests
  // rest.get(`${appConfig.apiUrl}/vacation-planner/my-time/requests`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(employeeRequestsMock));
  // }),
  // rest.get(`${appConfig.apiUrl}/vacation-planner/my-time/vacations/interval`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(interval));
  // }),
  //Administrative Vacation Details
  // rest.get(
  //   `${appConfig.apiUrl}/vacation-planner/hr-administrative/employee/:employeeId/vacation-stats/:year`,
  //   (req, res, ctx) => {
  //     return res(ctx.status(200), ctx.json(vacationDetailsMock), ctx.delay());
  //   },
  // ),
  //My Time Vacation Details
  // rest.get(`${appConfig.apiUrl}/vacation-planner/my-time/stats`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(vacationDetailsMock), ctx.delay());
  // }),
  //Profile
  // rest.get(`${appConfig.apiUrl}/profile`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(profileMock), ctx.delay());
  // }),
  //Manage Time Off Request
  // rest.get(`${appConfig.apiUrl}/vacation-planner/manage-time-off/requests`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(manageTimeOffRequest));
  // }),
  //Manage Time Off Request Approve
  // rest.put(`${appConfig.apiUrl}/vacation-planner/manage-time-off/requests/:id/approve`, (req, res, ctx) => {
  //   return res(ctx.status(200));
  // }),
  //Manage Time Off Create Event
  // rest.put(`${appConfig.apiUrl}/vacation-planner/manage-time-off/requests/:id/reject`, (req, res, ctx) => {
  //   return res(ctx.status(200));
  // }),
  //Manage Time Off Interval
  // rest.post(`${appConfig.apiUrl}/vacation-planner/manage-time-off/add-event`, (req, res, ctx) => {
  //   return res(ctx.status(200));
  // }),
  //Manage Time Off Interval
  // rest.get(`${appConfig.apiUrl}/vacation-planner/manage-time-off/interval`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(intervalMock));
  // }),
  //My Time Create Request
  // rest.post(`${appConfig.apiUrl}/vacation-planner/my-time/requests`, (req, res, ctx) => {
  //   return res(ctx.status(200));
  // }),
  //My Time Create Contractor Vacation
  // rest.post(`${appConfig.apiUrl}/vacation-planner/my-time/vacations`, (req, res, ctx) => {
  //   return res(ctx.status(200));
  // }),
  //My Time Edit Vacation
  // rest.put(`${appConfig.apiUrl}/vacation-planner/my-time/requests/:id`, (req, res, ctx) => {
  //   return res(ctx.status(200));
  // }),
  // My Time Interval
  // rest.get(`${appConfig.apiUrl}/vacation-planner/my-time/requests/interval`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(interval));
  // }),
  // My Time Calendar
  // rest.get(`${appConfig.apiUrl}/vacation-planner/my-time/calendar`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(MyTimeHolidays));
  // }),
  // Manage Time Off Calendar
  // rest.get(`${appConfig.apiUrl}/vacation-planner/manage-time-off/calendar`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(ManageTimeOffCalendar));
  // }),
  //My Time Vacation File Upload
  // rest.post(`${appConfig.apiUrl}/vacation-planner/my-time/requests/:id/files`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.delay(1000));
  // }),
  //My Time Vacation File Delete
  // rest.delete(`${appConfig.apiUrl}/vacation-planner/my-time/requests/:id/files/:fileId`, (req, res, ctx) => {
  //   return res(ctx.status(200));
  // }),
  //My Time Get Contractor Vacation
  // rest.get(`${appConfig.apiUrl}/vacation-planner/my-time/vacations`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(contractorVacations));
  // }),
  // rest.put(`${appConfig.apiUrl}/vacation-planner/my-time/vacations/:requestId`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.delay(2000));
  // }),
  //My Time Vacation Delete Request
  // rest.delete(`${appConfig.apiUrl}/vacation-planner/my-time/requests/:requestId`, (req, res, ctx) => {
  //   return res(ctx.delay(2000), ctx.status(200));
  // }),
  //Manage Time Off Add Event
  // rest.post(`${appConfig.apiUrl}/vacation-planner/manage-time-off/event`, (req, res, ctx) => {
  //   return res(ctx.delay(2000), ctx.status(200));
  // }),
  //Manage Time Off Edit Event
  // rest.put(`${appConfig.apiUrl}/vacation-planner/manage-time-off/event/:requestId`, (req, res, ctx) => {
  //   return res(ctx.delay(2000), ctx.status(200));
  // }),
  //Manage Time Off Delete Event
  // rest.delete(`${appConfig.apiUrl}/vacation-planner/manage-time-off/event/:requestId`, (req, res, ctx) => {
  //   return res(ctx.delay(2000), ctx.status(200));
  // }),
  // Out Of Office List
  // rest.get(`${appConfig.apiUrl}/vacation-planner/out-of-office/list`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.delay(3000), ctx.json(offEmployeesMock));
  // }),
  // Capacity Planning
  // rest.get(`${appConfig.apiUrl}/capacity-planner/employees/:employeeId/planning`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(capacityData), ctx.delay(5000));
  // }),
  // Profitability - Employees
  // rest.get(`${appConfig.apiUrl}/profitability/employees`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(profitabilityEmployees));
  // }),
  // // Profitability - Overall
  // rest.get(`${appConfig.apiUrl}/profitability/overall`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(profitabilityData));
  // }),
  // // Profitability - Periods
  // rest.get(`${appConfig.apiUrl}/profitability/overall/to-date`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(profitabilityPeriod));
  // }),
  // // Partially Allocated - Overall
  // rest.get(`${appConfig.apiUrl}/profitability/partially-allocated`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(partiallyAllocatedData));
  // }),
  // // Partially Allocated - Periods
  // rest.get(`${appConfig.apiUrl}/profitability/partially-allocated/to-date`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(profitabilityPeriod));
  // }),
];
