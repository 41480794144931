import { useTranslation } from 'react-i18next';
import { ArticleHeading } from '../ArticleHeading';
import { ResourceList } from '../ResourceList';
import { Article } from '../Article';
import { Section } from '../Section';

import { HANDBOOK_RESOURCES } from '@/config/constants';

export const HandbookResources = () => {
  const { t } = useTranslation('handbook');

  return (
    <Article className="flex flex-col" id={t('resources.title')}>
      <ArticleHeading title={t('resources.title')} />
      <p className="my-0 text-sm">{t('resources.intro')}</p>

      <Section className="flex flex-col gap-6 mt-6">
        <Section>
          <h6 className="my-0 text-sm">{t('resources.sections.administrative')}</h6>
          <ResourceList list={HANDBOOK_RESOURCES.administrative} />
        </Section>

        <Section>
          <h6 className="my-0 text-sm">{t('resources.sections.recruitment')}</h6>
          <ResourceList list={HANDBOOK_RESOURCES.recruitment} />
        </Section>

        <Section>
          <h6 className="my-0 text-sm">{t('resources.sections.performance')}</h6>
          <ResourceList list={HANDBOOK_RESOURCES.performance} />
        </Section>

        <Section>
          <h6 className="my-0 text-sm">{t('resources.sections.employeeBenefits')}</h6>
          <ResourceList list={HANDBOOK_RESOURCES.employeeBenefits} />
        </Section>

        <Section>
          <h6 className="my-0 text-sm">{t('resources.sections.internalCommunication')}</h6>
          <ResourceList list={HANDBOOK_RESOURCES.internalCommunication} />
        </Section>

        <Section>
          <h6 className="my-0 text-sm">{t('resources.sections.policiesAndProcesses')}</h6>
          <ResourceList list={HANDBOOK_RESOURCES.policiesAndProcesses} />
        </Section>
      </Section>
    </Article>
  );
};
