import * as React from 'react';
import {
  BarPlot,
  ResponsiveChartContainer,
  ChartsTooltip,
  AllSeriesType,
  ChartsXAxisProps,
  ChartsLegend,
  BarElement,
  BarSeriesType,
} from '@mui/x-charts';

const series: BarSeriesType[] = [
  {
    label: 'Customer',
    type: 'bar',
    data: [18],
    color: '#3f464f',
  },
  {
    label: 'Lynx',
    type: 'bar',
    data: [2],
    color: '#F7931E',
  },
  {
    label: 'Non-productive',
    type: 'bar',
    data: [2],
    color: '#C5C7C9',
  },
];

type PriorSeriesType = Omit<AllSeriesType, 'color'> & { color: string[]; label: string };

const priorSeries: PriorSeriesType[] = [
  {
    label: 'Planned',
    type: 'bar',
    data: [32, 2, 2],
    color: ['#E6E8EA', '#F7931E1A', '#F2F3F4'],
  },
  {
    label: 'Actual',
    type: 'bar',
    data: [37, 5, 15],
    color: ['#3f464f', '#F7931E', '#C5C7C9'],
  },
];

export const CapacityCurrentChart = () => {
  return (
    <div className="border border-[#CDD0DA] border-solid w-full rounded-lg">
      <ResponsiveChartContainer
        height={412}
        className="w-full"
        series={series}
        xAxis={[
          {
            id: 'x-axis-id',
            data: 'a',
            scaleType: 'band',
          } as ChartsXAxisProps,
        ]}
        margin={{ top: 70, bottom: 0 }}
        sx={{
          backgroundColor: '#F8F9FB',
          borderRadius: 8,
          '& .MuiChartsTooltip-valueCell': {
            backgroundColor: '#BE6500',
          },
        }}
      >
        <BarPlot />
        <ChartsTooltip />
        <ChartsLegend direction="column" position={{ vertical: 'top', horizontal: 'right' }} />
      </ResponsiveChartContainer>
    </div>
  );
};

export const CapacityPriorChart = () => {
  const BarCustomElement = (props: any) => {
    const { ownerState } = props;
    return (
      <BarElement
        {...props}
        stroke="black"
        strokeWidth="2"
        color={`${ownerState.color[ownerState.dataIndex]}`}
        sx={{ borderColor: '#3f464f', border: 1, relative: 'position' }}
      />
    );
  };
  return (
    <div className="border border-[#CDD0DA] border-solid w-full rounded-lg">
      <ResponsiveChartContainer
        height={412}
        className="w-full"
        series={priorSeries as any}
        xAxis={[
          {
            id: 'x-axis-id',
            data: 'ase',
            scaleType: 'band',
            barGapRatio: 0,
            categoryGapRatio: 0.4,
          } as ChartsXAxisProps,
        ]}
        margin={{ top: 70, bottom: 0 }}
      >
        <BarPlot slots={{ bar: BarCustomElement }} />
        <ChartsTooltip />
        <ChartsLegend direction="column" position={{ vertical: 'top', horizontal: 'right' }} />
      </ResponsiveChartContainer>
    </div>
  );
};
