import { RoutesCollection, TRouteObject } from '@/config/routing';
import { lazyImport } from '@/lib/lazyImport';
import { Outlet } from 'react-router-dom';

const { MyWork } = lazyImport(() => import('./MyWork'), 'MyWork');
const { MyWorkProject } = lazyImport(() => import('./MyWorkProject'), 'MyWorkProject');

export const myWorkRoutes: TRouteObject[] = [
  {
    path: RoutesCollection.Mywork,
    element: <MyWork />,
  },
  {
    path: RoutesCollection.MyworkProject,
    element: <MyWorkProject />,
  },
];

export const MyWorkRoutes = () => {
  return (
    <>
      <Outlet />
    </>
  );
};
