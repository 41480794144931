import { Tabs } from '@/components/Navigation';
import { Head } from '@/components/Head';
import { RoutesCollection, TRouteObjectWithName } from '@/config/routing';
import useEffectOnce from '@/hooks/useEffectOnce';
import { lazyImport } from '@/lib/lazyImport';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { ContractorHeader } from '../components/ContractorHeader';
import { useAppDispatch } from '@/store';
import { clearId } from '../store/contractorFiltersSlice';
import { ContentLayout } from '@/components/Layout';

const { ContractorReports } = lazyImport(() => import('./ContractorReports'), 'ContractorReports');
const { ContractorHistory } = lazyImport(() => import('./ContractorHistory'), 'ContractorHistory');
const { EditContractorReport } = lazyImport(() => import('./EditContractorReport'), 'EditContractorReport');

export const contractorRoutes: TRouteObjectWithName[] = [
  {
    name: 'contractor.tabs.reports',
    path: RoutesCollection.ContractorReports,
    element: <ContractorReports />,
  },
  {
    name: 'contractor.tabs.reports',
    path: RoutesCollection.ContractorReportsEdit,
    element: <EditContractorReport />,
  },
  {
    name: 'contractor.tabs.history',
    path: RoutesCollection.ContractorHistory,
    element: <ContractorHistory />,
  },
];

export const ContractorRoutes = () => {
  const { t } = useTranslation('common');
  const matchContractor = useMatch(RoutesCollection.Contractor);
  const matchHistory = useMatch(RoutesCollection.ContractorHistory);

  const navigate = useNavigate();

  useEffectOnce(() => {
    if (!matchHistory) {
      navigate(RoutesCollection.ContractorReports);
    }
  });

  /** @todo: investigate better redirect solution */
  if (matchContractor) {
    navigate(RoutesCollection.ContractorReports);
  }

  const dispatch = useAppDispatch();

  return (
    <>
      <Head title={t('title.contractor')} />

      <ContentLayout>
        <ContractorHeader />
        <Tabs
          routes={contractorRoutes.filter((route) => route.path !== RoutesCollection.ContractorReportsEdit)}
          onRouteChange={() => dispatch(clearId())}
        />
        <Outlet />
      </ContentLayout>
    </>
  );
};
