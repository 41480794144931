import { Configuration } from '@azure/msal-browser';
import appConfig from './appConfig';

export const msalConfig: Configuration = {
  auth: {
    clientId: appConfig.azureClientId,
    authority: 'https://login.microsoftonline.com/' + appConfig.azureTenantId,
    redirectUri: `${appConfig.rootUrl}/login-redirect`,
    navigateToLoginRequestUrl: false,
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};
