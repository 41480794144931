import { Trans } from 'react-i18next';

type DepartmentCardProps = {
  index: number;
  id: string;
  title: string;
  roles: string[];
};

export const DepartmentCard: React.FC<DepartmentCardProps> = ({ index, id, title, roles }) => (
  <div className="flex flex-col">
    <div className="relative p-6 border border-solid rounded border-gray mb-3">
      {id !== 'operationalDept' && (
        <div className="hidden lg:block absolute bg-gray left-full w-[0.80rem] bottom-1/2 h-[1px]" />
      )}
      <div className="flex items-center gap-2">
        <div className="text-4xl font-bold mb-2 text-primary">{String(index + 1).padStart(2, '0')}</div>
        <h1 className="font-medium text-sm my-0 uppercase">{title}</h1>
      </div>
    </div>
    <ul className="list-none p-0 m-0">
      {roles.map((role: string, index: number) => (
        <li key={index} className="relative mb-3 p-2 border border-solid rounded border-gray text-xs text-center">
          <Trans components={{ strong: <strong /> }}>{role}</Trans>
          <div className="absolute bg-gray left-1/2 w-[1px] bottom-[103%] h-3" />
        </li>
      ))}
    </ul>
  </div>
);
