export default {
  tableOfContents: [
    {
      name: 'Hello there!',
    },
    {
      name: 'How (not!) to use this handbook!',
    },
    {
      name: 'Who we are',
      subchapters: ['Our story', 'Vision, mission & values', 'What we expect from each other'],
    },
    {
      name: 'Onboarding',
      subchapters: [
        "Help! Who's my manager?",
        'Organizational structure',
        'Your first day & first week',
        'Your first three months',
        'Who should I contact for questions?',
      ],
    },
    {
      name: 'Administrative component',
      subchapters: [
        'Probation period',
        'Salary',
        'Attendance & punctuality',
        'Working hours',
        'Office entrance cards/keys',
        'Holidays: we love them as much as you do',
        '2nd Pillar requests',
        'Co-insured',
        'Documents update',
        'Business trip (delegations)',
        'Health check-ups',
        'SSM/PSI',
      ],
    },
    {
      name: 'iSO 9001 & iSO 27001',
    },
    {
      name: 'Internal communication',
      subchapters: ['Formal communication', 'Informal communication', 'Tools we use...and how to use them'],
    },
    {
      name: 'Employee benefits',
      subchapters: [
        'Prescription glasses',
        'Medical insurance',
        'Meal tickets',
        'Relaxation/massage',
        'Welcome kit',
        'Presents',
        'Professional development',
      ],
    },
    {
      name: 'Employee evaluation',
      subchapters: ['Annual evaluation', 'Career plan', '1:1 discussion', 'Your performance & progress'],
    },
    {
      name: 'Policies, procedures & processes you need to know',
      subchapters: [
        'Smoking',
        'Parking policy',
        'Overtime',
        'Safety & security',
        'Discrimination',
        'Confidentiality',
        'Non-compete',
        'Work from home',
        'Job offer & hiring procedures',
        'Events',
        'Referral program',
        'Internship & summer practice',
        'Computer use & other working devices',
      ],
    },
    {
      name: 'Off boarding',
    },
    {
      name: 'Resources & links',
    },
  ],
  employeeHandbook: {
    title: 'Employee <strong>Handbook</strong>',
    description: 'Everything you need to know to get started!',
  },
  intro1: {
    title: 'Hello there!',
    paragraph:
      "Welcome to Lynx Solutions! Whether you're embarking on a new journey with us or continuing to contribute to our dynamic team, we're thrilled to have you here. This handbook serves as a valuable guide, offering insights into how we collaborate, live, and enjoy our time together at Lynx Solutions.",
  },
  intro2: {
    title: 'How (not!) to use this handbook',
    paragraph:
      "Here, we'll take a lighthearted approach to guide you through the do's and don'ts of navigating this manual. First and foremost, don't treat it like a super strict rulebook - think of it more as a treasure map to the Lynx world. Feel free to flip through the pages, explore the content, and let your curiosity lead the way.",
  },
  companyIntro: {
    title: 'Who we are',
    subchapters: {
      cards: [
        { name: 'Our story', icon: 'story' },
        { name: 'Vision, mission & values', icon: 'vision' },
        { name: 'What we expect from each other', icon: 'expect' },
      ],
      story: {
        title: 'Our story',
        paragraphs: [
          'We are Lynx Solutions. A <strong>strong community of professionals with the shared dream of making a difference</strong>. We are builders. We build for the future. The future that always starts with “Why”. We always question the status quo, seek purpose, simplify complexities, and deliver the right solution.',
          '<strong>We build partnerships</strong>. The success of a business is always the consequence of the value it created for its partners',
          "The world is reshaping itself. The future is about technology, a fusion of technology with everything. This is our craft, our expertise. Over the last 2 decades we have learned that the results are the best if we invest ourselves in the specific business domain of our partner. We can truly deliver the best possible solution if we understand our partner's business roadmap. This way we can also drive the business forward.",
        ],
      },
      vision: {
        title: 'Vision, mission & values',
        section1: {
          title: 'Vision',
          paragraph: "We want to be one of Transylvania's largest IT companies.",
        },
        section2: {
          title: 'Mission',
          paragraph: 'We are a strong community of a professionals with the shared dream of making a difference.',
        },
        section3: {
          title: 'Values',
          paragraphs: [
            'We are <strong><span id="orange">human first</span></strong> - we are a strong community.',
            'We have <strong><span id="red">purpose</span></strong> - we have a shared dream.',
            'We <strong><span id="green">grow</span></strong> - we make a difference.',
            'We are <strong><span id="purple">experts</span></strong> - community of professionals.',
            'And…we simplify.',
          ],
        },
      },
      expect: {
        title: 'What we expect from each other',
        paragraph:
          "We expect from each other <strong>mutual respect</strong> and <strong>open communication</strong>. We're not just colleagues; we're collaborators working towards a shared Lynx success story. So, gear up for a journey where each contribution, big or small, adds to the Lynx story!",
      },
    },
  },
  onboarding: {
    title: 'Onboarding',
    infobox: 'For more information about onboarding click <a>here</a>',
    departments: [
      {
        id: 'leadershipDept',
        title: 'Leadership department',
        roles: [
          '<strong>CEO</strong> - Chief Executive Officer',
          '<strong>CFO</strong> - Chief Financial Officer',
          '<strong>COO</strong> - Chief Operating Officer',
          '<strong>CTO</strong> - Chief Technology Officer',
          '<strong>CIO</strong> - Chief Information Officer',
        ],
      },
      {
        id: 'managementDept',
        title: 'Management department',
        roles: [
          '<strong>PM</strong> - Project Manager',
          'Team Lead',
          'Tech Lead',
          '<strong>HR</strong> - Human Resources',
        ],
      },
      {
        id: 'developmentDept',
        title: 'Development department',
        roles: ['Developers', 'QA', 'Design'],
      },
      {
        id: 'administrativeDept',
        title: 'Administrative department',
        roles: ['Assistant Manager'],
      },
      {
        id: 'operationalDept',
        title: 'Operational department',
        roles: ['Marketing'],
      },
    ],
    subchapters: {
      cards: [
        { name: "Help! Who's my manager?", icon: 'manager' },
        { name: 'Organizational structure', icon: 'structure' },
        { name: 'Your first day & first week', icon: 'first-week' },
        { name: 'Your first three months', icon: 'first-three' },
        { name: 'Who should I contact for questions?', icon: 'question' },
      ],
      help: {
        title: "Help! Who's my manager?",
        paragraphs: [
          "Don't worry, we are here for you! During the onboarding process overseen by our <strong>Human Resources department</strong>, you'll find out who your manager is. If it's still unclear, simply ask the HR representative again.",
        ],
      },
      structure: {
        title: 'Organizational structure',
        infoBox: 'For more information about organizational structure click <a>here</a>',
      },
      firstDay: {
        title: 'Your first day & first week',
        section1: {
          title: 'Your first day',
          intro: "Here's a structured list of what we expect from your <strong>first day</strong>:",
          paragraphs: [
            'You should know who your project manager is.',
            'You should identify who to contact for technical assistance',
            'Be aware of who your point of contact is within the team.',
            'Have a checklist for your tasks and orientation.',
          ],
        },
        section2: {
          title: 'Your first week',
          intro: 'What we expect from your <strong>first week</strong>:',
          paragraphs: [
            "You should have clear information about the project you'll be working on.",
            'Have a clear plan outlined for your tasks and objectives.',
            "Know the technology or technologies you'll be working with.",
            'Feel free to embrace mistakes, ask questions, engage with everyone, and share your insights to contribute significantly to the company!',
          ],
        },
      },
      firstThreeMonths: {
        title: 'Your first three months',
        section1: {
          intro: 'What we expect from <strong>your first 3 months</strong>:',
          paragraphs: [
            'Before the completion of the initial 3 months, the company and you mutually decide whether to proceed with the collaboration.',
            'You are well aware of the expectations set for you during this period.',
            'You know the evaluation procedure, including when and how it will take place.',
            'You remain independent in your tasks.',
            'You have a clear career path.',
          ],
        },
      },
      questions: {
        title: 'Who should I contact for questions?',
        intro:
          'Up to this point, we anticipate that you are aware of your appropriate point of contact, your project manager, and your technical manager. However, if you are uncertain and need an answer, <strong>please contact the HR department</strong>.',
        paragraphs: [
          'For administrative questions: <a>{{hrEmail}}</a>.',
          'For business trips and devices contact your direct manager and the assistant manager.',
          'For technical questions contact your Tech Lead.',
        ],
      },
    },
  },
  administrative: {
    title: 'Administrative component',
    subchapters: {
      cards: [
        { name: 'Probation period', icon: 'story' },
        { name: 'Salary', icon: 'salary' },
        { name: 'Attendance & punctuality', icon: 'attendance' },
        { name: 'Working hours', icon: 'working' },
        { name: 'Office entrance cards/keys', icon: 'keys' },
        { name: 'Holidays', icon: 'holiday' },
        { name: '2nd Pillar requests', icon: 'pillars' },
        { name: 'Co-insured', icon: 'insurance' },
        { name: 'Documents update', icon: 'documents' },
        { name: 'Business trip (delegations)', icon: 'trip' },
        { name: 'Health check-ups', icon: 'health' },
        { name: 'SSM/PSI', icon: 'ssm' },
      ],
      probation: {
        title: 'Probation period',
        section1: {
          title: 'Probation period',
          paragraphs: [
            "Let's see if we are a match over the next 90 or 120 working days.",
            'The 90-day period applies to executive staff, while the 120-day period is for managerial functions. This timeframe allows us to assess our compatibility. Before the initial 3-month period concludes, both the company and yourself will decide whether to continue the collaboration.',
          ],
        },
        section2: {
          listIntro: "As you're aware, during this period, we expect the following from you:",
          list: [
            'Understand and meet the outlined expectations.',
            'Embrace and embody our culture and values.',
            'Familiarize yourself with our procedures, processes, and politics.',
            'Demonstrate a continuous desire for personal growth and development.',
            'Maintain clarity regarding the future of our collaboration.',
          ],
          listOutro:
            'Some of the benefits we provide will be available from the moment you begin working with us and some only after you finish the probation period.',
        },
      },
      salary: {
        title: 'Salary',
        paragraph:
          'Salaries are paid <strong>between the 7<sup>th</sup> - 10<sup>th</sup> of each month</strong>. We pay by bank transfer, so make sure that you submit your IBAN code at the beginning of your employment. The account needs to be opened at <strong>Banca Transilvania</strong>, so that we can avoid extra charges and make sure that the transactions are timely.',
      },
      attendance: {
        title: 'Attendance & punctuality',
        paragraphs: [
          'Attendance is a key factor in your job performance. Punctuality and regular attendance are expected of all colleagues. Excessive absences are unacceptable. If you are absent for any reason or plan to arrive late or leave early, you must notify your supervisor, HR Department, and the Office Management as far in advance as possible.',
          "When reporting an absence, indicate the absence (free day, sick leave) and when you will return to work. If you didn't arrive by 10:00 a.m. please notify us.",
        ],
      },
      workingHours: {
        title: 'Working hours',
        paragraphs: [
          'Ordinarily, work hours are from 8:00 am to 5:00 pm, Monday through Friday, including a one-hour lunch break. There is an opportunity to vary your work schedule to better accommodate personal responsibilities by taking 1 free hour during the day, with the condition that it will be recovered on the very same day.',
          'The office opening hours are between 7:00 a.m. and 7:00 p.m., so in this time interval the missed hour must be recovered.',
        ],
      },
      officeEntrance: {
        title: 'Office entrance cards / keys',
        paragraphs: [
          'To have access to the main door of the <strong>Cluj-Napoca office</strong>, you will get a key. Access to the office in <strong>Targu Mures</strong> happens with a key card, that you can solicit from the Office Manager or Assistant Manager. Please be careful not to lose it as it involves some costs replacing it. The office building is open from 9:00 am to 5:00 pm, with some flexibility.',
          "The Cluj-Napoca office doesn't have a dedicated parking space. We recommend parking in a paid parking lot nearby or considering alternative transportation options.",
        ],
      },
      holidays: {
        title: 'Holidays: we love them as much as you do',
        paragraphs: [
          'We encourage each team member to allocate time for recharging energy and finding <strong>mental balance</strong>. Therefore, we encourage the use of all annual leave days. You only need to consider the available days balance according to your profile in the <strong>LMP platform</strong>. To know the steps you need to follow before requesting days off, sick leave, or other types of leave, consult the following procedure.',
          "Whenever you're away from the office, particularly if you have direct client interactions, it's essential to <strong>activate the Auto Response feature</strong>. This ensures that anyone reaching out receives acknowledgment of your absence and knows when they can expect a response. This practice helps maintain professional communication standards and manages client expectations effectively.",
        ],
        infobox: 'For more information about holidays click <a>here</a>',
      },
      secondPillar: {
        title: '2nd Pillar requests',
        paragraphs: [
          'To contribute to Pillar 2 of the pension scheme, simply <strong>reach out to the HR manager</strong> and complete the provided application form. Once done, send the completed form to the HR email address. The HR department will take care of the rest. Also, if you need more information about the 2nd Pillar pension scheme, reach out to the HR department.',
          "It's important to note that this procedure <strong>applies only to employees exempt from tax</strong> (scutiti de impozit) and may be subject to changes based on current legislation.",
        ],
      },
      insured: {
        title: 'Co-insured',
        paragraph:
          "For this section, please contact the HR department to receive the necessary information regarding co-insurance. You'll be provided with a set of documents to fill out, and then you'll need to send us a list of the required documents for enrolment. Once completed, please email the documents to HR.",
      },
      documents: {
        title: 'Documents update',
        paragraphs: [
          "If any changes occur regarding your documents (such as address or Identity card), it's important to <strong>inform the HR department promptly</strong>. Send an email to your direct manager, including the updated document as an attachment.",
          'If the changes are HR-related matters, cc the HR department in the email. Ensure that the updated document is attached to the email before sending it. After receiving the updated document, HR will verify the changes and update your records accordingly.',
        ],
        infobox: 'For more information about documents update click <a>here</a>',
      },
      businessTrip: {
        title: 'Business trip (delegations)',
        paragraph:
          "If you need to go on a business trip, remember that you <strong>require your manager's approval</strong>. Additionally, you must inform the HR department regarding the business trip request. It's essential to have the required document with you throughout the business trip. For internal and/or external business trips, every employee is required to log their delegation days into the LMP system.",
        infobox1: 'For more information about internal business trips click <a>here</a>',
        infobox2: 'For more information about external business trips click <a>here</a>',
      },
      health: {
        title: 'Health check-ups',
        paragraphs: [
          'We firmly believe that prevention is better than curing so we recommend that you make your health a priority.',
          'At Lynx employee health check-ups are conducted upon hiring, and subsequently, an annual medical check-up is scheduled. A few days before the annual medical check-up, someone from the HR department will reach out to inform you about the scheduling.',
          "Don't hesitate to book a check-up and select a medical professional who aligns with your specific health needs using our collaborators.",
          'Stay healthy and safe!',
        ],
      },
      ssm: {
        title: 'SSM/PSI',
        paragraph:
          'Upon hiring, every employee receives occupational health and safety training. Subsequently, retraining sessions on various topics are conducted <strong>every 3-6 months</strong>, accompanied by the signing of labor protection documents.',
      },
    },
  },
  companyCertification: {
    title: 'iSO 9001 & iSO 27001',
    paragraphs: [
      "To ensure highest quality standards are always met and Customers receive best services and products from Lynx Solutions, we structured our internal systems in such way that in 2022 we've earned iSO 9001 certification and in 2023 the iSO 27001 certification.",
      "You should already have access to the <a1>Lynx Solutions Team Site</a1>. From here you can navigate to the ISO section, where you'll find the <a2>iSO 27001</a2> and the <a3>iSO 9001</a3> folders.",
    ],
    documentList: {
      intro: 'In case of iSO 27001 we encourage you, to browse the files in the order of the sub-folders:',
      items: [
        {
          title: 'Overview document',
          description:
            '<a1>overview document</a1> - as the name suggests, this Information Security Policy will give you an overall description and some highlights of our Information <strong>Security Management System</strong>.',
        },
        {
          title: 'Main document',
          description:
            '<a2>main document</a2>- this is a comprehensive document, containing all the main requirements of the iSO 27001 standard and also the way Lynx Solutions is implementing those requirements.',
        },
        {
          title: 'Supporting documents',
          description:
            '<a3>supporting documents</a3>- contains all the policies, rulesets, or other type of documents that supports our Information Security Management System.',
        },
      ],
    },
    outro:
      "This initiative is not about poking into your personal space, rather, it's about building a shield that keeps our collective information safe from any potential threats, both internal and external!",
    certificationResponsables: {
      intro: 'Should you have any questions, concerns, or suggestions, please contact:',
      informationSecurity:
        'Information Security Responsible - <strong>Szabolcs Szántó</strong> & <strong>Nimrod Nagy</strong>',
      qualityManagement: 'Quality Management Responsible - <strong>Alpár Kacsó</strong>',
    },
  },
  communication: {
    title: 'Internal communication',
    intro:
      'We like to have clear and consistent communication between Lynx team members. Here are some guidelines to facilitate our communication.',
    outro:
      'Every time when you leave the office for a holiday, it is <strong>recommended you use an Auto response function</strong>, especially if you have direct contact with clients',
    infobox: 'For more information about internal communication click <a>here</a>',
    subchapters: {
      titles: ['Formal communication', 'Informal communication', 'Tools we use...and how to use them'],
      formal: {
        title: 'Formal communication',
        paragraph: 'We use Outlook for our formal communication',
      },
      informal: {
        title: 'Informal communication',
        paragraph: 'We use Microsoft Teams for our quick communication',
      },
      tools: {
        title: 'Tools we use...and how to use them',
        paragraph:
          "We use a suite of tools to enhance productivity and streamline communication. Here's a brief overview of each tool: <strong>Redmine, Timetracker, Opencats, Intranet + Wishlist, Teams, Outlook and VPN</strong>.",
      },
    },
  },
  benefits: {
    title: 'Employee benefits',
    intro1:
      'At Lynx, employees enjoy various benefits, including health insurance, prescription glasses, meal tickets and professional development opportunities. So, take advantage of them to support your personal and professional growth.',
    intro2: 'For more information about employee benefit click <a>here</a>',
    subchapters: {
      titles: [
        'Prescription glasses',
        'Medical insurance',
        'Meal tickets',
        'Relaxation/massage',
        'Welcome kit',
        'Presents',
        'Professional development',
      ],
      glasses: {
        title: 'Prescription glasses',
        paragraph:
          'We understand the importance of eye health, which is why we provide coverage for prescription glasses. If you need glasses, read the procedures and after that reach out to the HR department.',
      },
      insurance: {
        title: 'Medical insurance',
        paragraph:
          'At Lynx, prevention comes first, and to ensure this, employees within the company are provided with medical insurance at Regina Maria.',
      },
      mealTickets: {
        title: 'Meal tickets',
        paragraph:
          'After the three-month probation period, employees are entitled to meal tickets for each day worked (no tickets are provided for days of leave, business trip, unpaid leave, or sick leave).',
      },
      relaxation: {
        title: 'Relaxation/massage',
        paragraph:
          'Every Thursday from 10:00 am to 2 pm, a masseur is available to employees at our office in Corunca.  Book your relaxation time here: <a>Massage (office365.com)</a>',
      },
      welcomeKit: {
        title: 'Welcome kit',
        paragraph:
          'On the first day of employment, employees receive a welcome kit from the company with several useful items.',
      },
      presents: {
        title: 'Presents',
        paragraph:
          "At Lynx Solutions, we love to celebrate the special moments in our employees' lives! That's why birthdays and other significant events are marked with a special gift from the company. But that's not all! We also value tenure, so when you reach 1 year, 5 years, or 10 years with us, we pay special attention to these milestone moments.",
      },
      professional: {
        title: 'Professional development',
        section1: [
          'At Lynx, employees benefit from professional training to keep them up-to-date, grow, and enhance their skills.',
          "We're big believers in always improving and having a good time while we're at it. That's why we're all about professional development opportunities, like workshops and certifications. But we're not all business, all the time. We also like to kick back and have some fun together.",
        ],
        section2: 'Here are some platforms that you can use for you learning process:',
      },
    },
  },
  evaluation: {
    title: 'Employee evaluation',
    infobox: 'For more information about employee evaluation click <a>here</a>',
    subchapters: {
      titles: ['Annual evaluation', 'Career plan', '1:1 discussion', 'Your performance & progress'],
      list: [
        {
          title: 'Annual <span>evaluation</span>',
          id: 'Annual evaluation',
          paragraphs: [
            'At Lynx, we believe in the importance of ongoing feedback and assessment to support the growth and development of our employees. For us, employee evaluation is a vital process that benefits both parties involved—the employee and the company.',
          ],
        },
        {
          title: 'Career <span>plan</span>',
          id: 'Career plan',
          paragraphs: [
            'Your career plan discussions are all about mapping out where you want to go professionally within our company and how we can help you get there.',
            'During the annual evaluation, we discuss together your career plan, agree upon it, and commit to the next steps.',
          ],
        },
        {
          title: '1:1 <span>discussion</span>',
          id: '1:1 discussion',
          paragraphs: [
            "These meetings take place between you and your direct manager, however, 1:1 discussions with HR may also occur if the need or necessity arises. Our 1:1 discussions are our chance to really connect and talk about how you're doing, offer support, and tackle any challenges together.",
          ],
        },
        {
          title: 'Your performance <span>& progress</span>',
          id: 'Your performance & progress',
          paragraphs: [
            "We always keep an eye on your performance and progress to ensure you're on track with your personal growth goals. This is achieved through monthly discussions with your direct manager, feedback from your colleagues, and input from your clients.",
          ],
        },
      ],
    },
  },
  policies: {
    title: 'Policies, procedures & processes you need to know',
    subchapters: {
      cards: [
        { name: 'Smoking', icon: 'smoking' },
        { name: 'Parking policy', icon: 'parking' },
        { name: 'Overtime', icon: 'overtime' },
        { name: 'Safety & security', icon: 'ssm' },
        { name: 'Discrimination', icon: 'discrimination' },
        { name: 'Confidentiality', icon: 'confidentiality' },
        { name: 'Non-compete', icon: 'expect' },
        { name: 'Work from home', icon: 'work-from-home' },
        { name: 'Job offer & hiring procedures', icon: 'offer' },
        { name: 'Events', icon: 'events' },
        { name: 'Referral program', icon: 'referral' },
        { name: 'Internship & summer practice', icon: 'internship' },
        { name: 'Computer use & other working devices', icon: 'devices' },
      ],
      smoking: {
        title: 'Smoking',
        paragraph:
          'Smoking is strictly prohibited inside all Lynx facilities, including offices, conference rooms, and common areas. The smoking areas are outside the building in designated smoking zones.',
      },
      parking: {
        title: 'Parking policy',
        paragraph:
          'Only the yard from the Tg Mures office can accommodate a certain number of cars. It is advised that you park your car in the parking lot, in order not to have any issues or possible damages when left outside. You can open the main gate with your access card and the gate closes automatically.',
      },
      overtime: {
        title: 'Overtime',
        paragraph:
          'We are not encouraging overtime; we want to <strong>maintain a balance between professional and personal life</strong>. Overtime requests are considered only in exceptional circumstances and require approval from both the direct manager and the leadership.',
      },
      security: {
        title: 'Safety & security',
        paragraph:
          'The safety and security of our employees is important. We maintain rigorous safety protocols to minimize risks and provide a secure work environment. Employees are encouraged to promptly report any safety concerns or incidents.',
      },
      discrimination: {
        title: 'Discrimination',
        paragraph:
          'We are committed to fostering a workplace free from discrimination. Discrimination based on race, gender, religion, age, disability, sexual orientation, or any other protected characteristic is strictly prohibited and will not be tolerated. Any reports of discrimination will be thoroughly investigated, and appropriate action will be taken to address and prevent recurrence.',
        infobox: 'For more information about discrimination click <a>here</a>',
      },
      confidentiality: {
        title: 'Confidentiality',
        paragraph:
          "We trust you with some sensitive stuff, like our company's secrets and our customers' data. That's why it's super important that you keep it all under wraps. Sharing confidential info without permission could cause trouble, and we don't want that. So, please make sure you're sticking to the confidentiality agreement you signed when you joined the team.",
        infobox: 'For more information about confidentiality click <a>here</a>',
      },
      nonCompete: {
        title: 'Non-compete',
        paragraph:
          'To protect our intellectual property and competitive interests, employees may be subject to non-compete agreements. These agreements typically restrict employees from engaging in competing activities during and after their employment with the company.',
        infobox: 'For more information about non-compete click <a>here</a>',
      },
      workFromHome: {
        title: 'Work from home',
        paragraph:
          "We know how nice it can be to work from the comfort of your own home. If that's something you're interested in, just know that we're all about flexible work arrangements. Just make sure <strong>you're sticking to our policies and guidelines</strong> when you're working remotely.",
        infobox: 'For more information about work from home click <a>here</a>',
      },
      offer: {
        title: 'Job offer & hiring procedures',
        paragraph:
          "Recruitment processes start at the manager's initiative. Each manager requesting the opening of a position must fill out a request form (Excel) with all the necessary information to start a new recruitment process.",
        infobox: 'For more information about job offer & hiring procedures click <a>here</a>',
      },
      events: {
        title: 'Events',
        paragraph:
          "We're not all about work around here. We love a good get-together, that's why we organize all sorts of events throughout the year, from holiday parties to team-buildings. They're totally optional, but <strong>it is mandatory to complete the registration forms</strong>, regardless of whether you are attending these events or not.",
        infobox: 'For more information about events click <a>here</a>',
      },
      referral: {
        title: 'Referral program',
        paragraph:
          "If you know someone awesome who'd be a perfect fit for our team, we'd love to meet them! Plus, if they end up joining us, you might even get a little bonus as a thank you for the hookup. <strong>It's a win-win</strong>!",
        infobox: 'For more information about referral program click <a>here</a>',
      },
      internship: {
        title: 'Internship & summer practice',
        paragraph:
          "We're all about helping students and recent grads get their foot in the door. Our internship and summer practice programs are designed to give them some real-world experience and help them grow their skills. If you want to become a mentor, talk with the HR manager.",
        infobox1: 'For more information about internship click <a>here</a>',
        infobox2: 'For more information about summer practice click <a>here</a>',
      },
      devices: {
        title: 'Computer use & other working devices',
        paragraph:
          'The use of the computer and other devices received from Lynx Solutions is strictly for professional purposes and must adhere to the safety and confidentiality standards set by the company.',
        infobox: 'For more information about computer use & other working devices click <a>here</a>',
      },
    },
  },
  offboarding: {
    title: 'Off boarding',
    listIntro: "Here's a simplified structure focusing on the steps you need to take at the end of our collaboration:",
    list: [
      'Notify your direct manager and the HR department officially about your decision;',
      'You will participate in an exit interview to provide feedback and insights;',
      'You will return all company equipment and belongings that are in your possession;',
      'You will sign the necessary termination documents;',
      'During the notice period, ensure all your responsibilities are settled.',
    ],
    infobox: 'For more information about off boarding click <a>here</a>',
  },
  resources: {
    title: 'Resources & links',
    intro: 'Here are the links with all the necessary information and some templates for administrative purposes.',
    sections: {
      administrative: 'Administrative',
      recruitment: 'Recruitment',
      performance: 'Performance',
      employeeBenefits: 'Employee Benefits',
      internalCommunication: 'Internal communication and tools we use',
      policiesAndProcesses: 'Policies and processes',
    },
  },
};
