import appConfig from '@/config/appConfig';
import i18n from '@/config/i18n';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'sonner';
import { ZodSchema } from 'zod';
import CloseIcon from '@mui/icons-material/Close';

type ErrorMessage = {
  message?: string;
};

const baseQuery = fetchBaseQuery({
  baseUrl: appConfig.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  prepareHeaders: (headers) => {
    const token = localStorage.getItem(appConfig.tokenKey);
    if (token !== null && token !== '""') {
      headers.set('Authorization', `Bearer ${token.replaceAll('"', '')}`);
      headers.set('Accept', 'application/json');
    }

    return headers;
  },
});
const baseQueryWithInterceptors: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  { dataSchema?: ZodSchema }
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    localStorage.removeItem(appConfig.tokenKey);
    localStorage.removeItem(appConfig.profileKey);
    window.location.href = '/login';
    /** @todo: call logout endpoint */
  }

  if (typeof result.error?.status === 'number' && result.error?.status > 400) {
    const errorMessage = (result.error.data as ErrorMessage).message || i18n.t('api.error');

    toast.custom((t) => {
      return (
        <div className="flex flex-row h-16 items-center font-primary bg-[#F54831] text-white rounded px-4 py-1.5">
          <p className="m-0 mr-14 p-0 font-normal text-sm">{errorMessage}</p>
          <CloseIcon className="m-0 cursor-pointer" onClick={() => toast.dismiss(t)} />
        </div>
      );
    });
  }

  const zodSchema = extraOptions?.dataSchema;
  const { data } = result;

  if (data && zodSchema) {
    try {
      zodSchema.parse(data);
    } catch (error) {
      console.error(error);
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: 'splitApi',
  baseQuery: baseQueryWithInterceptors,
  keepUnusedDataFor: 60,
  tagTypes: [
    'Clients',
    'Employees',
    'Contracts',
    'Reports',
    'Projects',
    'Profile',
    'Contractor',
    'Documents',
    'Contractor-Activities',
    'Administrative-Reports',
    'My-Time-Employee-Requests',
    'My-Time-Contractor-Requests',
    'My-Time-Calendar',
    'My-Time-Interval',
    'Reviewed',
    'Pending',
    'Manage-Time-Off-Pending',
    'Manage-Time-Off-Reviewed',
    'Manage-Time-Off-Interval',
    'Gantt-Calendar',
    'Religion',
    'ReligionTypes',
    'Vacation-Types',
    'Stats',
    'Capacity',
    'Notes',
    'Capacity-Employees',
    'Missing-Documents',
    'Capacity',
    'Notes',
    'Capacity-Employees',
    'Profitability-Employees',
    'Profitability-Overall',
    'Profitability-Periods',
    'Partially-Allocated',
    'Partially-Allocated-Periods',
    'Out-Of-Office',
  ],
  endpoints: () => ({}),
});
