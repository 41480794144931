import { useAuth } from '@/features/auth/context/AuthContext';
import { useAuthorization } from '../hooks';
import { EmployeeTypes, RoleTypes } from '../types';
import { PageLoader } from '@/components/Feedback';

type AuthorizationProps = {
  children: React.ReactNode;
  forbiddenFallback?: React.ReactNode;
  loader?: React.ReactNode;
  allowedRoles: RoleTypes[];
  allowedEmployeeTypes: EmployeeTypes[];
  includeEmploymentHistory?: boolean;
};

export const Authorization = ({
  children,
  forbiddenFallback = <></>,
  loader,
  allowedRoles,
  allowedEmployeeTypes,
  includeEmploymentHistory = false,
}: AuthorizationProps) => {
  const { checkAccess } = useAuthorization();
  const { profile, loading } = useAuth();

  if (loading || profile === null) {
    return loader ? loader : <PageLoader />;
  }

  const canAccess = checkAccess({ allowedRoles, allowedEmployeeTypes, includeEmploymentHistory });

  return canAccess ? <>{children}</> : forbiddenFallback;
};
