import { createContext, useContext, useEffect, useState } from 'react';
import { AuthState, User } from '../types';
import useLocalStorage from '@/hooks/useLocalStorage';
import appConfig from '@/config/appConfig';
import { FETCH_REQUEST_OPTIONS } from '@/config/constants';
import { UserProfile, profileApi, useGetProfileQuery } from '@/features/profile';
import { posthog } from 'posthog-js';
import { useAppDispatch } from '@/store';

interface AuthContextData extends AuthState {
  signInWithCredentials: (email: string, password: string) => Promise<void>;
  signInWithToken: (token: string) => Promise<void>;
  signOut: () => Promise<void>;
  isAuthenticated: boolean;
  authToken: string;
}

const AuthContext = createContext<AuthContextData>({
  profile: null,
  isAuthenticated: false,
  signInWithCredentials: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  signInWithToken: () => Promise.resolve(),
  loading: false,
  error: null,
  authToken: '',
});

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [authToken, setAuthToken] = useLocalStorage<string>(appConfig.tokenKey, '');
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const localProfile = localStorage.getItem(appConfig.profileKey);
  const jsonProfile: UserProfile = localProfile !== null && localProfile !== '' && JSON.parse(localProfile);
  const token = localStorage.getItem(appConfig.tokenKey);

  const { data: profileData } = useGetProfileQuery(undefined, { skip: token === null || token === '""' });

  useEffect(() => {
    if (token !== null && token !== '""' && profileData) {
      setLoading(true);

      setProfile(profileData);
      posthog.identify(profileData.email);
      localStorage.setItem(appConfig.profileKey, JSON.stringify(profileData));

      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  useEffect(() => {
    if (!profile) {
      setLoading(true);
      setProfile(jsonProfile);
      posthog.identify(jsonProfile.email);
      setLoading(false);
    }
  }, [jsonProfile, profile]);

  const signIn = async (user: User) => {
    setProfile(user.profile);
    setAuthToken(user.token);
    localStorage.setItem(appConfig.profileKey, JSON.stringify(user.profile));
    await dispatch(profileApi.util.upsertQueryData('getProfile', undefined, user.profile));
    posthog.identify(user.profile.email);
  };

  const signInWithToken = async (token: string) => {
    /**@todo: implement logic when endpoint available */
    setLoading(true);

    const requestOptions: RequestInit = { ...FETCH_REQUEST_OPTIONS, body: JSON.stringify({ token: token }) };
    const response = await fetch(`${appConfig.apiUrl}/login`, requestOptions);

    if (response.ok) {
      const data = await response.json();
      signIn(data);
    }

    setLoading(false);
    return Promise.resolve();
  };

  const signInWithCredentials = async (email: string, password: string) => {
    /**@todo: implement logic when endpoint available */
    console.log(email, password);
    return Promise.resolve();
  };

  const signOut = async () => {
    setAuthToken('');
    localStorage.setItem(appConfig.profileKey, '');
    setProfile(null);
    /** @todo: call logout endpoint */
  };

  return (
    <AuthContext.Provider
      value={{
        profile,
        signInWithCredentials,
        signOut,
        signInWithToken,
        isAuthenticated: !!authToken,
        loading,
        error: null,
        authToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
