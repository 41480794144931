import { cn, toKebabCase } from '@/lib/utils';
import { Typography } from '@mui/material';
import { SubchapterIcon } from './SubchapterIcon';

type SubchapterCardProps = {
  id: string;
  item: {
    name: string;
    icon: string;
  };
  className?: string;
};

export const SubchapterCard: React.FC<SubchapterCardProps> = (props) => {
  const { item, id, className } = props;

  return (
    <a href={`#${toKebabCase(id)}`}>
      <div
        className={cn(
          'px-[40px] flex flex-col justify-center items-center border border-solid rounded border-gray h-[140px] bg-veryLightBlue hover:bg-blue-light-1',
          className,
        )}
      >
        <SubchapterIcon icon={item.icon} name={item.name} />
        <Typography variant="subtitle1" className="text-base leading-5 text-center no-underline text-secondary">
          {item.name}
        </Typography>
      </div>
    </a>
  );
};
