import { cn } from '@/lib/utils';

interface Props {
  size?: 'small' | 'default';
  orientation?: 'horizontal' | 'vertical';
  className?: string;
}

export const SectionDivider = ({ size, orientation = 'horizontal', className }: Props) => (
  <div
    className={cn(
      ` bg-[#6D668B] opacity-30`,
      orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]',
      orientation === 'horizontal' ? (size === 'small' ? 'my-2' : 'my-8') : size === 'small' ? 'mx-2' : 'mx-8',
      className,
    )}
  />
);
