import styled from '@emotion/styled';
import { InputBase } from '@mui/material';

export const AutocompleteInput = styled(InputBase)(() => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid #eaecef`,
  '& input': {
    borderRadius: 4,
    backgroundColor: '#fff',
    padding: 8,
    border: `1px solid #eaecef`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px  rgba(3, 102, 214, 0.3)`,
      borderColor: '#0366d6',
    },
  },
}));
