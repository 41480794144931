import { useState } from 'react';

type Modal = Record<string, boolean>;

export const useModal = () => {
  const [modals, setModals] = useState<Modal>({});

  const showModal = (modalId: string) => setModals((prevModals) => ({ ...prevModals, [modalId]: true }));

  const hideModal = (modalId: string) => setModals((prevModals) => ({ ...prevModals, [modalId]: false }));

  const toggleModal = (modalId: string) =>
    setModals((prevModals) => ({ ...prevModals, [modalId]: !prevModals[modalId] }));

  const isModalVisible = (modalId: string) => !!modals[modalId];

  return {
    showModal,
    hideModal,
    toggleModal,
    isModalVisible,
  };
};
