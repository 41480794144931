import * as Sentry from '@sentry/react';

export const sentryConfig = {
  dsn: 'https://dfc449752729a96048c406153f7108e0@sentry.lynxsolutions.eu/10',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', import.meta.env.VITE_ROOT_URL],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
};
