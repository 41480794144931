import { Button } from '@/components/Inputs';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import MsIcon from '@/assets/msIconColored.svg';
import { signInWithMicrosoftPopup, signInWithMicrosoftRedirect } from '../services/microsoftLogin';
import { useAuth } from '../context/AuthContext';
import { useNotification } from '@/hooks/useNotification';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Head } from '@/components/Head';
import { useEffect, useState } from 'react';
import { LOGIN_TYPES } from '..';
import appConfig from '@/config/appConfig';

type LoginProps = {
  isRedirect?: boolean;
};

const Login = ({ isRedirect }: LoginProps) => {
  const { t } = useTranslation('login');
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { signInWithToken, loading } = useAuth();
  const { displayErrorNotification } = useNotification();
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleRedirect = async () => {
    if (isRedirect && isAuthenticated) {
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: ['User.Read'],
        })
        .then((response) => {
          signInWithToken(response.accessToken)
            .then(() => {
              navigate('/');
            })
            .catch(() => {
              displayErrorNotification(t('failed'));
            });
        })
        .catch(() => {
          displayErrorNotification(t('failed'));
        });
    }
  };

  useEffect(() => {
    handleRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedirect, isAuthenticated]);

  const handleSignInRedirect = async () => {
    try {
      await signInWithMicrosoftRedirect(instance);
    } catch {
      displayErrorNotification(t('failed'));
    }
  };

  const handleSignInPopup = async () => {
    const response = await signInWithMicrosoftPopup(instance);
    if (response && response.accessToken) {
      try {
        await signInWithToken(response.accessToken);
        navigate('/');
      } catch (error) {
        displayErrorNotification(t('failed'));
      }
    }
  };

  const handleMicrosoftLogin = async () => {
    setIsClicked(true);
    if (appConfig.loginType === LOGIN_TYPES.REDIRECT) {
      handleSignInRedirect();
    } else {
      handleSignInPopup();
    }
  };

  return (
    <Button
      variant="outlined"
      color="secondary"
      className="w-full gap-4"
      onClick={handleMicrosoftLogin}
      disabled={loading || isClicked}
    >
      {loading ? (
        <CircularProgress
          color="primary"
          classes={{
            colorPrimary: 'text-secondaryLight',
          }}
          size="16px"
        />
      ) : (
        <img src={MsIcon} alt="ms icon" />
      )}
      {t('login.microsoft')}
    </Button>
  );
};

export const LoginPage = ({ isRedirect }: LoginProps) => {
  const { t } = useTranslation('login');

  return (
    <>
      <Head />
      <div className="flex h-screen w-full flex-row">
        <div className="hidden bg-red-200 md:block md:w-[55%]"></div>
        <div className="flex w-full flex-col bg-white px-11 pt-11 md:w-[45%]">
          <h2 className="mb-11 mt-0 text-4xl font-bold text-primary">LMP</h2>
          <h3 className="m-0 mb-3 text-4xl font-normal">{t('login')}</h3>
          <div className="mt-20">
            <Login isRedirect={isRedirect} />
          </div>
          <div className="my-8 h-0.5 w-full bg-[#6D668B] opacity-30"></div>
        </div>
      </div>
    </>
  );
};
