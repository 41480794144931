import { Button as MuiButton, ButtonProps } from '@mui/material';
import React from 'react';
import { cn } from '@/lib/utils';

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className = '', ...props }: ButtonProps, ref) => (
    <MuiButton
      className={cn('px-3 py-2 font-medium shadow-none hover:shadow-none', className)}
      {...props}
      ref={ref}
      disableRipple
      classes={{
        root: 'capitalize font-primary',
        outlinedPrimary:
          'border border-solid border-primary border-opacity-40 hover:border-opacity-100 rounded hover:bg-inherit',
        containedPrimary:
          'border border-solid border-primary text-secondary rounded border-opacity-40 hover:border-opacity-100 hover:bg-primaryHover',
        textPrimary: 'text-primary hover:bg-transparent',
        outlinedSecondary:
          'border border-solid border-secondary rounded border-opacity-40 hover:border-opacity-100 text-secondary hover:bg-inherit',
        containedSecondary:
          'border border-solid border-secondary rounded border-opacity-40 hover:border-opacity-100 hover:bg-secondaryDark',
        textSecondary: 'text-secondary hover:bg-transparent',
        outlinedError:
          'border border-solid border-error border-opacity-40 hover:border-opacity-100 text-error rounded hover:bg-inherit',
        containedError:
          'border border-solid border-error bg-error rounded border-opacity-40 hover:border-opacity-100 hover:bg-error',
        textError: 'text-error hover:bg-transparent',
        containedInfo: 'rounded-sm bg-white text-black hover:bg-white',
      }}
    >
      {props.children}
    </MuiButton>
  ),
);
