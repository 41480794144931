import * as React from 'react';
import { SxProps, TextField as MuiTextField, TextFieldProps } from '@mui/material';
import clsx from 'clsx';

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(({ ...props }: TextFieldProps, ref) => {
  const { className } = props;
  const sx: SxProps = {
    '& .Mui-focused': {
      color: '#3C425E !important',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #3C425E !important',
      },
    },
    '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(60, 66, 94, 0.4) !important',
    },
  };

  return <MuiTextField {...props} sx={props.sx ? props.sx : sx} className={clsx(className, 'm-0')} ref={ref} />;
});
