import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '@/locale/en';
import hu from '@/locale/hu';
import ro from '@/locale/ro';

export const defaultLanguage = 'en';
export const defaultNS = 'common';
export const resources = { en, hu, ro } as const;

i18n.use(initReactI18next).init({
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  ns: ['common', 'layout', 'dashboard', 'reports', 'myWork', 'userGuide', 'handbook'],
  defaultNS: 'common',
  resources,
});

export default i18n;
