import { apiSlice } from '@/store';
import { ActivityDataResponseSchema, EmployeeListSchema, NoteListSchema } from '../types/schemas';
import {
  priorSeriesTransformer,
  currentSeriesTransformer,
  priorLegendTransformer,
  currentLegendTransformer,
} from '../utils/activityPlanningUtils';

import type { EmployeeRequest, TActivityData, TActivityDataResponse, TEmployeeList, TNoteList } from '../types';

const activityDataTransformer = (data: TActivityDataResponse): TActivityData => {
  return {
    ...data,
    priorSeries: priorSeriesTransformer(data.lastWeek.categories, data.categories),
    currentSeries: currentSeriesTransformer(data.actualWeek.categories, data.categories),
    priorLegend: priorLegendTransformer(),
    currentLegend: currentLegendTransformer(data.categories),
  };
};

export const activityPlanningApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Capacity Planner Employees
     */
    getCapacityEmployees: build.query<TEmployeeList, void | EmployeeRequest>({
      query: (data) => ({
        url: '/capacity-planner/employees',
        params: data ? data : {},
      }),
      providesTags: ['Capacity-Employees'],
      extraOptions: { dataSchema: EmployeeListSchema },
    }),
    putCapacityEmployees: build.mutation<void, { employeeId: number; body: { isFullyAllocated: boolean } }>({
      query: ({ employeeId, body }) => ({
        url: `/capacity-planner/employees/${employeeId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Capacity-Employees'],
    }),

    /**
     * Capacity Planning
     */
    getCapacityData: build.query<TActivityData, { employeeId: number }>({
      query: ({ employeeId }) => ({
        url: `/capacity-planner/planning/employees/${employeeId}`,
      }),
      providesTags: ['Capacity'],
      transformResponse: (response: TActivityDataResponse): TActivityData => {
        return activityDataTransformer(response);
      },
      extraOptions: { dataSchema: ActivityDataResponseSchema },
      keepUnusedDataFor: 0,
    }),
    postCapacityEmployee: build.mutation<
      void,
      { employeeId: number; body: { projectId: number; plannedHours: number } }
    >({
      query: ({ employeeId, body }) => ({
        url: `/capacity-planner/planning/employees/${employeeId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Capacity'],
    }),
    deleteEmployeePlanning: build.mutation<void, { planningId: number }>({
      query: ({ planningId }) => ({
        url: `/capacity-planner/planning/${planningId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Capacity'],
    }),

    /**
     * Employee Notes
     */
    getEmployeeNotes: build.query<TNoteList, { employeeId: number }>({
      query: ({ employeeId }) => ({
        url: `/capacity-planner/notes/employees/${employeeId}`,
      }),
      providesTags: ['Notes'],
      extraOptions: { dataSchema: NoteListSchema },
    }),
    postEmployeeNote: build.mutation<void, { employeeId: number; note: string }>({
      query: ({ employeeId, note }) => ({
        url: `/capacity-planner/notes/employees/${employeeId}`,
        method: 'POST',
        body: { note },
      }),
      invalidatesTags: ['Notes'],
    }),
    deleteEmployeeNote: build.mutation<void, { noteId: number }>({
      query: ({ noteId }) => ({
        url: `/capacity-planner/notes/${noteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Notes'],
    }),
  }),
});

export const {
  useGetEmployeeNotesQuery,
  usePostEmployeeNoteMutation,
  useDeleteEmployeeNoteMutation,
  useGetCapacityDataQuery,
  usePutCapacityEmployeesMutation,
  useGetCapacityEmployeesQuery,
  usePostCapacityEmployeeMutation,
  useDeleteEmployeePlanningMutation,
} = activityPlanningApi;
