import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { DefaultizedPieValueType, pieArcLabelClasses } from '@mui/x-charts';

const pieData = [
  { id: '0', label: 'Customer', value: 2400, color: '#3f464f' },
  { id: '1', label: 'Lynx', value: 4567, color: '#FFC453' },
  { id: '2', label: 'NonProd', value: 1398, color: '#C5C7C9' },
];

const TOTAL = pieData.map((item) => item.value).reduce((a, b) => a + b, 0);

const getArcLabel = (params: DefaultizedPieValueType) => {
  const percent = params.value / TOTAL;
  return `${(percent * 100).toFixed(0)}%`;
};

export const SimplePieChart = () => {
  return (
    <PieChart
      series={[
        {
          data: pieData,
          cx: 500,
          cy: 200,
          innerRadius: 40,
          outerRadius: 80,
          type: 'pie',
          arcLabel: getArcLabel,
        },
      ]}
      height={300}
      slotProps={{
        legend: { hidden: true },
      }}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'white',
          fontSize: 14,
        },
      }}
    />
  );
};
