import { RoutesCollection, TRouteObject } from '@/config/routing';
import { LoginPage } from '@/features/auth';
import { CircularProgress } from '@mui/material';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const App = () => {
  return (
    <Suspense
      fallback={
        <div className="flex h-full w-full items-center justify-center">
          <CircularProgress />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  );
};

export const publicRoutes: TRouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Navigate to={RoutesCollection.LoginPage} replace={true} />,
      },
      {
        path: RoutesCollection.LoginPage,
        element: <LoginPage />,
      },
      {
        path: RoutesCollection.LoginRedirect,
        element: <LoginPage isRedirect />,
      },
      {
        path: '/*',
        element: <Navigate to={RoutesCollection.LoginPage} replace={true} />,
      },
    ],
  },
];
