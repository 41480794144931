export const getFullName = (firstName?: string, lastName?: string) => {
  return firstName && lastName ? firstName + ' ' + lastName : undefined;
};

export const getInitials = (firstName?: string, lastName?: string) => {
  if (firstName === undefined || lastName === undefined) {
    return '';
  }

  return firstName.substring(0, 1) + lastName.substring(0, 1);
};
