import { EmployeeRequest, IEmployeeItem, IVacationTypes, OutOfOfficeEmloyee, ReligionProfile } from './../types';
import { apiSlice } from '@/store';
import { IdAndNameItem } from '@/types/utils';

export const holidaySharedApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getVacationTypes: build.query<IVacationTypes[], void>({
      query: () => ({
        url: `/vacation-planner/vacation-types`,
      }),
      providesTags: ['Vacation-Types'],
    }),
    getReligions: build.query<IdAndNameItem[], void>({
      query: () => ({
        url: `/vacation-planner/religions`,
      }),
      transformResponse: (resp: IdAndNameItem[]) => {
        return resp;
      },
      providesTags: ['ReligionTypes'],
    }),
    getEmployees: build.query<IdAndNameItem[], void | EmployeeRequest>({
      query: (data) => ({ url: '/vacation-planner/employees', params: data ? data : {} }),
      providesTags: ['Employees'],
      transformResponse: (response: IEmployeeItem[]) => {
        return response.map((item) => ({ id: item.id, name: item.name }));
      },
    }),
    getEmployeeReligion: build.query<ReligionProfile, void>({
      query: () => ({
        url: `/vacation-planner/profile`,
      }),
      transformResponse: (resp: ReligionProfile) => {
        return resp;
      },
      providesTags: ['Religion'],
    }),
    putEmployeeReligion: build.mutation<void, { religionId: number }>({
      query: (data) => ({
        url: `/vacation-planner/profile`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Religion', 'My-Time-Calendar', 'Gantt-Calendar'],
    }),
    getOutOfOffice: build.query<OutOfOfficeEmloyee[], void | void>({
      query: () => ({ url: '/vacation-planner/employees/out-of-office' }),
      providesTags: ['Out-Of-Office'],
    }),
  }),
});
export const {
  useGetVacationTypesQuery,
  useGetReligionsQuery,
  useGetEmployeesQuery,
  useGetEmployeeReligionQuery,
  useGetOutOfOfficeQuery,
  usePutEmployeeReligionMutation,
} = holidaySharedApi;
