import { useCallback } from 'react';
import { EMPLOYEE_TYPES, EmployeeTypes, ROLES, RoleTypes } from '../types';
import { useAuth } from '@/features/auth/context/AuthContext';

type AccessParams = {
  allowedRoles: RoleTypes[];
  allowedEmployeeTypes: EmployeeTypes[];
  includeEmploymentHistory?: boolean;
};

export const useAuthorization = () => {
  const { profile } = useAuth();

  const checkAccess = useCallback(
    ({ allowedRoles, allowedEmployeeTypes, includeEmploymentHistory = false }: AccessParams) => {
      if (profile === null) {
        return false;
      }

      if (
        allowedEmployeeTypes.length > 0 &&
        allowedEmployeeTypes.includes(EMPLOYEE_TYPES.CONTRACTOR) &&
        profile.wasProvider === true &&
        includeEmploymentHistory
      ) {
        // check if the user was ever a contractor
        return true;
      }

      if (allowedEmployeeTypes.length > 0 && !allowedEmployeeTypes.includes(profile.type as EmployeeTypes)) {
        return false;
      }

      if (allowedRoles.includes(ROLES.HR) && profile.isHr) {
        return true;
      }

      if (allowedRoles.includes(ROLES.MANAGER) && profile.isManager) {
        return true;
      }

      if (allowedRoles.includes(ROLES.ADMIN) && profile.isSuperAdmin) {
        return true;
      }

      if (allowedRoles.length > 0) {
        return false;
      }

      return true;
    },
    [profile],
  );

  return { checkAccess, role: profile };
};
