import { Trans, useTranslation } from 'react-i18next';
import { ArticleHeading } from '../ArticleHeading';
import { MediaCard } from '../MediaCard';
import { TextCard } from '../TextCard';
import { InfoBox } from '../InfoBox';
import { Article } from '../Article';
import { Section } from '../Section';
import { HANDBOOK_RESOURCES } from '@/config/constants';

import teamsIcon from '@/assets/icon-teams.svg';
import outlookIcon from '@/assets/icon-outlook.svg';
import chapter6Handbook1 from '@/assets/handbook/chapter6-handbook1.png';

export const HandbookCommunication = () => {
  const { t } = useTranslation('handbook');

  return (
    <Article className="flex flex-col gap-12 scroll-mt-20" id={t('communication.title')}>
      <Section>
        <ArticleHeading className="justify-start" title={t('communication.title')} />
        <p className="text-sm px-12">{t('communication.intro')}</p>
      </Section>

      <Section>
        <div className="grid grid-cols-2 gap-6">
          <Section id={t('communication.subchapters.formal.title')} className="scroll-mt-20">
            <TextCard className="text-center">
              <h3 className="text-base font-bold uppercase my-0 mb-3">{t('communication.subchapters.formal.title')}</h3>
              <img src={outlookIcon} alt="Microsoft Outlook" />
              <p className="text-sm">{t('communication.subchapters.formal.paragraph')}</p>
            </TextCard>
          </Section>

          <Section id={t('communication.subchapters.informal.title')} className="scroll-mt-20">
            <TextCard className="text-center">
              <h3 className="text-base font-bold uppercase my-0 mb-3">
                {t('communication.subchapters.informal.title')}
              </h3>
              <img src={teamsIcon} alt="Microsoft Teams" />
              <p className="text-sm">{t('communication.subchapters.informal.paragraph')}</p>
            </TextCard>
          </Section>
        </div>

        <p className="text-sm px-12">
          <Trans components={{ strong: <strong /> }}>{t('communication.outro')}</Trans>
        </p>
      </Section>

      <Section id={t('communication.subchapters.tools.title')} className="scroll-mt-20">
        <MediaCard title={t('communication.subchapters.tools.title')} imageSrc={chapter6Handbook1}>
          <p className="text-sm">
            <Trans components={{ strong: <strong /> }}>{t('communication.subchapters.tools.paragraph')}</Trans>
          </p>
        </MediaCard>

        <InfoBox gutterTop text={t('communication.infobox')} url={HANDBOOK_RESOURCES.common.internalCommunication} />
      </Section>
    </Article>
  );
};
