import common from './common';
import layout from './layout';
import dashboard from './dashboard';
import reports from './reports';
import myWork from './my-work';
import login from './login';
import userGuide from './user-guide';
import handbook from './handbook';
import activityPlanning from './activity-planning';

export default {
  common,
  layout,
  dashboard,
  reports,
  myWork,
  login,
  userGuide,
  handbook,
  activityPlanning,
};
