import { Trans, useTranslation } from 'react-i18next';
import { ArticleHeading } from '../ArticleHeading';
import { SubchapterCard } from '../SubchapterCard';
import { MediaCard } from '../MediaCard';
import { SectionHeading } from '../SectionHeading';
import { TextCard } from '../TextCard';
import { InfoBox } from '../InfoBox';
import { Article } from '../Article';
import { Section } from '../Section';
import { DepartmentCard } from '../DepartmentCard';

import { HR_EMAIL, HANDBOOK_RESOURCES } from '@/config/constants';

import chapter4Handbook1 from '@/assets/handbook/chapter4-handbook1.png';
import chapter4Handbook2 from '@/assets/handbook/chapter4-handbook2.png';

export const HandbookOnboarding = () => {
  const { t } = useTranslation('handbook');

  return (
    <Article className="flex flex-col gap-12" id={t('onboarding.title')}>
      <Section>
        <ArticleHeading title={t('onboarding.title')} />
        <div className="grid grid-cols-2 gap-6">
          {t('onboarding.subchapters.cards', { returnObjects: true }).map((item, index) => (
            <SubchapterCard key={item + '-' + index} id={item.name} item={item} />
          ))}
        </div>
      </Section>

      <Section id={t('onboarding.subchapters.help.title')}>
        <MediaCard reverseOrder title={t('onboarding.subchapters.help.title')} imageSrc={chapter4Handbook1}>
          {t('onboarding.subchapters.help.paragraphs', { returnObjects: true }).map((item, index) => (
            <p key={index}>
              <Trans components={{ strong: <strong /> }}>{item}</Trans>
            </p>
          ))}
        </MediaCard>
      </Section>

      <Section id={t('onboarding.subchapters.structure.title')}>
        <SectionHeading title={t('onboarding.subchapters.structure.title')} />
        <div className="flex flex-wrap lg:flex-nowrap justify-center lg:justify-normal gap-3">
          {t('onboarding.departments', { returnObjects: true }).map((dept, index) => (
            <DepartmentCard key={dept.id} index={index} id={dept.id} title={dept.title} roles={dept.roles} />
          ))}
        </div>

        <InfoBox
          className="my-6"
          text={t('onboarding.subchapters.structure.infoBox')}
          url={HANDBOOK_RESOURCES.common.organizationalStructure}
        />
      </Section>

      <Section id={t('onboarding.subchapters.firstDay.title')}>
        <SectionHeading title={t('onboarding.subchapters.firstDay.title')} align="start" />
        <div className="grid grid-cols-2 gap-6">
          <TextCard className="p-6">
            <h3 className="text-base font-bold uppercase my-0 mb-3">
              {t('onboarding.subchapters.firstDay.section1.title')}
            </h3>
            <p className="my-0 text-sm">
              <Trans components={{ strong: <strong /> }}>{t('onboarding.subchapters.firstDay.section1.intro')}</Trans>
            </p>
            <ul className="my-0 text-sm pl-1 ml-4">
              {t('onboarding.subchapters.firstDay.section1.paragraphs', { returnObjects: true }).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </TextCard>
          <TextCard className="p-6">
            <h3 className="text-base font-bold uppercase my-0 mb-3">
              {t('onboarding.subchapters.firstDay.section2.title')}
            </h3>
            <p className="my-0 text-sm">
              <Trans components={{ strong: <strong /> }}>{t('onboarding.subchapters.firstDay.section2.intro')}</Trans>
            </p>
            <ul className="my-0 text-sm pl-1 ml-4">
              {t('onboarding.subchapters.firstDay.section2.paragraphs', { returnObjects: true }).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </TextCard>
        </div>

        <Section id={t('onboarding.subchapters.firstThreeMonths.title')}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
            <img
              src={chapter4Handbook2}
              alt={t('onboarding.subchapters.firstThreeMonths.title')}
              className="aspect-video w-full self-center"
            />
            <div className="p-6">
              <h3 className="text-base font-bold uppercase my-0 mb-3">
                {t('onboarding.subchapters.firstThreeMonths.title')}
              </h3>
              <p className="my-0 text-sm">
                <Trans components={{ strong: <strong /> }}>
                  {t('onboarding.subchapters.firstThreeMonths.section1.intro')}
                </Trans>
              </p>
              <ul className="my-0 text-sm pl-1 ml-4">
                {t('onboarding.subchapters.firstThreeMonths.section1.paragraphs', { returnObjects: true }).map(
                  (item, index) => (
                    <li key={index}>{item}</li>
                  ),
                )}
              </ul>
            </div>
          </div>
        </Section>
        <InfoBox gutterTop text={t('onboarding.infobox')} url={HANDBOOK_RESOURCES.common.onboarding} />
      </Section>

      <Section id={t('onboarding.subchapters.questions.title')}>
        <TextCard className="text-center">
          <SectionHeading title={t('onboarding.subchapters.questions.title')} />
          <p className="text-sm">
            <Trans components={{ strong: <strong /> }}>{t('onboarding.subchapters.questions.intro')}</Trans>
          </p>
          <ul className="text-sm pl-0 list-inside">
            {t('onboarding.subchapters.questions.paragraphs', { hrEmail: HR_EMAIL, returnObjects: true }).map(
              (item, index) => (
                <li key={index}>
                  <Trans components={{ a: <a href={`mailto:${HR_EMAIL}`} className="text-primary underline" /> }}>
                    {item}
                  </Trans>
                </li>
              ),
            )}
          </ul>
        </TextCard>
      </Section>
    </Article>
  );
};
