import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const toKebabCase = (string: string) =>
  string
    /** Replace uppercase letters with lowercase and prepend a hyphen */
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    /** Replace all non-alphanumeric characters (except spaces) with a space */
    .replace(/[^a-zA-Z0-9\s]/g, ' ')
    /** Replace spaces and underscores with hyphens */
    .replace(/[\s_]+/g, '-')
    /** Convert the entire string to lowercase */
    .toLowerCase()
    /** Remove leading or trailing hyphens */
    .replace(/^-+|-+$/g, '');
