import { Box, Toolbar } from '@mui/material';
import { TopBar } from '@/components/Layout';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <Box className="flex h-screen">
      <TopBar />
      <main className="w-full flex-1">
        <Toolbar />
        {children}
      </main>
    </Box>
  );
};
