import { useTranslation } from 'react-i18next';
import { ArticleHeading } from '../ArticleHeading';
import { InfoBox } from '../InfoBox';
import { Article } from '../Article';
import { Section } from '../Section';
import { HANDBOOK_RESOURCES } from '@/config/constants';

export const HandbookOffboarding = () => {
  const { t } = useTranslation('handbook');

  return (
    <Article className="flex flex-col gap-12" id={t('offboarding.title')}>
      <ArticleHeading className="justify-start mb-0" title={t('offboarding.title')} />

      <Section className="text-center">
        <p className="my-0 text-sm">{t('offboarding.listIntro')}</p>
        <ul className="text-sm pl-0 list-inside my-0">
          {t('offboarding.list', { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <InfoBox className="mt-6" text={t('offboarding.infobox')} url={HANDBOOK_RESOURCES.common.offboarding} />
      </Section>
    </Article>
  );
};
