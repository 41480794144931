import { ValueOf } from '@/types/utils';
import { RouteObject } from 'react-router-dom';
import { NavigationNameType } from '@/routes/protected';
import { EmployeeTypes, RoleTypes } from '@/features/authorization';

export const RoutesCollection = {
  Dashboard: '/',
  EmployeeHandbook: '/employee-handbook',
  /** Reports */
  Reports: '/reports',
  ReportsMonthly: '/reports/monthly',
  ReportsOverdueInvoices: '/reports/overdue-invoices',
  ReportsAllInvoices: '/reports/all-invoices',
  ReportsProfitability: '/reports/monthly-hours-and-profitability',
  ReportsPartiallyAllocated: '/reports/partially-allocated',
  /** My Work */
  Mywork: '/my-work',
  MyworkProject: '/my-work/project/:projectId',
  /** Activity Planning */
  ActivityPlanning: '/activity-planner',
  ActivityPlanningManager: '/activity-planner/manage-planner',
  ActivityPlanningEmployee: '/activity-planner/my-planner',
  /** Vacation Planner */
  HolidayPageComponents: '/holiday-page-components',
  VacationPlanner: '/vacation-planner',
  VacationPlannerMyTime: '/vacation-planner/my-time',
  VacationPlannerManager: '/vacation-planner/manager',
  VacationPlannerAdministrative: '/vacation-planner/administrative',
  VacationPlannerOutOfOffice: '/vacation-planner/out-of-office',
  VacationPlannerUserGuide: '/vacation-planner/user-guide',
  /** Contractor */
  Contractor: '/contractor',
  ContractorReports: '/contractor/reports',
  ContractorReportsEdit: '/contractor/reports/edit',
  ContractorHistory: '/contractor/history',
  /** Admin */
  Admin: '/admin',
  LoginPage: '/login',
  LoginRedirect: '/login-redirect',
  ForbiddenPage: '/forbidden',
  Error404: '/*',
  Sandbox: '/sandbox',
} as const;

export type TRoutesCollection = ValueOf<typeof RoutesCollection>;

export type TRouteObject = Pick<RouteObject, 'element'> & { path: TRoutesCollection; children?: TRouteObject[] };

export type TRouteObjectWithName = Pick<RouteObject, 'element'> & {
  name: NavigationNameType;
  path: TRoutesCollection;
  children?: TRouteObject[];
  allowedRoles?: RoleTypes[];
  allowedEmployeeTypes?: EmployeeTypes[];
  hidden?: boolean;
  showNotification?: boolean;
};

/** Typing the routes and their params */
export type TRouteArgs =
  | { path: typeof RoutesCollection.Admin }
  | { path: typeof RoutesCollection.Reports }
  | { path: typeof RoutesCollection.ReportsMonthly }
  | { path: typeof RoutesCollection.Mywork }
  | { path: typeof RoutesCollection.MyworkProject; params: { projectId: string } }
  | { path: typeof RoutesCollection.Admin }
  | { path: typeof RoutesCollection.Contractor }
  | { path: typeof RoutesCollection.ContractorReports }
  | { path: typeof RoutesCollection.ContractorReportsEdit }
  | { path: typeof RoutesCollection.ContractorHistory }
  | { path: typeof RoutesCollection.Sandbox }
  | { path: typeof RoutesCollection.VacationPlanner }
  | { path: typeof RoutesCollection.VacationPlannerMyTime }
  | { path: typeof RoutesCollection.VacationPlannerManager }
  | { path: typeof RoutesCollection.VacationPlannerOutOfOffice }
  | { path: typeof RoutesCollection.VacationPlannerUserGuide };

export type TRouteArgsWithParams = Extract<TRouteArgs, { path: unknown; params: unknown }>;

export enum RemoteUrlCollection {
  OverdueInvoicesDownload = '/invoice-report/invoices/pdf?clientIds=:ids&overdue=true&sort=:sort&order=:order',
  AllInvoicesDownload = '/invoice-report/invoices/pdf?clientIds=:ids&overdue=false&startDate=:startDate&endDate=:endDate&sort=:sort&order=:order',
  SingleInvoice = '/invoice-report/invoices/:id/pdf?download=:download',
  MonthlyTimeReportDownload = 'vacation-planner/hr-administrative/monthly-time-reports/:year/:month',
  DayOffRequest = '/vacation-planner/hr-administrative/day-off-requests/:year/:month/:employeeId/zip',
  DayOffRequests = '/vacation-planner/hr-administrative/day-off-requests/:year/:month/zip',
  CatholicHolidays = 'vacation-planner/hr-administrative/catholic-holidays/:year',
}

export type TRemoteUrlArgs =
  | {
      path: RemoteUrlCollection.OverdueInvoicesDownload;
      params: { ids: string; sort: string; order: string };
    }
  | {
      path: RemoteUrlCollection.AllInvoicesDownload;
      params: { ids: string; startDate: string; endDate: string; sort: string; order: string };
    }
  | {
      path: RemoteUrlCollection.SingleInvoice;
      params: { id: string; download: string };
    }
  | {
      path: RemoteUrlCollection.MonthlyTimeReportDownload;
      params: { year: number; month: number };
    }
  | {
      path: RemoteUrlCollection.DayOffRequest;
      params: { year: number; month: number; employeeId: number };
    }
  | {
      path: RemoteUrlCollection.DayOffRequests;
      params: { year: number; month: number };
    }
  | {
      path: RemoteUrlCollection.CatholicHolidays;
      params: { year: number };
    };

export type TRemoteUrlArgsWithParams = Extract<TRemoteUrlArgs, { path: unknown; params: unknown }>;
