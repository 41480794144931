import { TRouteObject } from '@/config/routing';
import { lazyImport } from '@/lib/lazyImport';
import { Outlet } from 'react-router-dom';

const { Reports } = lazyImport(() => import('./Reports'), 'Reports');
const { MonthlyReports } = lazyImport(() => import('./MonthlyReports'), 'MonthlyReports');
const { OverdueInvoices } = lazyImport(() => import('./OverdueInvoices'), 'OverdueInvoices');
const { AllInvoices } = lazyImport(() => import('./AllInvoices'), 'AllInvoices');
const { Profitability } = lazyImport(() => import('./Profitability'), 'Profitability');
const { PartiallyAllocated } = lazyImport(() => import('./PartiallyAllocated'), 'PartiallyAllocated');

export const reportsRoutes: TRouteObject[] = [
  {
    path: '/reports',
    element: <Reports />,
  },
  {
    path: '/reports/overdue-invoices',
    element: <OverdueInvoices />,
  },
  {
    path: '/reports/monthly',
    element: <MonthlyReports />,
  },
  {
    path: '/reports/all-invoices',
    element: <AllInvoices />,
  },
  {
    path: '/reports/monthly-hours-and-profitability',
    element: <Profitability />,
  },
  {
    path: '/reports/partially-allocated',
    element: <PartiallyAllocated />,
  },
];

export const ReportsRoutes = () => {
  return (
    <>
      <Outlet />
    </>
  );
};
