import { Trans, useTranslation } from 'react-i18next';
import { ArticleHeading } from '../ArticleHeading';
import { SubchapterCard } from '../SubchapterCard';
import { MediaCard } from '../MediaCard';
import { SectionHeading } from '../SectionHeading';
import { TextCard } from '../TextCard';
import { InfoBox } from '../InfoBox';
import { Article } from '../Article';
import { Section } from '../Section';
import { HANDBOOK_RESOURCES } from '../../../../config/constants';

import chapter5Handbook1 from '@/assets/handbook/chapter5-handbook1.png';
import chapter5Handbook2 from '@/assets/handbook/chapter5-handbook2.png';
import chapter5Handbook3 from '@/assets/handbook/chapter5-handbook3.png';
import chapter5Handbook4 from '@/assets/handbook/chapter5-handbook4.png';
import chapter5Handbook5 from '@/assets/handbook/chapter5-handbook5.png';
import chapter5Handbook6 from '@/assets/handbook/chapter5-handbook6.png';
import chapter5Handbook7 from '@/assets/handbook/chapter5-handbook7.png';

export const HandbookAdministrative = () => {
  const { t } = useTranslation('handbook');

  return (
    <Article className="flex flex-col gap-12" id={t('administrative.title')}>
      <Section>
        <ArticleHeading title={t('administrative.title')} />
        <div className="grid grid-cols-3 md:grid-cols-4 gap-3">
          {t('administrative.subchapters.cards', { returnObjects: true }).map((item, index) => (
            <SubchapterCard
              key={item + '-' + index}
              className="max-h-28"
              id={item.name.includes('Holiday') ? t('administrative.subchapters.holidays.title') : item.name}
              item={item}
            />
          ))}
        </div>
      </Section>

      <Section id={t('administrative.subchapters.probation.title')}>
        <MediaCard reverseOrder title={t('administrative.subchapters.probation.title')} imageSrc={chapter5Handbook1}>
          {t('administrative.subchapters.probation.section1.paragraphs', { returnObjects: true }).map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </MediaCard>
        <MediaCard imageSrc={chapter5Handbook2}>
          <p className="mb-0">{t('administrative.subchapters.probation.section2.listIntro')}</p>
          <ul className="my-0 pl-1 ml-4">
            {t('administrative.subchapters.probation.section2.list', { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <p className="my-0">{t('administrative.subchapters.probation.section2.listOutro')}</p>
        </MediaCard>
      </Section>

      <Section id={t('administrative.subchapters.salary.title')}>
        <TextCard className="text-center">
          <SectionHeading title={t('administrative.subchapters.salary.title')} />
          <p className="my-0 text-sm">
            <Trans components={{ strong: <strong />, sup: <sup /> }}>
              {t('administrative.subchapters.salary.paragraph')}
            </Trans>
          </p>
        </TextCard>
      </Section>

      <Section id={t('administrative.subchapters.attendance.title')}>
        <MediaCard reverseOrder title={t('administrative.subchapters.attendance.title')} imageSrc={chapter5Handbook3}>
          {t('administrative.subchapters.attendance.paragraphs', { returnObjects: true }).map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </MediaCard>

        <Section id={t('administrative.subchapters.workingHours.title')}>
          <MediaCard title={t('administrative.subchapters.workingHours.title')} imageSrc={chapter5Handbook4}>
            {t('administrative.subchapters.workingHours.paragraphs', { returnObjects: true }).map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </MediaCard>
        </Section>
      </Section>

      <Section id={t('administrative.subchapters.officeEntrance.title')}>
        <TextCard className="text-center">
          <SectionHeading title={t('administrative.subchapters.officeEntrance.title')} />
          <div>
            {t('administrative.subchapters.officeEntrance.paragraphs', { returnObjects: true }).map((item, index) => (
              <p key={index} className="my-0 text-sm pb-3">
                <Trans components={{ strong: <strong /> }}>{item}</Trans>
              </p>
            ))}
          </div>
        </TextCard>
      </Section>

      <Section id={t('administrative.subchapters.holidays.title')} className="px-12">
        <SectionHeading title={t('administrative.subchapters.holidays.title')} align="start" />
        <div>
          {t('administrative.subchapters.holidays.paragraphs', { returnObjects: true }).map((item, index) => (
            <p key={index} className="my-0 text-sm pb-3">
              <Trans components={{ strong: <strong /> }}>{item}</Trans>
            </p>
          ))}
        </div>

        <InfoBox
          className="mt-6"
          text={t('administrative.subchapters.holidays.infobox')}
          url={HANDBOOK_RESOURCES.common.holidays}
        />
      </Section>

      <Section id={t('administrative.subchapters.secondPillar.title')} className="px-12">
        <SectionHeading title={t('administrative.subchapters.secondPillar.title')} align="start" />
        <div>
          {t('administrative.subchapters.secondPillar.paragraphs', { returnObjects: true }).map((item, index) => (
            <p key={index} className="my-0 text-sm pb-3">
              <Trans components={{ strong: <strong /> }}>{item}</Trans>
            </p>
          ))}
        </div>
      </Section>

      <Section id={t('administrative.subchapters.insured.title')}>
        <MediaCard reverseOrder title={t('administrative.subchapters.insured.title')} imageSrc={chapter5Handbook5}>
          <p>{t('administrative.subchapters.insured.paragraph')}</p>
        </MediaCard>

        <Section id={t('administrative.subchapters.documents.title')}>
          <MediaCard title={t('administrative.subchapters.documents.title')} imageSrc={chapter5Handbook6}>
            {t('administrative.subchapters.documents.paragraphs', { returnObjects: true }).map((item, index) => (
              <p key={index} className="pb-3">
                <Trans components={{ strong: <strong /> }}>{item}</Trans>
              </p>
            ))}
          </MediaCard>

          <InfoBox text={t('administrative.subchapters.documents.infobox')} url={HANDBOOK_RESOURCES.common.documents} />
        </Section>
      </Section>

      <Section id={t('administrative.subchapters.businessTrip.title')}>
        <TextCard className="text-center">
          <SectionHeading title={t('administrative.subchapters.businessTrip.title')} />
          <p className="my-0 text-sm pb-3">
            <Trans components={{ strong: <strong /> }}>{t('administrative.subchapters.businessTrip.paragraph')}</Trans>
          </p>
        </TextCard>

        <div className="grid grid-cols-2 gap-6">
          <InfoBox
            className="my-6"
            text={t('administrative.subchapters.businessTrip.infobox1')}
            url={HANDBOOK_RESOURCES.common.internalBusiness}
          />
          <InfoBox
            className="my-6"
            text={t('administrative.subchapters.businessTrip.infobox2')}
            url={HANDBOOK_RESOURCES.common.externalBusiness}
          />
        </div>
      </Section>

      <Section id={t('administrative.subchapters.health.title')}>
        <MediaCard reverseOrder title={t('administrative.subchapters.health.title')} imageSrc={chapter5Handbook7}>
          {t('administrative.subchapters.health.paragraphs', { returnObjects: true }).map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </MediaCard>
      </Section>

      <Section id={t('administrative.subchapters.ssm.title')}>
        <TextCard className="text-center">
          <SectionHeading title={t('administrative.subchapters.ssm.title')} />
          <p className="my-0 text-sm">
            <Trans components={{ strong: <strong /> }}>{t('administrative.subchapters.ssm.paragraph')}</Trans>
          </p>
        </TextCard>
      </Section>
    </Article>
  );
};
