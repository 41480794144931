import { ValueOf } from '@/types/utils';

export const ROLES = {
  MANAGER: 'MANAGER',
  HR: 'HR',
  ADMIN: 'ADMIN',
} as const;

export const EMPLOYEE_TYPES = {
  EMPLOYEE: 'employee',
  CONTRACTOR: 'contractor',
} as const;

export type RoleTypes = ValueOf<typeof ROLES>;
export type EmployeeTypes = ValueOf<typeof EMPLOYEE_TYPES>;
