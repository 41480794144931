import { zodResolver } from '@hookform/resolvers/zod';
import { DatePicker } from '@/components/Pickers';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';
import { InputBase } from '@mui/material';
import useEffectOnce from '@/hooks/useEffectOnce';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { RequestFormData, ErrorResponse, IVacationTypes } from '@/features/holiday/types';
import { useGetIntervalQuery } from '@/features/holiday/api/myTimeApiSlice';
import { SchedulerHelpers } from '@aldabil/react-scheduler/types';
import { Button, SingleSelect, SingleSelectItem } from '@/components/Inputs';
import { FormErrorMessage } from '../components';
import { queryError } from '@/utils/queryErrors';
import { nextWorkDay } from '@/config/constants';
import { disableWeekends } from '@/utils/dateUtils';

const formSchema = z.object({
  requestId: z.number().optional(),
  vacationType: z.object({
    id: z.number(),
    name: z.string().refine((vacationName) => vacationName !== t('holiday.select.vacationType')),
    isHalfDayRequest: z.boolean(),
  }),
  description: z.string(),
  fromDate: z.date(),
  toDate: z.date(),
});

type ValidationSchema = z.infer<typeof formSchema>;

interface RequestFormProps {
  vacationTypes: IVacationTypes[];
  data?: RequestFormData;
  scheduler?: SchedulerHelpers;
  isLoading?: boolean;
  deletionInProgress?: boolean;
  handleFormSubmit: (data: RequestFormData) => void;
  handleRequestDelete?: (requestId: number) => void;
}

export const RequestForm = ({
  vacationTypes,
  data,
  scheduler,
  isLoading = false,
  deletionInProgress = false,
  handleFormSubmit,
  handleRequestDelete,
}: RequestFormProps) => {
  const { t } = useTranslation('common');

  const defaultValues = {
    vacationType: { id: 0, name: t('holiday.select.vacationType'), isHalfDayRequest: false },
    toDate: nextWorkDay,
    fromDate: nextWorkDay,
  };

  const values = data
    ? {
        ...data,
        vacationType: data.vacationType
          ? {
              id: data.vacationType.id,
              name: data.vacationType.name,
              isHalfDayRequest: data.vacationType.isHalfDayRequest,
            }
          : defaultValues.vacationType,
      }
    : defaultValues;

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    getFieldState,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: values,
  });

  const {
    data: intervalData,
    isSuccess: isIntervalDataSuccess,
    error: intervalError,
  } = useGetIntervalQuery(
    getFieldState('fromDate').isTouched || getFieldState('toDate').isTouched || getFieldState('vacationType').isTouched
      ? {
          requestId: data?.requestId,
          typeId: getValues('vacationType').id,
          startDate: format(getValues('fromDate'), 'yyyy-MM-dd'),
          endDate: format(getValues('toDate'), 'yyyy-MM-dd'),
        }
      : skipToken,
  );

  const handleFromDateChange = (newDate: Date | null) => {
    if (newDate) {
      setValue('fromDate', newDate, { shouldValidate: true, shouldTouch: true });
      if (newDate > getValues('toDate')) {
        setValue('toDate', newDate, { shouldValidate: true });
      }
      if (getValues('vacationType.isHalfDayRequest')) {
        setValue('toDate', newDate, { shouldValidate: true, shouldTouch: true });
      }
    }
  };

  const handleToDateChange = (newDate: Date | null) => {
    if (newDate) {
      setValue('toDate', newDate, { shouldValidate: true, shouldTouch: true });
    }
  };

  const handleVacationChange = (newType: SingleSelectItem) => {
    let isHalfDayRequest: boolean = false;
    if (vacationTypes.find((vacation) => vacation.id === newType.id && vacation.isHalfDayRequest)) {
      setValue('toDate', getValues('fromDate'), { shouldValidate: true, shouldTouch: true });
      isHalfDayRequest = true;
    }
    setValue('vacationType', { ...newType, isHalfDayRequest }, { shouldValidate: true, shouldTouch: true });
  };

  const onSubmit = (data: RequestFormData) => {
    handleFormSubmit(data);
  };

  const onDelete = () => {
    const requestId = getValues('requestId');
    if (requestId && handleRequestDelete) {
      handleRequestDelete(requestId);
    }
  };

  useEffectOnce(() => {
    if (scheduler?.state !== undefined) {
      setValue('fromDate', scheduler.state.start.value, { shouldValidate: true, shouldTouch: true });
      setValue('toDate', scheduler.state.end.value, { shouldValidate: true, shouldTouch: true });
      setValue('vacationType', vacationTypes[0]);
    }
  });

  return (
    <div className="mx-8 mt-6 flex max-w-[480px] flex-col">
      <p className="m-0 mb-6 text-sm font-normal">
        {vacationTypes.find((vacation) => vacation.id === getValues('vacationType.id'))?.description}
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <div>
          <p className="m-0 mb-1 text-sm font-medium">{t('type.absence')}*</p>
          <Controller
            name="vacationType"
            control={control}
            render={({ field }) => (
              <SingleSelect
                value={field.value}
                items={vacationTypes}
                placeholder={t('holiday.select.vacationType')}
                label=""
                searchLabel="search"
                isLoading={false}
                isDisabled={false}
                onChange={handleVacationChange}
                searchDisabled={true}
                dropdownWidth="full"
                fullWidth={true}
                border="sm"
              />
            )}
          />

          {errors.vacationType && <FormErrorMessage errorMessage={t('error.required')} />}
        </div>
        <div>
          <p className="m-0 mb-1 text-sm font-medium">{t('description')}</p>
          <InputBase
            {...register('description')}
            multiline
            fullWidth
            rows={4}
            classes={{
              root: 'border border-secondary border-opacity-30 py-1 px-2 border-solid rounded-sm text-secondary',
            }}
          />
          {errors.description && <FormErrorMessage errorMessage={t('error.required')} />}
        </div>
        <div className="flex flex-row justify-between gap-6">
          <div className="flex flex-col">
            <p className="m-0 mb-1 text-sm font-medium">{t('from')}</p>
            <Controller
              name="fromDate"
              control={control}
              defaultValue={getValues('fromDate')}
              render={({ field }) => (
                <DatePicker
                  value={field.value}
                  onChange={handleFromDateChange}
                  shouldDisableDate={disableWeekends}
                  disabled={getValues('vacationType').id === 0}
                />
              )}
            />
          </div>
          <div
            className={clsx(
              `flex flex-col`,
              vacationTypes.find(
                (vacation) => vacation.id === getValues('vacationType.id') && vacation.isHalfDayRequest,
              ) && 'invisible',
            )}
          >
            <p className="m-0 mb-1 text-sm font-medium">{t('to')}</p>
            <Controller
              name="toDate"
              control={control}
              defaultValue={getValues('toDate')}
              render={({ field }) => (
                <DatePicker
                  value={field.value}
                  onChange={handleToDateChange}
                  minDate={getValues('fromDate')}
                  shouldDisableDate={disableWeekends}
                  disabled={getValues('vacationType').id === 0}
                />
              )}
            />
          </div>
        </div>
        {isIntervalDataSuccess ? (
          <p className="m-0 text-sm font-bold text-primaryLight">
            {`${intervalData.interval} ${t('day', { count: intervalData.interval })} ${t('taken')}`}
          </p>
        ) : (
          intervalError && <FormErrorMessage errorMessage={queryError(intervalError as ErrorResponse)} />
        )}
        <div className="flex justify-end gap-4 mb-6">
          {getValues('requestId') && (
            <Button
              variant="outlined"
              disabled={deletionInProgress || isLoading || !!intervalError}
              onClick={onDelete}
              color="secondary"
              className="w-16 h-8 p-4 px-8 text-xs font-medium rounded"
            >
              {deletionInProgress ? (
                <CircularProgress
                  className="absolute "
                  color="primary"
                  classes={{
                    colorPrimary: 'text-primary',
                  }}
                  size="16px"
                />
              ) : (
                t('delete')
              )}
            </Button>
          )}
          <Button
            variant="contained"
            disabled={deletionInProgress || isLoading || !!intervalError}
            color="primary"
            type="submit"
            className="w-16 h-8 p-4 px-8 text-xs font-medium rounded disabled:bg-primary"
          >
            {isLoading ? (
              <CircularProgress
                className="absolute "
                color="primary"
                classes={{
                  colorPrimary: 'text-veryLightBlue',
                }}
                size="16px"
              />
            ) : (
              t('save')
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};
