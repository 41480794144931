interface Props {
  title: string;
  children?: React.ReactNode;
}

export const GreetingsHeader = ({ title, children }: Props) => {
  return (
    <div className="flex flex-col pb-10 sm:flex-row sm:items-center sm:justify-between">
      <div className="mb-6 flex flex-row justify-center sm:mb-0">
        <h2 className="m-0 text-lg font-normal md:text-3xl md:leading-[50px]">{title}</h2>
      </div>
      {children}
    </div>
  );
};
